import { Stack, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import SearchField from './SearchField';

export default function FilterSidebar({ topContent, searchFunc, filters }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Stack spacing={1} alignItems="center" sx={{ flex: '1 1 auto', p: 1, mr: 1 }}>
            {topContent}
            <Typography variant="h5" color="primary">{resourceFinal[appLanguage].words.FILTERS}</Typography>
            {searchFunc ? <SearchField func={searchFunc} /> : ""}
            {filters}
        </Stack>
    );
}