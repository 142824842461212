import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ArticleList from '../subcomponents/ArticleList';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import { fetchInformation } from './informationSlice';

export default function InformationPage() {
    const dispatch = useDispatch();
    const { information, informationStatus } = useSelector((state) => state.information);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        dispatch(fetchInformation());
    }, [dispatch]);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.INFORMATION} content={
            <ContentLoader successCondition={informationStatus === "success"} errorCondition={informationStatus === "failed"} content={
                <ArticleList articles={information} status={informationStatus} pagination={true} rowHeight={60} />
            } />
        } bottomBar />
    );
}
