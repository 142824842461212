import { Button, Card, CardActions, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import TravelDeclarationEditCard from './TravelDeclarationEditCard';
import { selectDeclaration, setFailedDeclaration } from './travelCostSlice';

export default function TravelDeclarationCard({ travelCostOption }) {
    const dispatch = useDispatch();

    const [openNew, setOpenNew] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleDeclareClick = () => {
        dispatch(selectDeclaration({
            momentId: travelCostOption.momentId,
            performanceNumber: travelCostOption.performanceNumber,
            activityName: travelCostOption.activityName
        }));
        setOpenNew(!openNew);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedDeclaration(false));
    };

    return (
        <>
            <Card variant="outlined" sx={{ px: 2, pt: 1  }}>
                <Typography variant="h6" sx={{ ...CardHeader, textAlign: 'start' }}>
                    {travelCostOption.activityName}{travelCostOption.performanceName ? ": " + travelCostOption.performanceName : ""}
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.MOMENT}: </Typography>
                    {GetDate(travelCostOption.momentStart)} ({GetTime(travelCostOption.momentStart)} - {GetTime(travelCostOption.momentEnd)})
                </Typography>
                <Typography variant="body1">
                    <Typography component="span" fontWeight="bold">{resourceFinal[appLanguage].words.LOCATION}: </Typography>
                    {travelCostOption.location.name}
                </Typography>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="outlined" color="secondary" onClick={handleDeclareClick}>
                        {resourceFinal[appLanguage].words.DECLARE}
                    </Button>
                </CardActions>
            </Card>
            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <TravelDeclarationEditCard handleClose={callbackModalNew} addNew />
                </>
            </Modal>
        </>
    );
}
