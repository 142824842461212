import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const putSetting = createAsyncThunk(
    "/api/admin/updateSetting",
    async (settingKeyValue, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/admin/updateSetting",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(settingKeyValue),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putImageSetting = createAsyncThunk(
    "/api/admin/updateImageSetting",
    async (imgStgKeyValue, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/admin/updateImageSetting",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(imgStgKeyValue),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchAllNavSettings = createAsyncThunk(
    '/api/admin/navSettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/admin/navSettings', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const moveImagesToAzure = createAsyncThunk(
    '/api/file/update/imageType',
    async (imageType, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/file/update/${imageType}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putAllNavSettings = createAsyncThunk(
    "/api/admin/updateNavSettings",
    async (updatedNavSettings, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/admin/updateNavSettings",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedNavSettings),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchExternalConnectionSettings = createAsyncThunk(
    '/api/admin/externalConnections',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/admin/externalConnections', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        adminTabIndex: 0,
        updateSetting: null,
        updateSettingStatus: null,
        updateImageSetting: null,
        updateImageSettingStatus: null,
        navSettings: null,
        navSettingsStatus: null,
        navChanged: false,
        updateNavSettings: null,
        updateNavSettingsStatus: null,
        moveImagesToAzureStatus: null,
        externalConnections: null,
        externalConnectionsStatus: null,
        changedThemeSettings: false,
    },
    reducers: {
        setAdminTabindex: (state, action) => {
            state.adminTabIndex = action.payload;
        },
        setChangedThemeSettings: (state, action) => {
            state.changedThemeSettings = action.payload;
        },
        resetUpdateStatus: (state) => {
            state.updateSettingStatus = null;
            state.updateImageSettingStatus = null;
            state.updateNavSettingsStatus = null;
            state.updateDepartmentThemeSettingStatus = null;
            state.updateDepartmentImageSettingStatus = null;
            state.navChanged = false;
        },
        setNavUserRole: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].userRole = action.payload.value;
            state.navChanged = true;
        },
        setNavItemOrder: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].itemOrder = action.payload.value;
            state.navChanged = true;
        },
        setNavToggled: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].toggled = action.payload.value;
            state.navChanged = true;
        },
        setNavNavigation: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].navigation = action.payload.value;
            state.navChanged = true;
        },
        setNavTile: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].tile = action.payload.value;
            state.navChanged = true;
        },
        setNavTileButton: (state, action) => {
            const index = state.navSettings.findIndex((item) => item.id === action.payload.id);
            state.navSettings[index].tileButton = action.payload.value;
            state.navChanged = true;
        },
    },
    extraReducers: (builder) => {
        // Update setting
        builder
            .addCase(putSetting.pending, (state) => {
                state.updateSettingStatus = 'loading'
            })
            .addCase(putSetting.fulfilled, (state, action) => {
                state.updateSettingStatus = 'success';
                state.updateSetting = action.payload;
            })
            .addCase(putSetting.rejected, (state) => {
                state.updateSettingStatus = 'failed';
            });

        // Update image setting
        builder
            .addCase(putImageSetting.pending, (state) => {
                state.updateImageSettingStatus = 'loading'
            })
            .addCase(putImageSetting.fulfilled, (state, action) => {
                state.updateImageSettingStatus = 'success';
                state.updateImageSetting = action.payload;
            })
            .addCase(putImageSetting.rejected, (state) => {
                state.updateImageSettingStatus = 'failed';
            });

        // Get nav settings
        builder
            .addCase(fetchAllNavSettings.pending, (state) => {
                state.navSettingsStatus = 'loading'
            })
            .addCase(fetchAllNavSettings.fulfilled, (state, action) => {
                state.navSettingsStatus = 'success';
                state.navSettings = action.payload;
            })
            .addCase(fetchAllNavSettings.rejected, (state) => {
                state.navSettingsStatus = 'failed';
            });

        // Update nav settings
        builder
            .addCase(putAllNavSettings.pending, (state) => {
                state.updateNavSettingsStatus = 'loading'
            })
            .addCase(putAllNavSettings.fulfilled, (state, action) => {
                state.updateNavSettingsStatus = 'success';
                state.updateNavSettings = action.payload;
            })
            .addCase(putAllNavSettings.rejected, (state) => {
                state.updateNavSettingsStatus = 'failed';
            });

        // Move images to Azure 
        builder
            .addCase(moveImagesToAzure.pending, (state) => {
                state.moveImagesToAzureStatus = 'loading'
            })
            .addCase(moveImagesToAzure.fulfilled, (state) => {
                state.moveImagesToAzureStatus = 'success';
            })
            .addCase(moveImagesToAzure.rejected, (state) => {
                state.moveImagesToAzureStatus = 'failed';
            });

        // Fetch external connection settings
        builder
            .addCase(fetchExternalConnectionSettings.pending, (state) => {
                state.externalConnectionsStatus = 'loading'
            })
            .addCase(fetchExternalConnectionSettings.fulfilled, (state, action) => {
                state.externalConnectionsStatus = 'success';
                state.externalConnections = action.payload;
            })
            .addCase(fetchExternalConnectionSettings.rejected, (state) => {
                state.externalConnectionsStatus = 'failed';
            });
    },
});

export const {
    setChangedThemeSettings,
    resetUpdateStatus,
    setAdminTabindex,
    setNavUserRole,
    setNavItemOrder,
    setNavToggled,
    setNavNavigation,
    setNavTile,
    setNavTileButton
} = adminSlice.actions;

export default adminSlice.reducer;