import { Box } from '@mui/material';
import { useSelector } from "react-redux";
import ErrorDisplay from './ErrorDisplay';
import LoadingDisplay from './LoadingDisplay';

export default function ContentLoader({
    successCondition = false,
    errorCondition = false,
    content,
    style = {},
    textDisabled = false,
    errorCompact = false,
    customError = null,
    waitForInput = false
}) {

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <>
            {successCondition ? content : errorCondition ?
                <Box sx={{ ...style, display: 'flex', flex: '1 1 auto' }}>
                    {customError ? customError : <ErrorDisplay message={resourceFinal[appLanguage].messages.ERROR_MESSAGE} compact={errorCompact} />}
                </Box>
                :
                waitForInput ?
                    <></>
                    :
                    <Box sx={{ ...style, display: 'flex', flex: '1 1 auto' }}>
                        <LoadingDisplay style={style} textDisabled={textDisabled} />
                    </Box>}
        </>
    )
}