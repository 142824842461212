import { alpha, Box, Button, CardMedia, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { DisplayName } from '../../../app/AppUtils';
import { getAllContacts } from '../../contact/contactSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import IconDisplay from '../../subcomponents/IconDisplay';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { getLocations } from '../location/locationManagementSlice';
import { getActivityTypes, getHeadTypes } from '../types/typeManagementSlice';
import ActivityEditCard from './ActivityEditCard';
import { deleteActivity, getActivities, resetActivityStatus, selectActivity, setFailedActivity } from './activityManagementSlice';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function ActivityTab() {
    const dispatch = useDispatch();
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { activitiesStatus, deleteActivityStatus } = useSelector((state) => state.management.activity);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    useEffect(() => {
        if (deleteActivityStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetActivityStatus());
            dispatch(getActivities);
        } else if (deleteActivityStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetActivityStatus());
        };
    }, [deleteActivityStatus]);

    useEffect(() => {
        dispatch(getHeadTypes());
        dispatch(getActivityTypes());
        dispatch(getLocations());
        dispatch(getAllContacts());
    }, [dispatch]);

    useEffect(() => {
        if (activitiesStatus !== "success" && activitiesStatus !== "loading")
            dispatch(getActivities());
    }, [dispatch, activitiesStatus]);

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(setFailedActivity(false));
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedActivity(false));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.name?.toLowerCase().includes(filter.toLowerCase());
    };

    const newClick = () => {
        dispatch(selectActivity(null));
        setOpenNew(!openNew);
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const { activities } = useSelector((state) => state.management.activity);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activities?.filter(customFilterFunction).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (activity) => {
            dispatch(selectActivity(activity));
            setOpenEdit(!openEdit);
        };

        const deleteClick = (activityId) => {
            dispatch(deleteActivity(activityId));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? activities.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : activities.filter(customFilterFunction)).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.name ? item.name : "-"}</TableCell>
                            <Tooltip title={item.description}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.description ? item.description : "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center">{item.headType ? item.headType.name : "-"}</TableCell>
                            <TableCell align="center">{item.activityTypes ? item.activityTypes.map((item) => item.name).join(', ') : "-"}</TableCell>
                            {!siomSettings?.flagProgress && <TableCell align="center">{item.points ? item.points : "-"}</TableCell>}
                            {appSettings?.appVersion === 1 && <TableCell align="center">{item.cost ? Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(item.cost) : "-"}</TableCell>}
                            <TableCell align="center">{item.capacity ? item.capacity : "-"}</TableCell>
                            <TableCell align="center">{item.location ? item.location.name : "-"}</TableCell>
                            <TableCell align="center">{item.organiser ? item.organiser : "-"}</TableCell>
                            <TableCell align="center">{item.contact ? DisplayName(item.contact.firstname, item.contact.insertion, item.contact.lastname) : "-"}</TableCell>
                            <TableCell align="center">
                                {item.imageUrl ? <CardMedia component="img" sx={{ height: 50, width: 50, mx: 'auto' }} image={item.imageUrl} alt="location" /> : "-"}
                            </TableCell>
                            <TableCell align="center" padding="checkbox">{item.iconName ? <IconDisplay style={{ color: 'primary.main' }} component={item.iconName} /> : "-"}</TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.name} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? activities.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : activities.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={activities?.filter(customFilterFunction).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <ContentLoader successCondition={activitiesStatus === "success"} errorCondition={activitiesStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.TYPE_HEAD}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.TYPES.toLowerCase()}</TableCell>
                                    {!siomSettings?.flagProgress && <TableCell align="center" sx={{ ...TableHeader }}>{appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}</TableCell>}
                                    {appSettings?.appVersion === 1 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.COST}</TableCell>}
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CAPACITY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ORGANISER}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CONTACT}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.IMAGE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ICON}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <ActivityEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <ActivityEditCard handleClose={callbackModalNew} addNew />
                </>
            </Modal>
        </>
    );
}
