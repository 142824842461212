import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchMessageClasses = createAsyncThunk(
    '/api/message/classes',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/message/classes', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMessageActivities = createAsyncThunk(
    '/api/message/performances',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/message/performances', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMessageStudentsClass = createAsyncThunk(
    '/api/message/class/classId',
    async (classIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/message/class/${classIds}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMessageStudentsPerformance = createAsyncThunk(
    '/api/message/performances/performanceIds',
    async (performanceIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/message/performances/${performanceIds}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMessageStudents = createAsyncThunk(
    '/api/message/students',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/message/students`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchMessages = createAsyncThunk(
    '/api/message/messages',
    async ({ type, value }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const apiUrl = type === "sendto" ? `api/message/sendto/${value}` : type === "sendfrom" ? `api/message/sendfrom/${value}` : `api/message/subject/${value}`;
        const response = await fetch(window.Config.apiUrl + apiUrl, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postMessage = createAsyncThunk(
    '/api/message/post',
    async (message, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/message`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(message)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
); // Performances/{ids}

export const messageSlice = createSlice({
    name: 'message',
    initialState: {
        msgClasses: null,
        msgClassesStatus: null,
        msgActivities: null,
        msgActivitiesStatus: null,
        msgStudents: null,
        msgStudentsStatus: null,
        msgReceivers: null,
        messages: null,
        messagesStatus: null,
        postMessageStatus: null,
        msgStudentsFilter: ""
    },
    reducers: {
        setMsgReceivers: (state, action) => {
            state.msgReceivers = action.payload;
        },
        resetMessageStatus: (state) => {
            state.msgStudents = null;
            state.msgStudentsStatus = null;
            state.msgReceivers = null;
            state.postMessageStatus = null;
        },
        setMsgStudentFilter: (state, action) => {
            state.msgStudentsFilter = action.payload;
        },
        clearMessages: (state) => {
            state.messages = null;
        },
    },
    extraReducers: (builder) => {
        // Get classes
        builder
            .addCase(fetchMessageClasses.pending, (state) => {
                state.msgClassesStatus = 'loading';
            })
            .addCase(fetchMessageClasses.fulfilled, (state, action) => {
                state.msgClasses = action.payload;
                state.msgClassesStatus = 'success';
            })
            .addCase(fetchMessageClasses.rejected, (state) => {
                state.msgClassesStatus = 'failed';
            });

        // Get performances
        builder
            .addCase(fetchMessageActivities.pending, (state) => {
                state.msgActivitiesStatus = 'loading';
            })
            .addCase(fetchMessageActivities.fulfilled, (state, action) => {
                state.msgActivities = action.payload;
                state.msgActivitiesStatus = 'success';
            })
            .addCase(fetchMessageActivities.rejected, (state) => {
                state.msgActivitiesStatus = 'failed';
            });

        // Get students in classes
        builder
            .addCase(fetchMessageStudentsClass.pending, (state) => {
                state.msgStudentsStatus = 'loading';
            })
            .addCase(fetchMessageStudentsClass.fulfilled, (state, action) => {
                state.msgStudents = action.payload;
                state.msgStudentsStatus = 'success';
            })
            .addCase(fetchMessageStudentsClass.rejected, (state) => {
                state.msgStudentsStatus = 'failed';
            });

        // Get students in performances
        builder
            .addCase(fetchMessageStudentsPerformance.pending, (state) => {
                state.msgStudentsStatus = 'loading';
            })
            .addCase(fetchMessageStudentsPerformance.fulfilled, (state, action) => {
                state.msgStudents = action.payload;
                state.msgStudentsStatus = 'success';
            })
            .addCase(fetchMessageStudentsPerformance.rejected, (state) => {
                state.msgStudentsStatus = 'failed';
            });

        // Get all students
        builder
            .addCase(fetchMessageStudents.pending, (state) => {
                state.msgStudentsStatus = 'loading';
            })
            .addCase(fetchMessageStudents.fulfilled, (state, action) => {
                state.msgStudents = action.payload;
                state.msgStudentsStatus = 'success';
            })
            .addCase(fetchMessageStudents.rejected, (state) => {
                state.msgStudentsStatus = 'failed';
            });

        // Get sent messages
        builder
            .addCase(fetchMessages.pending, (state) => {
                state.messagesStatus = 'loading';
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.messages = action.payload;
                state.messagesStatus = 'success';
            })
            .addCase(fetchMessages.rejected, (state) => {
                state.messagesStatus = 'failed';
            });

        // Get post message
        builder
            .addCase(postMessage.pending, (state) => {
                state.postMessageStatus = 'loading';
            })
            .addCase(postMessage.fulfilled, (state) => {
                state.postMessageStatus = 'success';
            })
            .addCase(postMessage.rejected, (state) => {
                state.postMessageStatus = 'failed';
            });
    }
});

export const {
    clearMessages,
    resetMessageStatus,
    setMsgReceivers,
    setMsgStudentFilter
} = messageSlice.actions;

export default messageSlice.reducer;