import { alpha } from "@mui/material";

export const PageBgcolor = theme => `radial-gradient(circle, ${theme.palette.white.main} 30%, ${alpha(theme.palette.primary.light, 0.3)} 100%)`;
export const MainBgcolor = theme => `${alpha(theme.palette.primary.light, 0.25)}`;
export const CardBgcolor = theme => `${alpha(theme.palette.white.main, 0.85)}`;
export const SubcardBgcolor = theme => `${alpha(theme.palette.primary.light, 0.5)}`;

export const MainBorderRadius = 2;
export const MainIconStyle = { p: 1, color: 'primary.main' }

// PAGELAYOUT
export const PageRoot = {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    backgroundImage: PageBgcolor,
    p: 1,
    boxSizing: 'border-box',
}

export const PageContainer = {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: MainBgcolor,
    p: 1,
    boxShadow: 0,
    borderRadius: MainBorderRadius,
    boxSizing: 'border-box',
}

export const PageHeader = {
    color: theme => theme.palette.primary.main,
    textAlign: 'center',
    pb: 1,
}

// HOME COMPONENTS
export const HomeTile = {
    bgcolor: MainBgcolor,
    boxSizing: 'border-box',
    p: { xs: 0.5, md: 1 },
    boxShadow: 0,
    borderRadius: MainBorderRadius
}

export const HomeTileSmall = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: MainBgcolor,
    boxSizing: 'border-box',
    height: '100%',
    p: { xs: 0.5, md: 1 },
    boxShadow: 0,
    borderRadius: MainBorderRadius
}

export const HomeButton = {
    backgroundImage: theme => `linear-gradient(40deg, ${theme.palette.primary.dark} 5%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.light} 95%)`,
    color: 'white',
    minHeight: '80px',
    width: '100%',
    borderRadius: MainBorderRadius,
    "&:hover": {
        backgroundImage: theme => `linear-gradient(55deg, ${theme.palette.primary.main}, ${theme.palette.primary.light} 95%)`,
    },
    boxShadow: 0,
}

export const HomeTileHeader = {
    fontWeight: 'bold',
    color: 'white.main',
}

// CARDS
export const CardSmall = {
    width: { xs: '44vw', md: 220 },
    color: 'primary.main',
    bgcolor: CardBgcolor,
    borderRadius: MainBorderRadius,
    boxShadow: 0,
}

export const CardMedium = {
    flex: '1 1 auto',
    backgroundColor: CardBgcolor,
    borderRadius: MainBorderRadius,
    boxShadow: 0,
    p: 1
}

export const CardLarge = {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    bgcolor: CardBgcolor,
    boxShadow: 0,
    borderRadius: MainBorderRadius,
    boxSizing: 'border-box'
}

export const CardHeader = {
    color: 'secondary.main',
    fontWeight: 'bold',
    textAlign: 'left',
    p: 0
}

// LISTS
export const ListItemBackground = {
    bgcolor: CardBgcolor,
    boxSizing: 'border-box',
    borderRadius: MainBorderRadius
}

// TABLE
export const TableHeader = {
    fontWeight: 'bold',
    color: 'primary.main'
}

// OTHERS
export const SubHeader = {
    color: 'primary.main',
    textAlign: 'left',
    fontWeight: 'bold',
    pb: 0.2,
}