import { useSelector } from 'react-redux';
import TravelDeclarePage from './TravelDeclarePage';
import TravelReimbursePage from './TravelReimbursePage';

export default function TravelCostPage() {
    const { aspNetUser } = useSelector((state) => state.settings.app);

    return (
        <>
            {aspNetUser?.role?.includes("Employee") ?
                <TravelReimbursePage />
                :
                aspNetUser?.role?.includes("Student") ? <TravelDeclarePage /> : ""
            }
        </>
    );
}
