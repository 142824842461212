import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import Store from "./app/Store";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById('root');
const root = createRoot(container);

// Register push serviceworker (if possible)
if ('serviceWorker' in navigator && 'PushManager' in window) {
    console.log("Service worker and Push are supported.");

    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/pushServiceWorker.js")
            .then(function (registration) {
                console.log("Push service worker successfully registered.");
            })
            .catch(function (err) {
                console.error("Unable to register push service worker.", err);
            });
    });
} else {
    console.log("Service worker or Push API is not supported in the browser.");
};

root.render(
    <Provider store={Store}>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </Provider>,
);
