import { Avatar, Box, Checkbox, FormControlLabel, FormGroup, IconButton, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { DisplayName } from '../../../app/AppUtils';
import { fetchAllRegisterForEmployee, fetchRegisterForEmployee, setAllPeriodChecks, setCheckedPeriod, setPeriodsList } from '../../register/registerSlice';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import ErrorDisplay from '../../subcomponents/ErrorDisplay';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchSearchStudent, fetchSearchStudentAttendances, fetchSearchStudentTimetable, setStudentRegisterFilter, setStudentRegisterPage, setStudentRegisterParentCheck, setStudentRegisterReasonMode } from './searchStudentSlice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Tabs
import SearchStudentPerformanceTab from './tabs/register/SearchStudentPerformanceTab';
import SearchStudentRegisterTab from './tabs/register/SearchStudentRegisterTab';
import SearchStudentSummaryTab from './tabs/register/SearchStudentSummaryTab';
import SearchStudentAttendanceTab from './tabs/SearchStudentAttendanceTab';
import SearchStudentInfoTab from './tabs/SearchStudentInfoTab';
import SearchStudentProgressTab from './tabs/SearchStudentProgressTab';
import SearchStudentTimetableTab from './tabs/SearchStudentTimetableTab';

export default function SearchStudentResultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterPage, studentRegisterReasonMode, studentRegisterParentCheck } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { deleteSubscriptionStatus } = useSelector((state) => state.subscription);
    const { user } = useSelector((state) => state.user);
    const { register, registerStatus, registerAll, registerAllStatus, periods, subscriptionStatus } = useSelector((state) => state.register);

    const [tabValue, setTabValue] = useState(0);
    const [registerTabEnabled, setRegisterTabEnabled] = useState(false);
    const [departmentId, setDepartmentId] = useState(null);

    // Set department id for api calls
    useEffect(() => {
        setDepartmentId((siomSettings.useMultipleDepartments && activeDepartment) ? activeDepartment[0]?.id : null);
    }, [dispatch, activeDepartment, searchStudent, siomSettings]);

    // Set register enabled state
    useEffect(() => {
        setRegisterTabEnabled(siomSettings?.showSearchRegisterTab && !user?.onlyEmployee);
    }, [siomSettings, user]);

    // Get student data (progress & info)
    useEffect(() => {
        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading") {
            dispatch(fetchSearchStudent({ studentId: id, departmentId: departmentId }));
        };
    }, [dispatch, id, departmentId, deleteSubscriptionStatus, subscriptionStatus]);

    // Get student register data if enabled and selected
    useEffect(() => {
        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading" && registerTabEnabled && tabValue === 1) {
            dispatch(setStudentRegisterPage("REGISTER"));

            if (studentRegisterReasonMode)
                dispatch(fetchAllRegisterForEmployee({ studentId: id, departmentId: departmentId }))
            else
                dispatch(fetchRegisterForEmployee({ studentId: id, departmentId: departmentId }));
        };
    }, [dispatch, id, departmentId, studentRegisterReasonMode, deleteSubscriptionStatus, subscriptionStatus, tabValue, registerTabEnabled]);

    // Get student timetable data if selected
    useEffect(() => {
        if (tabValue === (registerTabEnabled ? 3 : 2)) {
            dispatch(fetchSearchStudentTimetable({ studentId: id, departmentId: departmentId }));
        }
    }, [dispatch, id, departmentId, registerTabEnabled, tabValue]);

    // Get student timetable data if selected
    useEffect(() => {
        if (tabValue === (registerTabEnabled ? 4 : 3)) {
            dispatch(fetchSearchStudentAttendances({ studentId: id, departmentId: departmentId }));
        }
    }, [dispatch, id, departmentId, registerTabEnabled, tabValue]);

    // Set register periods if applicable
    useEffect(() => {
        if (studentRegisterReasonMode && registerAllStatus === "success")
            dispatch(setPeriodsList(filterUniquePeriods(registerAll)));

        if (!studentRegisterReasonMode && registerStatus === "success")
            dispatch(setPeriodsList(filterUniquePeriods(register)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerStatus, registerAllStatus]);

    const BackClick = () => {
        if (studentRegisterPage === 'REGISTER')
            navigate(-1);
        else
            dispatch(setStudentRegisterPage("REGISTER"));
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dispatch(setStudentRegisterFilter(value));
        }, 600);
    };

    const filterUniquePeriods = (registerActivities) => {
        const allPeriods = registerActivities.flatMap(item => item.periods);
        const uniquePeriods = new Map();

        allPeriods.forEach((item) => {
            const key = JSON.stringify(item.name);
            if (!uniquePeriods.has(key)) {
                uniquePeriods.set(key, item);
            }
        });

        const uniquePeriodsArray = Array.from(uniquePeriods.values());
        uniquePeriodsArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

        return uniquePeriodsArray;
    };

    const FilterFragment = () => {
        return (
            <Box>
                <ToggleButtonGroup
                    color="primary"
                    disabled={registerStatus === "loading" || registerAllStatus === "loading"}
                    fullWidth
                    exclusive
                    value={studentRegisterReasonMode ? "EMPLOYEE" : "STUDENT"}
                    onChange={(value) => dispatch(setStudentRegisterReasonMode(value === "EMPLOYEE" ? true : false))}
                >
                    {siomSettings?.showSearchRegisterTab && <ToggleButton value="STUDENT">{resourceFinal[appLanguage].words.STUDENT}</ToggleButton>}
                    <ToggleButton value="EMPLOYEE">{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}</ToggleButton>
                </ToggleButtonGroup>
                {((registerStatus === "success" || registerAllStatus === "success") && (registerStatus !== "loading" && registerAllStatus !== "loading")) &&
                    <>
                        <FormControlLabel
                            label={resourceFinal[appLanguage].words.PERIODS}
                            control={
                                <Checkbox
                                    checked={studentRegisterParentCheck}
                                    onChange={
                                        () => {
                                            dispatch(setStudentRegisterParentCheck())
                                            periods.forEach((item) => {
                                                dispatch(setAllPeriodChecks({ id: item.periodId, bool: !studentRegisterParentCheck }));
                                            });
                                        }
                                    }
                                />
                            } />
                        <FormGroup sx={{ width: 1, alignSelf: 'center' }}>
                            {periods?.map((item) => <PeriodCheckbox key={item.periodId} item={item} />)}
                        </FormGroup>
                    </>
                }
            </Box>
        );
    };

    const PeriodCheckbox = ({ item }) => {
        return (
            <FormControlLabel sx={{ alignSelf: 'left', ml: 2 }} control={<Checkbox checked={item.checked} onChange={() => {
                dispatch(setCheckedPeriod(item.periodId))
            }} />} label={item.name} />
        );
    };

    return (
        <PageLayout title={searchStudentStatus === "success" ? `${DisplayName(searchStudent.studentData?.firstName, searchStudent.studentData?.middleName, searchStudent.studentData?.lastName)} (${searchStudent.studentData?.number} - ${searchStudent.studentData?.extraInfo?.class ?? ""})` : `${resourceFinal[appLanguage].words.STUDENT}`} content={
            <ContentLoader successCondition={searchStudentStatus === "success"} errorCondition={searchStudentStatus === "failed"} content={
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} centered>
                            <Tab label={resourceFinal[appLanguage].words.PROGRESS} />
                            {registerTabEnabled && <Tab label={resourceFinal[appLanguage].words.REGISTER} />}
                            <Tab label={resourceFinal[appLanguage].words.STUDENT_DATA} />
                            <Tab label={resourceFinal[appLanguage].words.TIMETABLE} />
                            <Tab label={resourceFinal[appLanguage].words.ATTENDANCE} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <SearchStudentProgressTab />
                    </TabPanel>
                    {registerTabEnabled && <TabPanel value={tabValue} index={1}>
                        {studentRegisterPage === 'REGISTER' ? <SearchStudentRegisterTab /> :
                            studentRegisterPage === 'PERFORMANCE' ? <SearchStudentPerformanceTab studentId={id} /> :
                                studentRegisterPage === 'SUMMARY' ? <SearchStudentSummaryTab studentId={id} /> :
                                    <ErrorDisplay />}
                    </TabPanel>}
                    <TabPanel value={tabValue} index={registerTabEnabled ? 2 : 1}>
                        <SearchStudentInfoTab />
                    </TabPanel>
                    <TabPanel value={tabValue} index={registerTabEnabled ? 3 : 2}>
                        <SearchStudentTimetableTab />
                    </TabPanel>
                    <TabPanel value={tabValue} index={registerTabEnabled ? 4 : 3}>
                        <SearchStudentAttendanceTab />
                    </TabPanel>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                {(tabValue !== 1 || !registerTabEnabled) && <BackButton desktop />}
                {(tabValue === 1 && registerTabEnabled) &&
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={BackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        } contentLeft={
            (tabValue === 1 && registerTabEnabled) && <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar />
    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
            >
                {value === index && (
                    <Box sx={{ display: 'flex', flex: '1 1 auto', p: 2, borderRadius: '0px 0px 8px 8px' }}>
                        {children}
                    </Box>
                )}
            </Box>
        );
    };
}
