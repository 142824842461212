import { Box, Button, ButtonGroup, Card, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { HandleImageUpload } from '../../../app/AppUtils';
import { getHeadTypes, postHeadType, putHeadType, resetTypeStatus, setFailedType } from './typeManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 400 },
    boxShadow: 24,
};

export default function HeadTypeEditCard(props) {
    const { handleClose, addNew } = props;
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { putHeadTypeStatus, postHeadTypeStatus, selectedType, showFailed } = useSelector((state) => state.management.type);
    const [changed, setChanged] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putHeadTypeStatus === "success") {
            dispatch(setFailedType(false));
            dispatch(resetTypeStatus());
            dispatch(getHeadTypes());
            handleClose();
        } else if (putHeadTypeStatus === "failed") {
            dispatch(resetTypeStatus());
            dispatch(setFailedType(true));
        };
    }, [dispatch, handleClose, putHeadTypeStatus]);

    // Post status callback
    useEffect(() => {
        if (postHeadTypeStatus === "success") {
            dispatch(setFailedType(false));
            dispatch(resetTypeStatus());
            dispatch(getHeadTypes());
            handleClose();
        } else if (postHeadTypeStatus === "failed") {
            dispatch(resetTypeStatus());
            dispatch(setFailedType(true));
        };
    }, [dispatch, handleClose, postHeadTypeStatus]);

    // Character limits
    const nameLength = 255;

    // Property states
    const [newName, setNewName] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [newDescription, setNewDescription] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [errorImage, setErrorImage] = useState(null);

    // Property change functions
    const handleNameChange = (event) => setNewName(event.target.value);
    const handleDescriptionChange = (event) => setNewDescription(event.target.value);
    function handleImageChange(event) {
        setErrorImage(null);
        var imagePromise = HandleImageUpload(event);

        imagePromise.then(
            (value) => {
                setImageName(value?.name);
                setNewImage(value?.image);
            },
            (error) => {
                (error.message === "SIZE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_TOO_LARGE));
                (error.message === "TYPE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_NOT_IMAGE));
            });
    };

    // Selected location state update 
    useEffect(() => {
        if (!addNew) {
            setNewName(selectedType?.naam);
            setNewDescription(selectedType?.beschrijving);
            setNewImage(selectedType?.imageUrl);
        };
    }, [selectedType]);

    // Keep track of changed state 
    useEffect(() => {
        if (
            newName !== selectedType?.naam ||
            newDescription !== selectedType?.beschrijving ||
            newImage !== selectedType?.imageUrl
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedType, newName, newDescription, newImage]);

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);

        dispatch(putHeadType({
            id: selectedType.id,
            naam: newName,
            beschrijving: newDescription,
            imageUrl: newImage,
            createdBy: selectedType.createdBy,
            dateCreated: selectedType.dateCreated,
            modifiedBy: `AP/${user.number}`,
            timestamp: selectedType.timestamp,
        }));
    };

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_TOO_LONG} ${nameLength}`);

        dispatch(postHeadType({
            naam: newName,
            beschrijving: newDescription,
            imageUrl: newImage,
            createdBy: `AP/${user.number}`,
        }));
    };

    return (
        <Box sx={modalStyle}>
            <Card sx={{ display: 'flex', p: 3 }}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.TYPE_HEAD} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                    <TextField
                        fullWidth required
                        defaultValue={selectedType?.naam}
                        label={resourceFinal[appLanguage].words.NAME}
                        onChange={handleNameChange}
                        error={errorName !== null}
                        helperText={errorName}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth multiline
                        defaultValue={selectedType?.beschrijving}
                        label={resourceFinal[appLanguage].words.DESCRIPTION}
                        onChange={handleDescriptionChange}
                        variant="outlined"
                    />
                    <Button
                        sx={{ width: 1, height: 1 }}
                        variant="outlined"
                        component="label"
                    >
                        {imageName ? imageName : resourceFinal[appLanguage].words.IMAGE}
                        <input
                            type="file"
                            name="image"
                            onChange={handleImageChange}
                            hidden
                        />
                    </Button>
                    {showFailed && <>
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                    </>}
                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{errorImage}</Typography>
                    <ButtonGroup sx={{ justifyContent: 'center' }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                        {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                        {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                    </ButtonGroup>
                </Stack>
            </Card>
        </Box>
    );
}
