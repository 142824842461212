import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchAvailableStudents = createAsyncThunk(
    '/api/studentInfo/availableStudents/performanceId',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/studentInfo/availableStudents/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const fetchStudentSettings = createAsyncThunk(
    '/api/student/studentSettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/student/studentSettings`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const putStudentInfo = createAsyncThunk(
    '/api/student/updateStudentData',
    async ({ key, secondaryKey, newValue }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/student/updateStudentData`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify({
                key: key,
                secondaryKey: secondaryKey,
                newValue: newValue,
            }),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const studentSlice = createSlice({
    name: 'student',
    initialState: {
        availableStudents: null,
        availableStudentsStatus: null,
        studentInfoSettings: null,
        studentInfoSettingsStatus: null,
        putStudentInfoStatus: null,
        showReservationWarning: true
    },
    reducers: {
        turnReservationWarningOff: (state) => {
            state.showReservationWarning = false;
        },
        resetPutStudentInfoStatus: (state) => {
            state.putStudentInfoStatus = null;
        }
    },
    extraReducers: (builder) => {
        // Fetch Available Students
        builder
            .addCase(fetchAvailableStudents.pending, (state) => {
                state.availableStudentsStatus = 'loading';
            })
            .addCase(fetchAvailableStudents.fulfilled, (state, action) => {
                state.availableStudentsStatus = 'success';
                state.availableStudents = action.payload;
            })
            .addCase(fetchAvailableStudents.rejected, (state) => {
                state.availableStudentsStatus = 'failed';
            });

        // Fetch Student Info Settings
        builder
            .addCase(fetchStudentSettings.pending, (state) => {
                state.studentInfoSettingsStatus = 'loading';
            })
            .addCase(fetchStudentSettings.fulfilled, (state, action) => {
                state.studentInfoSettingsStatus = 'success';
                state.studentInfoSettings = action.payload;
            })
            .addCase(fetchStudentSettings.rejected, (state, action) => {
                state.studentInfoSettingsStatus = 'failed';
                state.studentInfoSettings = action.payload;
            });

        // Put Student Info
        builder
            .addCase(putStudentInfo.pending, (state) => {
                state.putStudentInfoStatus = 'loading';
            })
            .addCase(putStudentInfo.fulfilled, (state) => {
                state.putStudentInfoStatus = 'success';
            })
            .addCase(putStudentInfo.rejected, (state) => {
                state.putStudentInfoStatus = 'failed';
            });
    }
});

export const { turnReservationWarningOff, resetPutStudentInfoStatus } = studentSlice.actions;

export default studentSlice.reducer;