import { Button, Card, CardActionArea, CardActions, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardHeader, CardLarge, MainIconStyle } from '../../app/AppStyles';
import { GetDateLong, GetTime } from '../../app/AppUtils';
import { setStudentRegisterPage } from '../search/student/searchStudentSlice';
import RegisterMomentsDisplay from './RegisterMomentsDisplay';
import { setPerformance } from './registerSlice';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreateIcon from '@mui/icons-material/Create';
import EuroIcon from '@mui/icons-material/Euro';
import GroupsIcon from '@mui/icons-material/Groups';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarningIcon from '@mui/icons-material/Warning';

export default function RegisterPerformancesCard({ performance, employeeMode }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const enrollClick = (name, id) => {
        dispatch(setPerformance(performance));

        if (employeeMode)
            dispatch(setStudentRegisterPage('SUMMARY'));
        else
            navigate(`/inschrijven/overzicht/${encodeURIComponent(name)}/${id}`)
    };

    const ItemFragment = ({ icon, text, label }) => {
        return (
            <ListItem sx={{ py: 0 }}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    secondary={label}
                />
            </ListItem>
        );
    };

    return (
        <Card sx={{ ...CardLarge, height: 1 }}>
            <CardActionArea onClick={() => enrollClick(performance.activity.name, performance.performanceId)} sx={{ flexGrow: 1 }}>
                <Grid container sx={{ height: 1 }}>
                    <Grid size={{ xs: 12 }} sx={{ p: 1 }}>
                        {performance.performanceName ?
                            <Typography variant="h6" sx={{ ...CardHeader }}>{performance.performanceName}</Typography> :
                            <Typography variant="h6" sx={{ ...CardHeader }}>{GetDateLong(performance.firstDate)}, {GetTime(performance.firstDate)}</Typography>}
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Typography variant="h6" sx={{ ...CardHeader, ml: 1, color: 'primary.main', fontSize: 16 }}>{resourceFinal[appLanguage].words.DATA}</Typography>
                        <List dense sx={{ p: 0 }}>
                            <ItemFragment icon={<CalendarMonthIcon sx={{ ...MainIconStyle }} />} text={performance.periode.name} label={resourceFinal[appLanguage].words.PERIOD} />
                            <ItemFragment icon={<GroupsIcon sx={{ ...MainIconStyle }} />} text={performance.occupation} label={resourceFinal[appLanguage].words.OCCUPATION} />
                            <ItemFragment icon={<LocationOnIcon sx={{ ...MainIconStyle }} />} text={performance.location.name} label={resourceFinal[appLanguage].words.LOCATION} />
                            {performance.paidActivity ? <ItemFragment icon={<EuroIcon sx={{ ...MainIconStyle }} />} text={Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(performance.cost)} label={resourceFinal[appLanguage].words.COST} /> : ""}
                        </List>
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                        <Typography variant="h6" sx={{ ...CardHeader, ml: 1, color: 'primary.main', fontSize: 16 }}>{performance.activityMoments.length === 1 ? resourceFinal[appLanguage].words.MOMENT : resourceFinal[appLanguage].words.MOMENTS} ({performance.activityMoments.length})</Typography>
                        <RegisterMomentsDisplay moments={performance.activityMoments} amount={3} />
                    </Grid>
                    {(!performance.inRooster && performance.alwaysAvailable) && <Grid size={{ xs: 12 }}>
                        <Typography variant="body2" sx={{ color: 'gray', textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ALWAYS_AVAILABLE}</Typography>
                    </Grid>}
                    {performance.periodeIsOutsideProgram && <Grid size={{ xs: 12 }}>
                        <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                            <WarningIcon color="warning" />
                            <Typography variant="body2" sx={{ color: 'warning.main', textAlign: 'center' }}>{resourceFinal[appLanguage].messages.REGISTER_FUTURE_PERFORMANCE_WARNING}</Typography>
                        </Stack>
                    </Grid>}
                    {(employeeMode && performance.invalidReasons?.length > 0) && <Grid size={{ xs: 12 }}>
                        {performance.invalidReasons.map((item, index) =>
                            <Stack key={index} spacing={1} direction="row" justifyContent="center" alignItems="center">
                                <WarningIcon color="error" />
                                <Typography variant="body2" sx={{ color: 'error.light', textAlign: 'center' }}>{item}</Typography>
                            </Stack>
                        )}
                    </Grid>}
                </Grid>
            </CardActionArea>
            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" color="secondary" startIcon={<CreateIcon />} onClick={() => enrollClick(performance.activity.name, performance.performanceId)}>{resourceFinal[appLanguage].words.REGISTER_BUTTON}</Button>
            </CardActions>
        </Card >
    );
}
