import { Avatar, IconButton, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton({ desktop, overlay }) {
    const navigate = useNavigate();
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const BackClick = () => {
        navigate(-1);
    };

    return (
        <>
            {desktop ? (
                overlay ? (
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow >
                        <IconButton sx={{
                            alignSelf: 'flex-end',
                            position: 'fixed',
                            bottom: 0,
                            right: 0
                        }} onClick={BackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip >
                ) : (
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={BackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                )
            ) : (
                <IconButton onClick={BackClick}>
                    <ArrowBackIcon sx={{ color: 'white.main', fontSize: 32 }} />
                </IconButton>
            )
            }
        </>
    );
}