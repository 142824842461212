import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        alertStatus: false,
        alertMessage: "",
        alertSeverity: "info",
    },
    reducers: {
        setAlertStatus: (state, action) => {
            state.alertStatus = action.payload;
        },
        setAlertMessage: (state, action) => {
            state.alertMessage = action.payload;
        },
        setAlertSeverity: (state, action) => {
            state.alertSeverity = action.payload;
        },
    },
    extraReducers: (builder) => { },
});

export const { setAlertStatus, setAlertMessage, setAlertSeverity } = alertSlice.actions;

export default alertSlice.reducer;