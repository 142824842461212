import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchAppSettings = createAsyncThunk(
    '/api/settings/appSettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/settings/appSettings', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState: {
        aspNetUser: null,
        appSettings: null,
        appSettingsStatus: null,
    },
    reducers: {
        // Get logged in gebruiker
        getUser: (state, action) => {
            state.aspNetUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppSettings.pending, (state) => {
                state.appSettingsStatus = 'loading'
            })
            .addCase(fetchAppSettings.fulfilled, (state, action) => {
                state.appSettingsStatus = 'success';
                state.appSettings = action.payload;
            })
            .addCase(fetchAppSettings.rejected, (state) => {
                state.appSettingsStatus = 'failed';
            })
    },
});

export const { getUser } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
