import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getLocations = createAsyncThunk(
    '/api/locationManagement',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/locationManagement`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putLocation = createAsyncThunk(
    '/api/locationManagement/put',
    async (updatedLocation, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/locationManagement`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedLocation)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const postLocation = createAsyncThunk(
    '/api/locationManagement/post',
    async (newLocation, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/locationManagement`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newLocation)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const deleteLocation = createAsyncThunk(
    '/api/locationManagement/delete',
    async (locationId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/locationManagement/${locationId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const locationManagementSlice = createSlice({
    name: 'locationManagement',
    initialState: {
        locations: null,
        locationsStatus: null,
        selectedLocation: null,
        putLocationStatus: null,
        postLocationStatus: null,
        deleteLocationStatus: null,
        showFailed: false,
    },
    reducers: {
        selectLocation: (state, action) => {
            state.selectedLocation = action.payload;
        },
        setFailedLocation: (state, action) => {
            state.showFailed = action.payload;
        },
        resetLocationStatus: (state) => {
            state.locationsStatus = null;
            state.putLocationStatus = null;
            state.postLocationStatus = null;
            state.deleteLocationStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get locations
        builder
            .addCase(getLocations.pending, (state, action) => {
                state.locationsStatus = 'loading';
            })
            .addCase(getLocations.fulfilled, (state, action) => {
                state.locationsStatus = 'success';
                state.locations = action.payload;
            })
            .addCase(getLocations.rejected, (state, action) => {
                state.locationsStatus = 'failed';
            });

        // Put location
        builder
            .addCase(putLocation.pending, (state, action) => {
                state.putLocationStatus = 'loading';
            })
            .addCase(putLocation.fulfilled, (state, action) => {
                state.putLocationStatus = 'success';
            })
            .addCase(putLocation.rejected, (state, action) => {
                state.putLocationStatus = 'failed';
            });

        // Post location
        builder
            .addCase(postLocation.pending, (state, action) => {
                state.postLocationStatus = 'loading';
            })
            .addCase(postLocation.fulfilled, (state, action) => {
                state.postLocationStatus = 'success';
            })
            .addCase(postLocation.rejected, (state, action) => {
                state.postLocationStatus = 'failed';
            });

        // Delete location
        builder
            .addCase(deleteLocation.pending, (state, action) => {
                state.deleteLocationStatus = 'loading';
            })
            .addCase(deleteLocation.fulfilled, (state, action) => {
                state.deleteLocationStatus = 'success';
            })
            .addCase(deleteLocation.rejected, (state, action) => {
                state.deleteLocationStatus = 'failed';
            });
    },
});

export const {
    resetLocationStatus,
    selectLocation,
    setFailedLocation
} = locationManagementSlice.actions;

export default locationManagementSlice.reducer;
