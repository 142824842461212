import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const checkUser = createAsyncThunk(
    "/api/user/check",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/user/check`, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const fetchUserData = createAsyncThunk(
    "/api/user/data",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/user/data`, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        userStatus: null,
        userCheck: null,
        userCheckStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // User Check
        builder
            .addCase(checkUser.pending, (state) => {
                state.userCheckStatus = "loading";
            })
            .addCase(checkUser.fulfilled, (state, action) => {
                state.userCheckStatus = "success";
                state.userCheck = action.payload;
            })
            .addCase(checkUser.rejected, (state, action) => {
                state.userCheckStatus = "failed";
                state.userCheck = action.payload;
            });

        // User Data
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.userStatus = "loading";
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.userStatus = "success";
                state.user = action.payload;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.userStatus = "failed";
                state.user = action.payload;
            });
    },
});

export default userSlice.reducer;
