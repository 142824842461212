import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../subcomponents/PageLayout';
import ActivityTab from './activity/ActivityTab';
import LocationTab from './location/LocationTab';
import TypeTab from './types/TypeTab';

export default function ManagementPage() {
    const [value, setValue] = useState(0);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT} content={
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label={resourceFinal[appLanguage].words.ACTIVITIES} />
                        <Tab label={resourceFinal[appLanguage].words.LOCATIONS} />
                        <Tab label={resourceFinal[appLanguage].words.TYPES} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ActivityTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <LocationTab />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <TypeTab />
                </TabPanel>
            </>
        } bottomBar fullScreen />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 2, bgcolor: 'white.light', borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
