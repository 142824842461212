import { Button, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { putStudentInfo } from './studentSlice';

export default function StudentInfoEditCard({ object }) {
    const dispatch = useDispatch();

    const {
        field,
        label,
        currentValue,
        errorMessage,
        type
    } = object;

    const { putStudentInfoStatus } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const [saveFailed, setSaveFailed] = useState(false);
    const [disabled, setDisabled] = useState(false);

    function isValidEmail(input) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input); };
    function isValidAnyPhone(input) { return /^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9]){8}$/.test(input); };
    function isValidIban(input) { return /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/.test(input); };
    function isValidMobilePhone(input) { return /^\(?(0)-?6(\s?|-)([0-9]\s{0,2}){8}$/.test(input); };
    function isValidValue(input) {
        if (input !== null && input !== undefined && input !== '') {
            return true;
        }
        return false;
    }

    function isValid(input) {
        if (field === "email") return isValidEmail(input);
        if (field === "mobilePhone") return isValidMobilePhone(input);
        if (field === "anyPhone") return isValidAnyPhone(input);
        if (field === "iban") return isValidIban(input);
        return isValidValue(input);
    };

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue]);

    useEffect(() => {
        if (error !== null && isValid(value)) {
            setError(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleChange = event => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (putStudentInfoStatus === "success") {
            window.location.reload();
        } else if (putStudentInfoStatus === "failed") {
            setSaveFailed(true);
            setDisabled(false);
        };
    }, [dispatch, putStudentInfoStatus]);

    const submitValue = () => {
        if (!isValid(value) || value === null) {
            setError(errorMessage);
        } else {
            dispatch(putStudentInfo({ key: field, newValue: value }));
            setDisabled(true);
        };
    };

    return (
        <Card sx={{ display: 'flex', p: 3 }}>
            {object &&
                <Stack spacing={1} sx={{ flex: "1 1 auto", alignItems: 'center' }}>
                    {type === "default" &&
                        <TextField
                            fullWidth
                            defaultValue={currentValue}
                            error={error !== null}
                            helperText={error}
                            variant="outlined"
                            onChange={handleChange}
                            label={label}
                        />}

                    {type === "number" &&
                        <TextField
                            fullWidth
                            type="number"
                            inputProps={{ step: field === "shoeSizeEU" ? 0.5 : 0.1, min: 1, max: 999 }}
                            defaultValue={currentValue}
                            error={error !== null}
                            helperText={error}
                            variant="outlined"
                            onChange={handleChange}
                            label={label}
                        />}

                    {type === "bool" &&
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <RadioGroup onChange={handleChange}>
                                <FormControlLabel value="True" control={<Radio />} label={resourceFinal[appLanguage].words.YES} />
                                <FormControlLabel value="False" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                            </RadioGroup>
                        </FormControl>}

                    {type === "ovSubscription" &&
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <RadioGroup onChange={handleChange}>
                                <FormControlLabel value="Week" control={<Radio />} label={resourceFinal[appLanguage].words.STUDENT_DATA_WEEK} />
                                <FormControlLabel value="Weekend" control={<Radio />} label={resourceFinal[appLanguage].words.STUDENT_DATA_WEEKEND} />
                                <FormControlLabel value="Geen" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                            </RadioGroup>
                        </FormControl>}

                    {type === "skiExperience" &&
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <RadioGroup onChange={handleChange}>
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_NO} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_NO} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES1} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES1} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES2} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES2} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES3} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES3} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES4} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES4} />
                            </RadioGroup>
                        </FormControl>}

                    {type === "sport" &&
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <RadioGroup onChange={handleChange}>
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SPORT_1} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SPORT_1} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_SPORT_2} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_SPORT_2} />
                            </RadioGroup>
                        </FormControl>}

                    {type === "ownedMaterial" &&
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <RadioGroup onChange={handleChange}>
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_1} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_1} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_2} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_2} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_3} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_3} />
                                <FormControlLabel value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_4} control={<Radio />} label={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_4} />
                            </RadioGroup>
                        </FormControl>}

                    {saveFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                    <Button disabled={disabled} variant="outlined" color="secondary" onClick={submitValue} sx={{ ml: 1 }}>{resourceFinal[appLanguage].words.SAVE}</Button>
                </Stack>
            }
        </Card>
    );
}
