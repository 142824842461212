import { alpha, Box, Button, CardMedia, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, PageHeader, TableHeader } from '../../../app/AppStyles';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import ActivityTypeEditCard from './ActivityTypeEditCard';
import HeadTypeEditCard from './HeadTypeEditCard';
import { deleteActivityType, deleteHeadType, getActivityTypes, getHeadTypes, resetTypeStatus, selectType, setFailedType } from './typeManagementSlice';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function TypeTab() {
    const dispatch = useDispatch();
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [modalVersion, setModalVersion] = useState("");
    const [headFilter, setHeadFilter] = useState("");
    const [activityFilter, setActivityFilter] = useState("");
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { headTypesStatus, activityTypesStatus, deleteHeadTypeStatus, deleteActivityTypeStatus } = useSelector((state) => state.management.type);

    useEffect(() => {
        if (deleteHeadTypeStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetTypeStatus());
            dispatch(getHeadTypes());
        } else if (deleteHeadTypeStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetTypeStatus());
        };
    }, [deleteHeadTypeStatus]);

    useEffect(() => {
        if (deleteActivityTypeStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetTypeStatus());
            dispatch(getActivityTypes());
        } else if (deleteActivityTypeStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetTypeStatus());
        };
    }, [deleteActivityTypeStatus]);

    useEffect(() => {
        if (headTypesStatus !== "success")
            dispatch(getHeadTypes());
    }, [dispatch, headTypesStatus]);

    useEffect(() => {
        if (activityTypesStatus !== "success")
            dispatch(getActivityTypes());
    }, [dispatch, activityTypesStatus]);

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(setFailedType(false));
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedType(false));
    };

    const handleSearchChangeHead = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setHeadFilter(value);
        }, 600);
    };

    const handleSearchChangeActivity = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setActivityFilter(value);
        }, 600);
    };

    var customFilterFunctionHead = (item) => {
        return item.naam?.toLowerCase().includes(headFilter.toLowerCase());
    };

    var customFilterFunctionActivity = (item) => {
        return item.type?.toLowerCase().includes(activityFilter.toLowerCase());
    };

    const newClick = (version) => {
        setModalVersion(version)
        dispatch(selectType(null));
        setOpenNew(!openNew);
    };

    const TableFragmentHead = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const { headTypes } = useSelector((state) => state.management.type);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - headTypes?.filter(customFilterFunctionHead).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (headType) => {
            dispatch(selectType(headType));
            setOpenEdit(!openEdit);
            setModalVersion("Head");
        };

        const deleteClick = (headTypeId) => {
            dispatch(deleteHeadType(headTypeId));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? headTypes.filter(customFilterFunctionHead).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : headTypes.filter(customFilterFunctionHead)).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.naam ? item.naam : "-"}</TableCell>
                            <Tooltip title={item.beschrijving}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.beschrijving ? item.beschrijving : "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center">
                                {item.imageUrl ? <CardMedia component="img" sx={{ height: 50, width: 50, mx: 'auto' }} image={item.imageUrl} alt="location" /> : "-"}
                            </TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.naam} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? headTypes.filter(customFilterFunctionHead).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : headTypes.filter(customFilterFunctionHead)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={5} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={headTypes?.filter(customFilterFunctionHead).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    const TableFragmentActivity = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const { activityTypes } = useSelector((state) => state.management.type);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - activityTypes?.filter(customFilterFunctionActivity).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (headType) => {
            dispatch(selectType(headType));
            setOpenEdit(!openEdit);
            setModalVersion("Activity");
        };

        const deleteClick = (activityTypeId) => {
            dispatch(deleteActivityType(activityTypeId));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? activityTypes.filter(customFilterFunctionActivity).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : activityTypes.filter(customFilterFunctionActivity)).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.type ? item.type : "-"}</TableCell>
                            <Tooltip title={item.description}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.description ? item.description : "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="left">{item.headType ? item.headType : "-"}</TableCell>
                            <TableCell align="center">
                                {item.imageUrl ? <CardMedia component="img" sx={{ height: 50, width: 50, mx: 'auto' }} image={item.imageUrl} alt="location" /> : "-"}
                            </TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.type} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? activityTypes.filter(customFilterFunctionActivity).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : activityTypes.filter(customFilterFunctionActivity)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 63 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={activityTypes?.filter(customFilterFunctionActivity).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
            <Grid container spacing={1} sx={{ display: 'flex', flex: '1 1 auto' }}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ContentLoader successCondition={headTypesStatus === "success"} errorCondition={headTypesStatus === "failed"} content={
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                                <SearchField func={handleSearchChangeHead} textRef={textRef} />
                                <Typography variant="h6" sx={{ ...PageHeader, pb: 0 }}>{resourceFinal[appLanguage].words.TYPE_HEAD}</Typography>
                                <Button variant="outlined" onClick={() => newClick("Head")} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.IMAGE}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableFragmentHead />
                                </Table>
                            </TableContainer>
                        </Box>
                    } />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <ContentLoader successCondition={activityTypesStatus === "success"} errorCondition={activityTypesStatus === "failed"} content={
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                                <SearchField func={handleSearchChangeActivity} textRef={textRef} />
                                <Typography variant="h6" sx={{ ...PageHeader, pb: 0 }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.TYPE.toLowerCase()}</Typography>
                                <Button variant="outlined" onClick={() => newClick("Activity")} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.TYPE_HEAD}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.IMAGE}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableFragmentActivity />
                                </Table>
                            </TableContainer>
                        </Box>
                    } />
                </Grid>
            </Grid>

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    {modalVersion === "Head" && <HeadTypeEditCard key={"Edit"} handleClose={callbackModalEdit} />}
                    {modalVersion === "Activity" && <ActivityTypeEditCard key={"Edit"} handleClose={callbackModalEdit} />}
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    {modalVersion === "Head" && <HeadTypeEditCard key={"Add"} handleClose={callbackModalNew} addNew />}
                    {modalVersion === "Activity" && <ActivityTypeEditCard key={"Add"} handleClose={callbackModalNew} addNew />}
                </>
            </Modal>
        </Box>
    );
}
