import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmployeeInfoContent from '../employee/EmployeeInfoContent';
import StudentInfoContentV1 from '../student/StudentInfoContentV1';
import StudentInfoContentV2 from '../student/StudentInfoContentV2';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import VersionSwitcher from '../subcomponents/VersionSwitcher';
import { fetchUserData } from './userSlice';

export default function UserInfoPage() {
    const dispatch = useDispatch();

    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user, userStatus } = useSelector((state) => state.user);

    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchUserData());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, aspNetUser]);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.STUDENT_DATA} content={
            <ContentLoader successCondition={userStatus === "success"} errorCondition={userStatus === "failed"} content={
                <Box sx={{ mt: { xs: 0, md: 1 } }}>
                    {aspNetUser?.role?.includes("Student") ?
                        <VersionSwitcher content1={<StudentInfoContentV1 content={user} editable />} content2={<StudentInfoContentV2 content={user} editable />} />
                        : aspNetUser?.role?.includes("Employee") ?
                            <EmployeeInfoContent />
                            : ""}
                </Box>
            } />
        } bottomBar />
    );
}
