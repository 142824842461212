import { Box, Button, Card, CardActionArea, CardActions, CardContent, Stack, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardLarge } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import { incrArchiveCounter, updateNotification } from './notificationSlice';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

export default function NotificationsCard({ notification }) {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const { id, dateSent, title, subject, body, displayName, read, archive } = notification;
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const expandClick = () => {
        setExpanded(!expanded);

        if (!read)
            dispatch(updateNotification({ notificationId: id, archive: false }));
    };

    const archiveClick = () => {
        dispatch(incrArchiveCounter());
        dispatch(updateNotification({ notificationId: id, archive: !archive }));
    };

    return (
        <Card sx={{ ...CardLarge }}>
            <CardActionArea onClick={expandClick}>
                <CardContent>
                    <Typography variant="h6" sx={{ ...CardHeader, color: read ? archive ? 'white.dark' : 'primary.light' : 'secondary.main', fontWeight: read ? '' : 'bold' }}>{title}</Typography>
                    {subject !== title && <Typography variant="subtitle1">{subject}</Typography>}
                    {expanded && <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body.replace(/\\n/g, '<br />')) }} />}
                    <Stack direction="row" spacing={1} sx={{ mt: expanded ? 1 : 0 }}>
                        <Typography variant="subtitle2"><Box component="span" sx={{ color: 'primary.dark' }}>{resourceFinal[appLanguage].words.SENDER}:</Box> {displayName}</Typography>
                        <Typography variant="subtitle2"><Box component="span" sx={{ color: 'primary.dark' }}>{resourceFinal[appLanguage].words.DATE_SENT}:</Box> {GetDate(dateSent)} ({GetTime(dateSent)})</Typography>
                    </Stack>
                </CardContent>
            </CardActionArea>
            {expanded && <CardActions>
                <Button color="secondary" onClick={archiveClick} startIcon={archive ? <UnarchiveIcon /> : <ArchiveIcon />}>{archive ? resourceFinal[appLanguage].words.UNARCHIVE : resourceFinal[appLanguage].words.ARCHIVE}</Button>
            </CardActions>}
        </Card >
    );
}
