import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchSubscriptions = createAsyncThunk(
    '/api/subscription/subscriptons/performanceId',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/subscription/subscriptions/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchUnsubscriptions = createAsyncThunk(
    '/api/subscription/unsubscriptions/performanceId',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/subscription/unsubscriptions/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putSubscription = createAsyncThunk(
    '/api/subscription/put',
    async (updatedSubscriptions, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/subscription`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedSubscriptions)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postSubscription = createAsyncThunk(
    '/api/subscription/post',
    async (newSubscriptions, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/subscription`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newSubscriptions)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteSubscription = createAsyncThunk(
    '/api/subscription/delete',
    async (unsubscriptions, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/subscription`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
            body: JSON.stringify(unsubscriptions)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscriptions: null,
        subscriptionsStatus: null,
        unsubscriptions: null,
        unsubscriptionsStatus: null,
        putSubscriptionStatus: null,
        postSubscriptionStatus: null,
        deleteSubscriptionStatus: null,
        showFailed: false,
    },
    reducers: {
        setFailedSubscription: (state, action) => {
            state.showFailed = action.payload;
        },
        resetSubscriptionStatus: (state) => {
            state.subscriptionsStatus = null;
            state.unsubscriptionsStatus = null;
            state.putSubscriptionStatus = null;
            state.postSubscriptionStatus = null;
            state.deleteSubscriptionStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get subscriptions
        builder
            .addCase(fetchSubscriptions.pending, (state) => {
                state.subscriptionsStatus = 'loading';
            })
            .addCase(fetchSubscriptions.fulfilled, (state, action) => {
                state.subscriptions = action.payload;
                state.subscriptionsStatus = 'success';
            })
            .addCase(fetchSubscriptions.rejected, (state) => {
                state.subscriptionsStatus = 'failed';
            });

        // Get unsubscriptions
        builder
            .addCase(fetchUnsubscriptions.pending, (state) => {
                state.unsubscriptionsStatus = 'loading';
            })
            .addCase(fetchUnsubscriptions.fulfilled, (state, action) => {
                state.unsubscriptions = action.payload;
                state.unsubscriptionsStatus = 'success';
            })
            .addCase(fetchUnsubscriptions.rejected, (state) => {
                state.unsubscriptionsStatus = 'failed';
            });

        // Put subscription
        builder
            .addCase(putSubscription.pending, (state) => {
                state.putSubscriptionStatus = 'loading';
            })
            .addCase(putSubscription.fulfilled, (state) => {
                state.putSubscriptionStatus = 'success';
            })
            .addCase(putSubscription.rejected, (state) => {
                state.putSubscriptionStatus = 'failed';
            });

        // Post subscription
        builder
            .addCase(postSubscription.pending, (state) => {
                state.postSubscriptionStatus = 'loading';
            })
            .addCase(postSubscription.fulfilled, (state) => {
                state.postSubscriptionStatus = 'success';
            })
            .addCase(postSubscription.rejected, (state) => {
                state.postSubscriptionStatus = 'failed';
            });

        // Delete subscription
        builder
            .addCase(deleteSubscription.pending, (state) => {
                state.deleteSubscriptionStatus = 'loading';
            })
            .addCase(deleteSubscription.fulfilled, (state) => {
                state.deleteSubscriptionStatus = 'success';
            })
            .addCase(deleteSubscription.rejected, (state) => {
                state.deleteSubscriptionStatus = 'failed';
            });
    }
});

export const { setFailedSubscription, resetSubscriptionStatus } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;