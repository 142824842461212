import { Badge, Button, Stack, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HomeButton } from '../../app/AppStyles';
import IconDisplay from '../subcomponents/IconDisplay';

export default function TileButton({ text }) {
    const navigate = useNavigate();
    const { newsCount } = useSelector((state) => state.news);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handlePathDirection = (path) => {
        const pathMap = {
            [resourceFinal[appLanguage].words.SCHEDULE]: '/agenda',
            [resourceFinal[appLanguage].words.REGISTER]: '/inschrijven',
            [resourceFinal[appLanguage].words.PROGRESS]: '/voortgang',
            [resourceFinal[appLanguage].words.CONTACT]: '/contact',
            [resourceFinal[appLanguage].words.NEWS]: '/nieuws',
            [resourceFinal[appLanguage].words.FAVORITES]: '/favorieten',
            [resourceFinal[appLanguage].words.INFORMATION]: '/informatie',
            [resourceFinal[appLanguage].words.TIMETABLE]: '/rooster',
            [resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT]: '/activiteitenbeheer',
            [resourceFinal[appLanguage].words.PROGRAM_MANAGEMENT]: '/programmabeheer',
            [resourceFinal[appLanguage].words.PLANNING]: '/plannen',
            [resourceFinal[appLanguage].words.WEB_MANAGEMENT]: '/webbeheer',
            [resourceFinal[appLanguage].words.REPORTS_PAGE]: '/rapporten',
            [resourceFinal[appLanguage].words.ADMIN_PAGE]: '/admin',
            [resourceFinal[appLanguage].words.HOME]: '/',
            [resourceFinal[appLanguage].words.ABOUT]: '/over',
            [resourceFinal[appLanguage].words.ATTENDANCE]: '/presentie',
            [resourceFinal[appLanguage].words.SEARCH_PAGE]: '/zoeken/student',
            [resourceFinal[appLanguage].words.MESSAGES_PAGE]: '/berichten',
            [resourceFinal[appLanguage].words.EMPLOYEE_PAGE]: '/medewerkers',
            [resourceFinal[appLanguage].words.TRAVEL_COSTS]: '/reiskosten'
        };

        return pathMap[path] || '/';
    };

    return (
        <>
            <Button sx={{ ...HomeButton }} variant="contained" onClick={() => navigate(handlePathDirection(text))}>
                <Stack alignItems="center">
                    {text === 'Nieuws' && newsCount > 0 ? <Badge badgeContent={newsCount} color="secondary"><IconDisplay component={text} style={{ color: 'white.main' }} /></Badge> : <IconDisplay style={{ color: 'white.main' }} component={text} />}
                    <Typography variant="body1" noWrap sx={{ fontSize: { xs: 15, md: 17 }, color: 'white.main' }}>{text}</Typography>
                </Stack>
            </Button>
        </>
    )
}