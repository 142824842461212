import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import NewsArticleCard from './NewsArticleCard';
import { addNewsUser, fetchArticle } from './newsSlice';

export default function NewsArticlePage() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { articleStatus } = useSelector((state) => state.news);
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        if (aspNetUser !== null)
            dispatch(addNewsUser(id));

        dispatch(fetchArticle(id));
    }, [dispatch, id, aspNetUser]);

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.NEWS}`} content={
            <ContentLoader successCondition={articleStatus === "success"} errorCondition={articleStatus === "failed"} content={
                <NewsArticleCard />
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}
