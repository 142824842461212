import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, TableHeader } from '../../../app/AppStyles';
import { fetchAvailableStudents } from '../../student/studentSlice';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { fetchSubscriptions, fetchUnsubscriptions, postSubscription, resetSubscriptionStatus, setFailedSubscription } from './subscriptionSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function SubscriptionAddCard({ handleClose, performanceId }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { availableStudents } = useSelector((state) => state.student);
    const { postSubscriptionStatus, showFailed } = useSelector((state) => state.subscription);
    const [filter, setFilter] = useState("");
    const textRef = useRef(null);

    // Post status callback
    useEffect(() => {
        if (postSubscriptionStatus === "success") {
            dispatch(setFailedSubscription(false));
            dispatch(resetSubscriptionStatus());
            dispatch(fetchSubscriptions(performanceId));
            dispatch(fetchUnsubscriptions(performanceId));
            dispatch(fetchAvailableStudents(performanceId));
            handleClose();
        } else if (postSubscriptionStatus === "failed") {
            dispatch(resetSubscriptionStatus());
            dispatch(setFailedSubscription(true));
        };
    }, [dispatch, handleClose, postSubscriptionStatus, performanceId]);

    // Property states
    const [newStudents, setNewStudents] = useState([]);
    const [newIsReservation, setNewIsReservation] = useState(false);
    const [errorStudents, setErrorStudents] = useState(null);

    const handleIsReservationChange = (event) => setNewIsReservation(!newIsReservation);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newStudents) return setErrorStudents(`${resourceFinal[appLanguage].messages.ERROR_LIST_EMPTY}`);
        setErrorStudents(null);

        var subscriptions = [];
        newStudents.forEach(item => subscriptions.push({
            performance: { id: performanceId },
            student: { studentId: item.studentId },
            isReservation: newIsReservation,
            createdBy: `AP/${user.number}`
        }));

        dispatch(postSubscription(subscriptions));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.fullname?.toLowerCase().includes(filter.toLowerCase()) || item.studentNumber?.toLowerCase().includes(filter.toLowerCase());
    };

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (filter ? numSelected < availableStudents?.filter(customFilterFunction).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (filter ? numSelected === availableStudents?.filter(customFilterFunction).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = availableStudents?.filter(customFilterFunction).map((n) => n);
            setNewStudents(newSelected);
            return;
        }
        setNewStudents([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = newStudents.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(newStudents, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(newStudents.slice(1));
        } else if (selectedIndex === newStudents.length - 1) {
            newSelected = newSelected.concat(newStudents.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                newStudents.slice(0, selectedIndex),
                newStudents.slice(selectedIndex + 1),
            );
        };

        setNewStudents(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - availableStudents?.filter(customFilterFunction).length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (id) => newStudents.findIndex(x => x.studentId === id) !== -1;

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1} sx={{ flex: '1 1 auto', maxWidth: 1, p: 0.5 }}>
                        <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.STUDENTS} {resourceFinal[appLanguage].words.REGISTER.toLowerCase()}</Typography>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <TableContainer>
                            <Table>
                                <EnhancedTableHead numSelected={newStudents.length} onSelectAllClick={handleSelectAllClick} rowCount={availableStudents?.length} />
                                <TableBody>
                                    {(rowsPerPage > 0 ? availableStudents?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableStudents?.filter(customFilterFunction))?.map((item, index) => {
                                        const isItemSelected = isSelected(item.studentId);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow hover onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.studentId} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                                </TableCell>
                                                <TableCell align="center">{item.studentNumber}</TableCell>
                                                <TableCell align="left">{item.fullname}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {(rowsPerPage > 0 ? availableStudents?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : availableStudents?.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            sx={{ border: 0 }}
                                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                                            count={availableStudents ? availableStudents.length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        {errorStudents && <Typography variant="body1" color="error" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{errorStudents}</Typography>}
                        <Typography variant="body1" sx={{ display: 'flex', justifyContent: 'center', alignText: 'center' }}>{resourceFinal[appLanguage].words.STUDENTS} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}: {newStudents?.length}</Typography>
                        <FormControlLabel sx={{ display: 'flex', justifyContent: 'center' }} control={< Checkbox onChange={handleIsReservationChange} />} label={resourceFinal[appLanguage].messages.SET_RESERVATION} />
                        {showFailed && <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>}
                        <ButtonGroup sx={{ justifyContent: 'center' }}>
                            <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                            <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>
                        </ButtonGroup>
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}
