import { Box, List, ListItem, ListItemIcon, ListItemText, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetDateLong, GetTime } from '../../app/AppUtils';

import TodayIcon from '@mui/icons-material/Today';

export default function RegisterMomentsDisplay({ moments, amount, pagination, dense }) {
    const itemCount = amount;
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState();

    useEffect(() => {
        setPageCount(Math.ceil(moments?.length / itemCount));
    }, [moments, itemCount]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', justifyContent: 'space-between' }}>
            <List dense={dense} sx={{ p: 0 }}>
                {moments?.slice((page - 1) * itemCount, page * itemCount).map((item) =>
                    <ListItem key={item.momentId} sx={{ py: 0 }}>
                        <ListItemIcon>
                            <TodayIcon sx={{ mx: 1, color: 'primary.main' }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={GetDateLong(item.starttime)}
                            secondary={<>{GetTime(item.starttime)} - {GetTime(item.endtime)}</>}
                        />
                    </ListItem>
                )}
            </List>
            {pagination && pageCount > 1 ?
                <Pagination sx={{ alignSelf: 'center', mb: 1 }} count={pageCount} page={page} onChange={handleChange}
                    defaultPage={1} color="primary" shape="rounded" size="small" /> : ""}
        </Box>
    );
}
