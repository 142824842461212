import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAppSettings } from '../settings/appSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { putSetting, resetUpdateStatus } from './adminSlice';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function AppSettingsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings, appSettingsStatus } = useSelector((state) => state.settings.app);
    const { updateSettingStatus } = useSelector((state) => state.admin);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchAppSettings());
        } else if (updateSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus]);

    const [showPassword, setShowPassword] = useState(false);

    // App Version setting
    const [newAppVersion, setNewAppVersion] = useState();

    useEffect(() => {
        if (newAppVersion) {
            dispatch(putSetting({
                key: "App_Version",
                value: newAppVersion,
                modifiedBy: `AP/${user.number}`
            }));
        };
    }, [dispatch, user, newAppVersion]);

    // Show Schedule Period setting
    const [newWintersportMode, setNewWintersportMode] = useState(null);

    useEffect(() => {
        if (newWintersportMode !== null) {
            dispatch(putSetting({
                key: "App_WintersportModeEnabled",
                value: newWintersportMode ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newWintersportMode]);

    // Notification enabled setting
    const [newNotificationsEnabled, setNewNotificationsEnabled] = useState(null);

    useEffect(() => {
        if (newNotificationsEnabled !== null) {
            dispatch(putSetting({
                key: "Notification_Enabled",
                value: newNotificationsEnabled ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newNotificationsEnabled]);

    // Notification required setting
    const [newNotificationsRequired, setNewNotificationsRequired] = useState(null);

    useEffect(() => {
        if (newNotificationsRequired !== null) {
            dispatch(putSetting({
                key: "Notification_Required",
                value: newNotificationsRequired ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newNotificationsRequired]);

    // Show Schedule Period setting
    const [newShowSchedulePeriod, setNewShowSchedulePeriod] = useState(null);

    useEffect(() => {
        if (newShowSchedulePeriod !== null) {
            dispatch(putSetting({
                key: "App_ShowSchedulePeriod",
                value: newShowSchedulePeriod ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newShowSchedulePeriod]);

    // Show Schedule Period setting
    const [newActivityProgressOrder, setNewActivityProgressOrder] = useState();

    useEffect(() => {
        if (newActivityProgressOrder) {
            dispatch(putSetting({
                key: "App_ActivityProgressOrder",
                value: newActivityProgressOrder,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newActivityProgressOrder]);

    return (
        <ContentLoader successCondition={appSettingsStatus === "success"} errorCondition={appSettingsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.APP_VERSION}</FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={appSettings?.appVersion}
                                    onChange={(e) => setNewAppVersion(e.target.value)}
                                    variant="standard"
                                >
                                    <MenuItem value={"1"}>{resourceFinal[appLanguage].admin.APP_VERSION_1}</MenuItem>
                                    <MenuItem value={"2"}>{resourceFinal[appLanguage].admin.APP_VERSION_2}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText>{resourceFinal[appLanguage].admin.APP_VERSION_HTEXT}</FormHelperText>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.APP_WINTERSPORT_MODE}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={appSettings?.wintersportModeEnabled} onChange={(e) => setNewWintersportMode(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.APP_WINTERSPORT_MODE_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.APP_NOTIFS_ON_HEAD}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={appSettings?.notificationEnabled} onChange={(e) => setNewNotificationsEnabled(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                            <FormControlLabel control={
                                <Checkbox disabled={!appSettings?.notificationEnabled} defaultChecked={appSettings?.notificationRequired} onChange={(e) => setNewNotificationsRequired(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.APP_NOTIFS_REQ} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.APP_NOTIFS_ON_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.APP_SHOW_PERIOD_SCHEDULE}</FormLabel>
                            <FormControlLabel control={
                                <Checkbox defaultChecked={appSettings?.showSchedulePeriod} onChange={(e) => setNewShowSchedulePeriod(e.target.checked)} />
                            } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                            <FormHelperText>{resourceFinal[appLanguage].admin.APP_SHOW_PERIOD_SCHEDULE_HTEXT}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Stack spacing={1} sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                            <FormLabel component="legend">{resourceFinal[appLanguage].admin.APP_ACTIVITY_PROGRESS_ORDER}</FormLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={appSettings?.activityProgressOrder}
                                    onChange={(e) => setNewActivityProgressOrder(e.target.value)}
                                    variant="standard"
                                >
                                    <MenuItem value={"Default"}>Default</MenuItem>
                                    <MenuItem value={"Name"}>Name</MenuItem>
                                    <MenuItem value={"Moment"}>Moment</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText>{resourceFinal[appLanguage].admin.APP_ACTIVITY_PROGRESS_ORDER_HTEXT}</FormHelperText>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        } />
    );
}
