import { Box, Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../subcomponents/PageLayout';
import AdminFunctionsTab from './AdminFunctionsTab';
import AppSettingsTab from './AppSettingsTab';
import NavSettingsTab from './NavSettingsTab';
import SiomSettingsTab from './SiomSettingsTab';
import StudentSettingsTab from './StudentSettingsTab';
import ThemeSettingsTab from './ThemeSettingsTab';
import { setAdminTabindex } from './adminSlice';
import ExternalConnectionsTab from './ExternalConnectionsTab';

export default function AdminPage() {
    const dispatch = useDispatch();
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { adminTabIndex } = useSelector((state) => state.admin);

    const handleChange = (event, newValue) => {
        dispatch(setAdminTabindex(newValue))
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.ADMIN_PAGE} content={
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={adminTabIndex} onChange={handleChange}>
                        <Tab label={`${resourceFinal[appLanguage].words.APPLICATION} ${resourceFinal[appLanguage].words.SETTINGS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.NAVIGATION} ${resourceFinal[appLanguage].words.SETTINGS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.SIOM} ${resourceFinal[appLanguage].words.SETTINGS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.STUDENT} ${resourceFinal[appLanguage].words.SETTINGS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.THEME} ${resourceFinal[appLanguage].words.SETTINGS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.EXTERNAL} ${resourceFinal[appLanguage].words.CONNECTIONS.toLowerCase()}`} />
                        <Tab label={`${resourceFinal[appLanguage].words.SYSTEM} ${resourceFinal[appLanguage].words.FUNCTIONS.toLowerCase()}`} />
                    </Tabs>
                </Box>
                <TabPanel value={adminTabIndex} index={0}>
                    <AppSettingsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={1}>
                    <NavSettingsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={2}>
                    <SiomSettingsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={3}>
                    <StudentSettingsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={4}>
                    <ThemeSettingsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={5}>
                    <ExternalConnectionsTab />
                </TabPanel>
                <TabPanel value={adminTabIndex} index={6}>
                    <AdminFunctionsTab />
                </TabPanel>
            </>
        } bottomBar fullScreen />
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 2, bgcolor: 'white.light', borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
