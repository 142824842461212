import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from "react-redux";

const layout = {
    display: "flex",
    flexDirection: "column",
    flex: '1 1 auto',
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
};

export default function LoadingDisplay({ style, textDisabled }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Box sx={{ ...layout }}>
            <CircularProgress sx={{ p: 4, ...style }} />
            {textDisabled ? "" : <Typography variant="h6">{resourceFinal[appLanguage].messages.LOADING_MESSAGE}</Typography>}
        </Box>
    )
}