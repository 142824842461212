import { Backdrop, Button, Card, CardContent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { turnReservationWarningOff } from '../student/studentSlice';
import { fetchSchedule } from './scheduleSlice';

export default function ScheduleReservationWarning() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { schedule, scheduleStatus } = useSelector((state) => state.schedule);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { showReservationWarning } = useSelector((state) => state.student);

    useEffect(() => {
        dispatch(fetchSchedule());
    }, [dispatch]);

    const handleClose = () => dispatch(turnReservationWarningOff());

    return (
        <>
            {location.pathname.toLocaleLowerCase().startsWith("/inschrijven/betaling/") ? "" :
                (scheduleStatus === "success" && showReservationWarning && schedule.filter(e => e.isReservation === true).length > 0) &&
                    <Backdrop open={showReservationWarning} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Card sx={{ height: 'auto', mx: 2, textAlign: 'center' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h6">{resourceFinal[appLanguage].messages.WARNING_RESERVATION_SCHEDULE}</Typography>
                                <Button sx={{ mt: 2 }} onClick={handleClose} variant="outlined" color="secondary">{resourceFinal[appLanguage].words.OK}</Button>
                            </CardContent>
                        </Card>
                    </Backdrop>
            }
        </>
    );
}
