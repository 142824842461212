import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchActivity = createAsyncThunk(
    '/api/activity/activityId/',
    async (activityId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/activity/${activityId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchActivities = createAsyncThunk(
    '/api/activity/activities',
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/activity/activities/${departmentId}` : `api/activity/activities`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const activitySlice = createSlice({
    name: 'activity',
    initialState: {
        activity: null,
        activityStatus: null,
        activities: null,
        activitiesStatus: null,
        scrollPosition: 0,
    },
    reducers: {
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Activity
            .addCase(fetchActivity.pending, (state) => {
                state.activityStatus = 'loading'
            })
            .addCase(fetchActivity.fulfilled, (state, action) => {
                state.activityStatus = 'success';
                state.activity = action.payload;
            })
            .addCase(fetchActivity.rejected, (state) => {
                state.activityStatus = 'failed';
            });

        // Activities
        builder
            .addCase(fetchActivities.pending, (state) => {
                state.activitiesStatus = 'loading'
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {

                state.activitiesStatus = 'success';
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.activitiesStatus = 'failed';
                state.activities = action.payload;
            });
    },
});

export const { setScrollPosition } = activitySlice.actions;

export default activitySlice.reducer;