import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchContacts = createAsyncThunk(
    "/api/contact",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/contact`, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const getAllContacts = createAsyncThunk(
    "/api/contact/all",
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/contact/all`, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const contactSlice = createSlice({
    name: "contacts",
    initialState: {
        contacts: null,
        contactsStatus: null,
        allContacts: null,
        allContactsStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get contacts for student
        builder
            .addCase(fetchContacts.pending, (state) => {
                state.contactsStatus = "loading";
            })
            .addCase(fetchContacts.fulfilled, (state, action) => {
                state.contactsStatus = "success";
                state.contacts = action.payload;
            })
            .addCase(fetchContacts.rejected, (state) => {
                state.contactsStatus = "failed";
            });

        // Get contacts for employee
        builder
            .addCase(getAllContacts.pending, (state) => {
                state.allContactsStatus = "loading";
            })
            .addCase(getAllContacts.fulfilled, (state, action) => {
                state.allContactsStatus = "success";
                state.allContacts = action.payload;
            })
            .addCase(getAllContacts.rejected, (state) => {
                state.allContactsStatus = "failed";
            });
    },
});

export default contactSlice.reducer;
