import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchFavoriteSettings } from './favoritesSlice';

export default function FavoritesCheckMessage() {
    const dispatch = useDispatch();

    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { favoriteSettings, favoriteSettingsStatus } = useSelector((state) => state.favorites);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const [open, setOpen] = useState(false);
    const favoriteWord = (favoriteSettings?.favoriteMinimum - favoriteSettings?.userFavorites) > 1 ? `${resourceFinal[appLanguage].words.FAVORITES}` : `${resourceFinal[appLanguage].words.FAVORITE}`

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchFavoriteSettings(activeDepartment[0].id));
                };
            } else {
                dispatch(fetchFavoriteSettings(null));
            };
        };
    }, [dispatch, siomSettings, student, activeDepartment, departmentThemesStatus]);

    useEffect(() => {
        if (favoriteSettingsStatus === 'success') {
            setOpen(favoriteSettings.forceFavorite && favoriteSettings.userFavorites < favoriteSettings.favoriteMinimum);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoriteSettingsStatus]);

    return (
        <>
            {favoriteSettingsStatus === 'success' ?
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    sx={{ zIndex: 1, m: 1, mb: 8 }}
                    open={open}
                >
                    <Alert variant="filled" severity="warning">
                        {resourceFinal[appLanguage].messages.FAVORITES_WARNING_1} {favoriteSettings.favoriteMinimum - favoriteSettings.userFavorites} {favoriteWord?.toLowerCase()} {resourceFinal[appLanguage].messages.FAVORITES_WARNING_2}
                    </Alert>
                </Snackbar>
                : ""}
        </>
    )
}