import { Avatar, Box, IconButton, Modal } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import ListItemCard from '../../subcomponents/ListItemCard';
import PageLayout from '../../subcomponents/PageLayout';
import ClassEditCard from './ClassEditCard';
import ClassMemberDisplay from './ClassMemberDisplay';
import { getSpecificClass, resetClassStatus, setFailedClass } from './classManagementSlice';

import EditIcon from '@mui/icons-material/Edit';

export default function ClassPage() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedClass, selectedClassStatus } = useSelector((state) => state.management.class);

    const [open, setOpen] = useState(false);

    const callbackModal = () => {
        setOpen(false);
        dispatch(resetClassStatus());
        dispatch(setFailedClass(false));
    };

    useEffect(() => {
        dispatch(getSpecificClass(id));
    }, [dispatch, id]);

    return (
        <PageLayout title={`${selectedClass?.name ? selectedClass?.name : ""}`} content={
            <ContentLoader successCondition={selectedClassStatus === "success"} errorCondition={selectedClassStatus === "failed"} content={
                <>
                    <Grid container spacing={1} sx={{ mt: { xs: 0, md: 1 } }}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={selectedClass?.name}
                                secondaryText={resourceFinal[appLanguage].words.NAME}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={selectedClass?.isLicensed ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                                secondaryText={resourceFinal[appLanguage].words.LICENSED}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={selectedClass?.description ? selectedClass?.description : "-"}
                                secondaryText={resourceFinal[appLanguage].words.DESCRIPTION}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ClassMemberDisplay mode="STUDENT" members={selectedClass?.students} classId={id} />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ClassMemberDisplay mode="EMPLOYEE" members={selectedClass?.employees} classId={id} />
                        </Grid>
                    </Grid>
                    <Modal open={open} onClose={callbackModal}>
                        <>
                            <ClassEditCard handleClose={callbackModal} classId={id} />
                        </>
                    </Modal>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <IconButton sx={{ alignSelf: 'flex-end' }} onClick={() => setOpen(true)}>
                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                        <EditIcon sx={{ color: 'white.main', fontSize: 40 }} />
                    </Avatar>
                </IconButton>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}

