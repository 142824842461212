import { Avatar, Box, CardActionArea, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { ListItemBackground, MainBorderRadius } from '../../app/AppStyles';

export default function ListItemCard({ backgroundStyle, avatarIcon, avatarColor, action, actionIcon, altBgColor, primaryText, secondaryText, tertiaryText }) {
    return (
        <>
            {action ?
                <Box sx={{ ...ListItemBackground, ...backgroundStyle, ...altBgColor }}>
                    <CardActionArea sx={{ borderRadius: MainBorderRadius, ...backgroundStyle }} onClick={action}>
                        <ListItem secondaryAction={actionIcon}>
                            {avatarIcon &&
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: avatarColor }}>
                                        {avatarIcon}
                                    </Avatar>
                                </ListItemAvatar>}
                            <Box>
                                <ListItemText sx={{ p: 0, m: 0 }}
                                    primary={primaryText}
                                    secondary={secondaryText}
                                />
                                {tertiaryText && <ListItemText sx={{ p: 0, m: 0 }}
                                    secondary={tertiaryText}
                                />}
                            </Box>
                        </ListItem>
                    </CardActionArea>
                </Box>
                :
                <Box sx={{ ...ListItemBackground, ...backgroundStyle, ...altBgColor }}>
                    <ListItem >
                        {avatarIcon &&
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: avatarColor }}>
                                    {avatarIcon}
                                </Avatar>
                            </ListItemAvatar>}
                        <ListItemText sx={{ p: 0, m: 0 }}
                            primary={primaryText}
                            secondary={secondaryText}
                        />
                        {tertiaryText && <ListItemText sx={{ p: 0, m: 0 }}
                            secondary={tertiaryText}
                        />}
                    </ListItem>
                </Box>
            }
        </>
    );
}
