import { Card, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import PageLayout from '../subcomponents/PageLayout';
import TimetableDisplay from './TimetableDisplay';
import { fetchTimetable, fetchTimetableOverview } from './timetableSlice';

export default function TimetablePage() {
    const dispatch = useDispatch();
    const [onlyCurrent, setOnlyCurrent] = useState(true);
    const [activeTimetable, setActiveTimetable] = useState();

    const { timetable, timetableStatus, timetableOverview, timetableOverviewStatus } = useSelector((state) => state.timetable);
    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { activeDepartment, departmentThemes, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchTimetable(activeDepartment[0].id));

                    var departmentIds = departmentThemes.map(function (item) { return item.id })
                    dispatch(fetchTimetableOverview(departmentIds))

                };
            } else {
                dispatch(fetchTimetable(null));
            };
        };
    }, [dispatch, student, activeDepartment, departmentThemes, departmentThemesStatus, siomSettings]);

    useEffect(() => {
        if (siomSettings.useMultipleDepartments && timetableOverviewStatus === "success")
            setActiveTimetable(timetableOverview);
        else if (!siomSettings.useMultipleDepartments && timetableStatus === "success")
            setActiveTimetable(timetable);

    }, [timetableOverviewStatus, timetableStatus]);

    const handleChange = () => {
        setOnlyCurrent(!onlyCurrent);
        setActiveTimetable(onlyCurrent ? timetable : timetableOverview)
    };

    const FilterFragment = () => {
        return (
            <FormGroup sx={{ width: 1 }}>
                <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={onlyCurrent} onChange={handleChange} />} label={`${resourceFinal[appLanguage].messages.SHOW_ALL_TIMETABLE}`} />
            </FormGroup>
        );
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.TIMETABLE} content={
            <ContentLoader successCondition={timetableStatus === 'success'} errorCondition={timetableStatus === 'failed'} content={
                <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                    <TimetableDisplay timetable={activeTimetable} />
                </Card>
            } />
        } contentLeft={
            timetableOverviewStatus === "success" && siomSettings.useMultipleDepartments ? <FilterSidebar filters={<FilterFragment />} /> : ""
        } bottomBar contentBottom={
            timetableOverviewStatus === "success" && siomSettings.useMultipleDepartments ? <FilterButton filters={<FilterFragment />} /> : ""
        } />
    );
}
