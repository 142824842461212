import { Card, CardContent, Stack, Typography, useMediaQuery } from '@mui/material';
import DOMPurify from 'dompurify';
import { useSelector } from "react-redux";
import { CardHeader, CardLarge } from '../../app/AppStyles';

export default function InformationArticleCard() {
    const { informationArticle } = useSelector((state) => state.information);
    const dekstop = useMediaQuery('(min-width:900px)');
    var i = 0;

    return (
        <Card key={informationArticle.id} sx={{ ...CardLarge }}>
            <CardContent sx={{ flex: '1 1 auto' }}>
                <Typography variant="h6" sx={{ ...CardHeader }}>{informationArticle.title}</Typography>
                {informationArticle.text.includes("https://www.youtube.com/embed/") ?
                    <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        {informationArticle.text.split(" ").map((item) =>
                            <iframe key={i++} width={dekstop ? "560" : "100%"} height="315"
                                src={item}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="clipboard-write; encrypted-media; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        )}
                    </Stack>
                    :
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(informationArticle.text) }}></Typography>
                }
            </CardContent>
        </Card>
    );
}
