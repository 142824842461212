import { Box, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLastUsedDepartment } from '../settings/themeSettingsSlice';
import Flag from './Flag';

const flag = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
};

export default function FlagDisplay({ progressFlags, requiredPoints, xsIconSize, mdIconSize, compact, sizeOverride, departmentSettings, departmentId, departmentOverride }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeThemeSettings, activeDepartment } = useSelector((state) => state.settings.theme);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { lifestyleStatus, flagsGreen, flagsYellow, flagsOrange } = progressFlags;

    const desktop = useMediaQuery('(min-width:1536px)');
    const settings = departmentOverride ? departmentSettings : activeThemeSettings;

    const flagClick = () => {
        if (departmentSettings) {
            dispatch(updateLastUsedDepartment(departmentId));

            if (activeDepartment[0].id !== departmentId) {
                navigate('/Voortgang');
                navigate(0);
                return;
            };
        };

        navigate('/Voortgang');
    };

    const tjlClick = () => {
        window.open("https://www.testjeleefstijl.nl/", '_blank');
    };

    const GreenFlagFragment = ({ fragmentStyle }) => {
        return (
            <Flag
                count={flagsGreen}
                type='green'
                style={{ ...fragmentStyle }}
                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                icon={settings.flagStyle}
            />
        );
    }

    const YellowFlagFragment = ({ fragmentStyle }) => {
        return (
            <Flag
                count={flagsYellow}
                type='yellow'
                style={{ ...fragmentStyle }}
                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                icon={settings.flagStyle}
            />
        );
    }

    const OrangeFlagFragment = ({ fragmentStyle }) => {
        return (
            <Flag
                count={flagsOrange}
                type='red'
                style={{ ...fragmentStyle }}
                badgePosition={{ vertical: 'top', horizontal: 'right' }}
                icon={settings.flagStyle}
            />
        );
    }

    const TJLBadgeFragment = ({ fragmentStyle }) => {
        return (
            <Flag
                count={lifestyleStatus}
                style={{ ...fragmentStyle }}
                icon={settings.tjlStyle}
            />
        );
    }

    const RequiredProgramsBadgeFragment = ({ fragmentStyle }) => {
        return (
            <Flag
                type={requiredPoints?.requiredPointsStatus.toLowerCase()}
                style={{ ...fragmentStyle }} RequiredProgramIconStyle
                icon={settings.requiredProgramIconStyle}
            />
        );
    }

    if (compact) {
        return (
            <Grid container spacing={0.5} justifyContent="center" sx={{ display: 'flex', flex: '1 1 auto' }}>
                {/*{ GREEN FLAGS }*/}
                <Grid size={{ xs: sizeOverride ? 3 : 6, xl: 3 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={flagClick} sx={{ p: 0 }}>
                        <GreenFlagFragment fragmentStyle={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: green[500] }} />
                    </IconButton>
                </Grid>
                {/*{ YELLOW FLAGS }*/}
                <Grid size={{ xs: sizeOverride ? 3 : 6, xl: 3 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={flagClick} sx={{ p: 0 }}>
                        <YellowFlagFragment fragmentStyle={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: yellow[700] }} />
                    </IconButton>
                </Grid>
                {/*{ ORANGE FLAGS }*/}
                {settings.showOrangeFlags &&
                    <Grid size={{ xs: sizeOverride ? 3 : 6, xl: 3 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={flagClick} sx={{ p: 0 }}>
                            <OrangeFlagFragment fragmentStyle={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: red[500] }} />
                        </IconButton>
                    </Grid>}
                {/*{ TJL BADGE }*/}
                {settings.tjlEnabled &&
                    <Grid size={{ xs: sizeOverride ? 3 : 6, xl: 3 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={tjlClick} sx={{ p: 0 }}>
                            <TJLBadgeFragment fragmentStyle={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: lifestyleStatus === 2 ? green[500] : lifestyleStatus === 1 ? yellow[700] : red[500] }} />
                        </IconButton>
                    </Grid>}
                {/*{ REQUIRED PROGRAMS BADGE }*/}
                {(siomSettings?.enableRequiredPrograms && requiredPoints?.anyRequiredPrograms) &&
                    <Grid size={{ xs: sizeOverride ? 3 : 6, xl: 3 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={flagClick} sx={{ p: 0 }}>
                            <RequiredProgramsBadgeFragment fragmentStyle={{ fontSize: desktop ? mdIconSize : sizeOverride ? mdIconSize : xsIconSize, color: requiredPoints?.requiredPointsStatus.toLowerCase() === "green" ? green[500] : requiredPoints?.requiredPointsStatus.toLowerCase() === "yellow" ? yellow[700] : red[500] }} />
                        </IconButton>
                    </Grid>
                }
            </Grid>
        );
    }

    return (
        <Stack spacing={0.5}>
            {/*{ GREEN FLAGS }*/}
            <Stack direction="row" spacing={1}>
                <GreenFlagFragment fragmentStyle={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: green[500] }} />
                <Box sx={{ ...flag }}>
                    <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsGreen} {flagsGreen !== 1 ? resourceFinal[appLanguage].words.FLAGS_GREEN_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_GREEN.toLowerCase()}.</Typography>
                </Box>
            </Stack>
            {/*{ YELLOW FLAGS }*/}
            <Stack direction="row" spacing={1}>
                <YellowFlagFragment fragmentStyle={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: yellow[700] }} />
                <Box sx={{ ...flag }}>
                    <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsYellow} {flagsYellow !== 1 ? resourceFinal[appLanguage].words.FLAGS_YELLOW_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_YELLOW.toLowerCase()}.</Typography>
                </Box>
            </Stack>
            {/*{ ORANGE FLAGS }*/}
            {settings.showOrangeFlags &&
                <Stack direction="row" spacing={1}>
                    <OrangeFlagFragment fragmentStyle={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: red[500] }} />
                    <Box sx={{ ...flag }}>
                        <Typography variant="body1">{resourceFinal[appLanguage].messages.YOU_HAVE_FLAGS} {flagsOrange} {flagsOrange !== 1 ? resourceFinal[appLanguage].words.FLAGS_ORANGE_MULT.toLowerCase() : resourceFinal[appLanguage].words.FLAGS_ORANGE.toLowerCase()}.</Typography>
                    </Box>
                </Stack>}
            {/*{ TJL BADGE }*/}
            {settings.tjlEnabled &&
                <Stack direction="row" spacing={1}>
                    <IconButton onClick={tjlClick}>
                        <TJLBadgeFragment fragmentStyle={{ fontSize: { xs: xsIconSize, md: mdIconSize }, color: lifestyleStatus === 2 ? green[500] : lifestyleStatus === 1 ? yellow[700] : red[500] }} />
                    </IconButton>
                    <Box sx={{ ...flag }}>
                        <Typography variant="body1">
                            {lifestyleStatus === 2 ? resourceFinal[appLanguage].messages.TJL_COMPLETED :
                                lifestyleStatus === 1 ? resourceFinal[appLanguage].messages.TJL_STARTED :
                                    resourceFinal[appLanguage].messages.TJL_NOT_STARTED}
                        </Typography>
                    </Box>
                </Stack>}
            {/*{ REQUIRED PROGRAMS BADGE }*/}
            {(siomSettings?.enableRequiredPrograms && requiredPoints?.anyRequiredPrograms) &&
                <Stack direction="row" spacing={1}>
                    <RequiredProgramsBadgeFragment fragmentStyle={{ p: 1, fontSize: { xs: xsIconSize, md: mdIconSize }, color: requiredPoints?.requiredPointsStatus.toLowerCase() === "green" ? green[500] : requiredPoints?.requiredPointsStatus.toLowerCase() === "yellow" ? yellow[700] : red[500] }} />
                    <Box sx={{ ...flag }}>
                        <Typography variant="body1">
                            {requiredPoints?.requiredPointsStatus.toLowerCase() === "green" ? resourceFinal[appLanguage].messages.PROGRESS_REQUIRED_PROGRAM_GREEN : requiredPoints?.requiredPointsStatus.toLowerCase() === "yellow" ? resourceFinal[appLanguage].messages.PROGRESS_REQUIRED_PROGRAM_YELLOW : resourceFinal[appLanguage].messages.PROGRESS_REQUIRED_PROGRAM_RED}
                        </Typography>
                    </Box>
                </Stack>}
        </Stack>
    );
}
