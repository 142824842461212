import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { PageHeader } from '../../../../../app/AppStyles';
import RegisterPerformancesCard from '../../../../register/RegisterPerformancesCard';
import ContentLoader from '../../../../subcomponents/ContentLoader';

export default function SearchStudentPerformanceTab() {
    const { periods, performances, performancesStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    var customFilterFunction = (item) => {
        return periods?.filter(e1 => e1.periodId === item.periode.periodId).some((e) => e.checked);
    };

    return (
        <ContentLoader successCondition={performancesStatus === "success"} errorCondition={performancesStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={1} justifyContent="center" alignItems="stretch">
                    <Grid size={{ xs: 12 }}><Typography sx={{ ...PageHeader, typography: { xs: 'h5', md: 'h3' } }}>{performances[0].activity.name}</Typography></Grid>
                    {performances?.filter(customFilterFunction).map((item) =>
                        <Grid size={{ xs: 12, md: 6 }} key={item.performanceId}>
                            <RegisterPerformancesCard performance={item} employeeMode />
                        </Grid>
                    )}
                    {performances?.filter(customFilterFunction).length === 0 &&
                        <Grid size={{ xs: "grow" }}>
                            <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</Typography>
                        </Grid>}
                </Grid>
            </Box>
        } />
    );
}
