import { useEffect, useState } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';

const AuthorizeRoute = (props) => {
    const [ready, setReady] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const subscription = authService.subscribe(() => authenticationChanged());
        populateAuthenticationState();

        return () => {
            authService.unsubscribe(subscription);
        };
    }, []);

    const populateAuthenticationState = async () => {
        const isAuthenticated = await authService.isAuthenticated();
        setReady(true);
        setAuthenticated(isAuthenticated);
    };

    const authenticationChanged = async () => {
        setReady(false);
        setAuthenticated(false);
        await populateAuthenticationState();
    };

    return !ready ? <div></div> : authenticated ? <Outlet /> : <Navigate to={ApplicationPaths.Login} />;
};

export default AuthorizeRoute;

