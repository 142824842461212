import Grid from '@mui/material/Grid2';
import NewsTile from "../news/NewsTile";
import InfoTile from "./InfoTile";
import EmployeeSchoolclassesTile from '../employee/EmployeeSchoolclassesTile';
import EmployeeAttendancesTile from '../employee/EmployeeAttendancesTile';

export default function EmployeeHome() {

    return (
        <Grid container direction="column" spacing={{ xs: 0.5, md: 1 }} sx={{ flex: '1 1 auto' }}>
            <Grid size={{ xs: 12 }}><InfoTile /></Grid>
            <Grid container size={{ xs: "grow" }}>
                <Grid size={{ xs: 12, md: 2 }}><EmployeeSchoolclassesTile /></Grid>
                <Grid size={{ xs: 12, md: 10 }}><EmployeeAttendancesTile /></Grid>
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ display: 'flex', flex: '1 1 auto' }}><NewsTile /></Grid>
        </Grid>
    );
}