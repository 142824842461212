import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import InformationArticleCard from './InformationArticleCard';
import { fetchInformationArticle } from './informationSlice';

export default function InformationArticlePage() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { informationArticleStatus } = useSelector((state) => state.information);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        dispatch(fetchInformationArticle(id));
    }, [dispatch, id]);

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.INFORMATION}`} content={
            <ContentLoader successCondition={informationArticleStatus === "success"} errorCondition={informationArticleStatus === "failed"} content={
                <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
                    <InformationArticleCard />
                </Box>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}
