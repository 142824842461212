import { useSelector } from "react-redux";
import ContentLoader from "../subcomponents/ContentLoader";
import PageLayout from '../subcomponents/PageLayout';
import EmployeeHome from "./EmployeeHome";
import StudentHome from "./StudentHome";

export default function Home() {
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { userStatus } = useSelector((state) => state.user);

    return (
        <PageLayout style={{ bgcolor: '', p: 0 }} content={
            <ContentLoader successCondition={userStatus === "success"} errorCondition={userStatus === "failed"} content={
                aspNetUser?.role?.includes("Student") ? <StudentHome /> :
                    aspNetUser?.role?.includes("Employee") ? <EmployeeHome /> : ""
            } customError={<></>} />
        } />
    );
}
