import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchStudentSettings = createAsyncThunk(
    '/api/settings/studentSettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + 'api/settings/studentSettings', {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const studentSettingsSlice = createSlice({
    name: 'studentSettings',
    initialState: {
        studentSettings: null,
        studentSettingsStatus: null,
    },
    reducers: { },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStudentSettings.pending, (state) => {
                state.studentSettingsStatus = 'loading'
            })
            .addCase(fetchStudentSettings.fulfilled, (state, action) => {
                state.studentSettingsStatus = 'success';
                state.studentSettings = action.payload;
            })
            .addCase(fetchStudentSettings.rejected, (state) => {
                state.studentSettingsStatus = 'failed';
            })
    },
});

//export const { } = studentSettingsSlice.actions;

export default studentSettingsSlice.reducer;