import { useSelector } from "react-redux";
import PageLayout from '../subcomponents/PageLayout';
import ScheduleDisplayAlt from './ScheduleDisplayAlt';

export default function SchedulePage() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.SCHEDULE} content={
            <ScheduleDisplayAlt pagination />
        } bottomBar />
    );
}
