import { alpha, Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSiomSettings } from '../settings/siomSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { putSetting, resetUpdateStatus } from './adminSlice';
import Grid from '@mui/material/Grid2';

export default function SiomSettingsTab() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings, siomSettingsStatus } = useSelector((state) => state.settings.siom);
    const { updateSettingStatus } = useSelector((state) => state.admin);

    // Put Callback
    useEffect(() => {
        if (updateSettingStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchSiomSettings());
        } else if (updateSettingStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateSettingStatus]);

    // Absence message send setting
    const [newAbsenceMessage, setNewAbsenceMessage] = useState(null);

    useEffect(() => {
        if (newAbsenceMessage !== null) {
            dispatch(putSetting({
                key: "Email_EmailBijAfwezigheidVersturen",
                value: newAbsenceMessage ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newAbsenceMessage]);

    // Absence message send mail setting
    const [newAbsenceSendMail, setNewAbsenceSendMail] = useState(null);

    useEffect(() => {
        if (newAbsenceSendMail !== null) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_SendEmail",
                value: newAbsenceSendMail ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newAbsenceSendMail]);

    // Absence message send app message setting
    const [newAbsenceSendApp, setNewAbsenceSendApp] = useState(null);

    useEffect(() => {
        if (newAbsenceSendApp !== null) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_SendAppMessage",
                value: newAbsenceSendApp ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newAbsenceSendApp]);

    // Absence message text setting
    const [newAbsenceSubject, setNewAbsenceSubject] = useSearchDebounce();

    useEffect(() => {
        if (newAbsenceSubject) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_Onderwerp",
                value: newAbsenceSubject,
                modifiedBy: `AP/${user.number}`
            }));
        };
    }, [dispatch, user, newAbsenceSubject]);

    // Absence message text setting
    const [newAbsenceText, setNewAbsenceText] = useSearchDebounce();

    useEffect(() => {
        if (newAbsenceText) {
            dispatch(putSetting({
                key: "Siom_Absentiebericht_Tekst",
                value: newAbsenceText,
                modifiedBy: `AP/${user.number}`
            }));
        };
    }, [dispatch, user, newAbsenceText]);

    // Default moment duration setting
    const [newMomentDefaultDuration, setNewMomentDefaultDuration] = useSearchDebounce();

    useEffect(() => {
        if (newMomentDefaultDuration) {
            dispatch(putSetting({
                key: "Siom_MomentDefaultDuration",
                value: newMomentDefaultDuration,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMomentDefaultDuration]);

    // Departments setting
    const [newDepartments, setNewDepartments] = useState(null);

    useEffect(() => {
        if (newDepartments !== null) {
            dispatch(putSetting({
                key: "Siom_UseMultipleDepartments",
                value: newDepartments ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newDepartments]);

    // Default moment duration setting
    const [newEmployeeShowAllDepartments, setNewEmployeeShowAllDepartments] = useState(null);

    useEffect(() => {
        if (newEmployeeShowAllDepartments !== null) {
            dispatch(putSetting({
                key: "Siom_EmployeeShowAllDepartments",
                value: newEmployeeShowAllDepartments ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEmployeeShowAllDepartments]);

    // Flag progress setting
    const [newFlagProgress, setNewFlagProgress] = useState(null);

    useEffect(() => {
        if (newFlagProgress !== null) {
            dispatch(putSetting({
                key: "Siom_GroeneVlaggenVoortgang",
                value: newFlagProgress ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newFlagProgress]);

    // Gradual points setting
    const [newGradualPoints, setNewGradualPoints] = useState(null);

    useEffect(() => {
        if (newGradualPoints !== null) {
            dispatch(putSetting({
                key: "Siom_Gradual_Points",
                value: newGradualPoints ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newGradualPoints]);

    // Mentor contact setting
    const [newMentorContact, setNewMentorContact] = useState(null);

    useEffect(() => {
        if (newMentorContact !== null) {
            dispatch(putSetting({
                key: "Siom_Mentor_As_Contact",
                value: newMentorContact ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMentorContact]);

    // Messages setting
    const [newMessages, setNewMessages] = useState(null);

    useEffect(() => {
        if (newMessages !== null) {
            dispatch(putSetting({
                key: "Siom_Messages_Enabled",
                value: newMessages ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newMessages]);

    // Reservation setting
    const [newReservation, setNewReservation] = useState(null);

    useEffect(() => {
        if (newReservation !== null) {
            dispatch(putSetting({
                key: "Siom_IsReservation",
                value: newReservation ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newReservation]);

    // Show Search Register Tab setting
    const [newShowSearchRegisterTab, setNewShowSearchRegisterTab] = useState(null);

    useEffect(() => {
        if (newShowSearchRegisterTab !== null) {
            dispatch(putSetting({
                key: "Siom_ShowSearchRegisterTab",
                value: newShowSearchRegisterTab ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newShowSearchRegisterTab]);

    // Travel costs hours setting
    const [newTravelCosts, setNewTravelCosts] = useSearchDebounce();

    useEffect(() => {
        if (newTravelCosts) {
            dispatch(putSetting({
                key: "Siom_TravelCost_Hours_Limit",
                value: newTravelCosts,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newTravelCosts]);

    // Uitschrijfperiode setting
    const [newUnsubscribePeriod, setNewUnsubscribePeriod] = useSearchDebounce();

    useEffect(() => {
        if (newUnsubscribePeriod) {
            dispatch(putSetting({
                key: "Siom_Uitschrijfperiode",
                value: newUnsubscribePeriod,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newUnsubscribePeriod]);

    // Remove subscribed points if absent setting
    const [newRemoveAbsentSubscribedPoints, setNewRemoveAbsentSubscribedPoints] = useState(null);

    useEffect(() => {
        if (newRemoveAbsentSubscribedPoints !== null) {
            dispatch(putSetting({
                key: "Siom_RemoveAbsentSubscribedPoints",
                value: newRemoveAbsentSubscribedPoints ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newRemoveAbsentSubscribedPoints]);

    // Enable subscriptions outside program setting
    const [newEnableSubscriptionOutsideProgram, setNewEnableSubscriptionOutsideProgram] = useState(null);

    useEffect(() => {
        if (newEnableSubscriptionOutsideProgram !== null) {
            dispatch(putSetting({
                key: "Siom_EnableSubscriptionOutsideProgramma",
                value: newEnableSubscriptionOutsideProgram ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEnableSubscriptionOutsideProgram]);

    // Enable subscriptions outside schedule setting
    const [newEnableSubscriptionOutsideSchedule, setNewEnableSubscriptionOutsideSchedule] = useState(null);

    useEffect(() => {
        if (newEnableSubscriptionOutsideSchedule !== null) {
            dispatch(putSetting({
                key: "Siom_EnableSubscriptionOutsideSchedule",
                value: newEnableSubscriptionOutsideSchedule ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newEnableSubscriptionOutsideSchedule]);

    // Enable required programs setting
    const [newRequiredPrograms, setNewRequiredPrograms] = useState(null);

    useEffect(() => {
        if (newRequiredPrograms !== null) {
            dispatch(putSetting({
                key: "Siom_EnableRequiredPrograms",
                value: newRequiredPrograms ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newRequiredPrograms]);

    // Enable performance names setting
    const [newPerformanceName, setNewPerformanceName] = useState(null);

    useEffect(() => {
        if (newPerformanceName !== null) {
            dispatch(putSetting({
                key: "Siom_EnablePerformanceName",
                value: newPerformanceName ? "True" : "False",
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [dispatch, user, newPerformanceName]);

    return (
        <ContentLoader successCondition={siomSettingsStatus === "success"} errorCondition={siomSettingsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <Grid container spacing={1}>
                    <Grid container spacing={1} item size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.primary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* ABSENTIE */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.SIOM_TITLE_ABSENCE}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.absenceSendMessage} onChange={(e) => setNewAbsenceMessage(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ABSENCE_SEND_EMAIL}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.absenceSendEmail} onChange={(e) => setNewAbsenceSendMail(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ABSENCE_SEND_EMAIL_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ flex: '1 1 auto', border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG_SUBJECT}</FormLabel>
                                <TextField
                                    fullWidth
                                    defaultValue={siomSettings?.absenceSubject}
                                    onChange={(e) => setNewAbsenceSubject(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG_SUBJECT_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ABSENCE_SEND_APP}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.absenceSendApp} onChange={(e) => setNewAbsenceSendApp(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ABSENCE_SEND_APP_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG_TEXT}</FormLabel>
                                <TextField
                                    fullWidth multiline
                                    defaultValue={siomSettings?.absenceText}
                                    onChange={(e) => setNewAbsenceText(e.target.value)}
                                    variant="outlined"
                                    helperText={resourceFinal[appLanguage].admin.SIOM_ABSENCE_MSG_TEXT_HTEXT}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.secondary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* VOORTGANG */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.SIOM_TITLE_PROGRESS}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_FLAG_PROGRESS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.flagProgress} onChange={(e) => setNewFlagProgress(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_FLAG_PROGRESS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_GRAD_POINTS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.gradualPoints} onChange={(e) => setNewGradualPoints(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_GRAD_POINTS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_REMOVE_ABSENT_SUBSCRIBED_POINTS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.removeAbsentSubscribedPoints} onChange={(e) => setNewRemoveAbsentSubscribedPoints(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_REMOVE_ABSENT_SUBSCRIBED_POINTS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ENABLE_REQUIRED_PROGRAMS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.enableRequiredPrograms} onChange={(e) => setNewRequiredPrograms(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ENABLE_REQUIRED_PROGRAMS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.primary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* INSCHRIJVEN */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.SIOM_TITLE_REGISTER}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, flex: '1 1 auto', bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_MOMENT_DEFAULT_DURATION}</FormLabel>
                                <TextField
                                    fullWidth type="number"
                                    defaultValue={siomSettings?.momentDefaultDuration}
                                    inputProps={{ min: 1 }}
                                    onChange={(e) => setNewMomentDefaultDuration(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.SIOM_MOMENT_DEFAULT_DURATION_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_RESERVATION}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.isReservation} onChange={(e) => setNewReservation(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_RESERVATION_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_SHOW_REGISTER_TAB}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.showSearchRegisterTab} onChange={(e) => setNewShowSearchRegisterTab(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_SHOW_REGISTER_TAB_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }} sx={{ display: 'flex' }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, flex: '1 1 auto', bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_UNSUBSCRIBE_PERIOD}</FormLabel>
                                <TextField
                                    fullWidth type="number"
                                    defaultValue={siomSettings?.unsubscribePeriod}
                                    inputProps={{ min: 0 }}
                                    onChange={(e) => setNewUnsubscribePeriod(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.SIOM_UNSUBSCRIBE_PERIOD_HTEXT}
                                />
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ENABLE_SUBSCRIPTION_OUTSIDE_PROGRAM}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.enableSubscriptionOutsideProgram} onChange={(e) => setNewEnableSubscriptionOutsideProgram(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ENABLE_SUBSCRIPTION_OUTSIDE_PROGRAM_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ENABLE_SUBSCRIPTION_OUTSIDE_SCHEDULE}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.enableSubscriptionOutsideSchedule} onChange={(e) => setNewEnableSubscriptionOutsideSchedule(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ENABLE_SUBSCRIPTION_OUTSIDE_SCHEDULE_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_ENABLE_PERFORMANCE_NAME}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.enablePerformanceName} onChange={(e) => setNewPerformanceName(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_ENABLE_PERFORMANCE_NAME_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.secondary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* DEPARTMENT */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.SIOM_TITLE_DEPARTMENT}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_DEPARTMENTS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.useMultipleDepartments} onChange={(e) => setNewDepartments(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_DEPARTMENTS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_SHOW_ALL_DEPARTMENTS}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.employeeShowAllDepartments} onChange={(e) => setNewEmployeeShowAllDepartments(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_SHOW_ALL_DEPARTMENTS_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item size={{ xs: 12 }} sx={{ bgcolor: theme => `${alpha(theme.palette.primary.light, 0.3)}`, border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1 }}>
                        {/* OTHER */}
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="button">{resourceFinal[appLanguage].admin.SIOM_TITLE_OTHER}</Typography>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_MENTOR_CONTACT}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.mentorAsContact} onChange={(e) => setNewMentorContact(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_MENTOR_CONTACT_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 6 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_MESSAGES}</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox defaultChecked={siomSettings?.messagesEnabled} onChange={(e) => setNewMessages(e.target.checked)} />
                                } label={resourceFinal[appLanguage].admin.ADMIN_GENERAL_ON} />
                                <FormHelperText>{resourceFinal[appLanguage].admin.SIOM_MESSAGES_HTEXT}</FormHelperText>
                            </Box>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Box sx={{ border: 1, borderColor: 'white.dark', borderRadius: 1, p: 1, bgcolor: 'white.light' }}>
                                <FormLabel component="legend">{resourceFinal[appLanguage].admin.SIOM_TRAVEL_COST}</FormLabel>
                                <TextField
                                    fullWidth type="number"
                                    defaultValue={siomSettings?.travelCostHoursLimit}
                                    inputProps={{ min: 1 }}
                                    onChange={(e) => setNewTravelCosts(e.target.value)}
                                    variant="standard"
                                    helperText={resourceFinal[appLanguage].admin.SIOM_TRAVEL_COST_HTEXT}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        } />
    );
}