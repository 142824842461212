import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestOptions } from "../../app/AppUtils";

export const fetchLeaderboard = createAsyncThunk(
    '/api/leaderboard',
    async (args, { rejectWithValue }) => {
        const response = await fetch(window.Config.apiUrl + 'api/leaderboard', requestOptions)

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: {
        leaderboard: null,
        status: null
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Leaderboard
        builder
            .addCase(fetchLeaderboard.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLeaderboard.fulfilled, (state, action) => {
                state.status = 'success';
                state.leaderboard = action.payload;
            })
            .addCase(fetchLeaderboard.rejected, (state) => {
                state.status = 'failed';
            });
    }
});

//export const { } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;