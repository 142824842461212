import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { GetDate, GetTime } from '../../../app/AppUtils';
import { useEffect, useState } from 'react';

export default function ExportExcelWintersport({ disabled, jsonData }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [studentList, setStudentList] = useState([]);
    const [fileName, setFileName] = useState([]);

    useEffect(() => {
        if (jsonData) {
            const updatedStudents = jsonData.students.map(({ studentId, ...rest }) => rest);

            setStudentList(updatedStudents);
            setFileName(`${jsonData.schoolClassName}-${jsonData.activityName}-${jsonData.performanceName}-${GetDate(jsonData.startTime)}`);
        }
    }, [jsonData]);

    const handleExportToExcel = () => {
        let sheetData = [];

        sheetData.push([`${resourceFinal[appLanguage].words.CLASS}: ${jsonData.schoolClassName}`]);
        sheetData.push([`${resourceFinal[appLanguage].words.ACTIVITY}: ${jsonData.activityName}`]);
        sheetData.push([`${resourceFinal[appLanguage].words.PERFORMANCE}: ${jsonData.performanceName}`]);
        sheetData.push([`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}: ${GetDate(jsonData.startTime)} ${GetTime(jsonData.startTime)}`]);
        sheetData.push([]);

        const headerRow = [
            resourceFinal[appLanguage].words.NAME_FIRST,
            resourceFinal[appLanguage].words.NAME_INSERTION,
            resourceFinal[appLanguage].words.NAME_LAST,
            resourceFinal[appLanguage].words.USER_DATA_BIRTHDATE,
            resourceFinal[appLanguage].words.USER_DATA_SPORT,
            resourceFinal[appLanguage].words.USER_DATA_HEIGHT,
            resourceFinal[appLanguage].words.USER_DATA_WEIGHT,
            resourceFinal[appLanguage].words.USER_DATA_SHOE_SIZE,
            resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE,
            resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL,
            resourceFinal[appLanguage].words.USER_DATA_GENDER,
            resourceFinal[appLanguage].words.USER_DATA_MEDICATION,
            resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE,
            resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER,
            resourceFinal[appLanguage].words.USER_DATA_ALLERGY,
            resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NAME,
            resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NUMBER,
        ];

        sheetData.push(headerRow);

        studentList.forEach(item => {
            sheetData.push(Object.values(item));
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => handleExportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
