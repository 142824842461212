import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useSelector } from "react-redux";
import { CardHeader, CardLarge } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';

export default function NewsArticleCard() {
    const { article } = useSelector((state) => state.news);

    return (
        <Card key={article.id} sx={{ ...CardLarge, height: '100%' }}>
            <CardMedia component="img" sx={{ display: article.image ? 'inline-block' : 'none', height: { xs: 180, md: 320 } }} image={article.image} alt="article image" />
            <CardContent sx={{ flex: '1 1 auto' }}>
                <Typography variant="h6" sx={{ ...CardHeader }}>{article.title}</Typography>
                <Typography variant="subtitle2" gutterBottom>{GetDate(article.date)} ({GetTime(article.date)}) | door: {article.author}</Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.text) }}></Typography>
            </CardContent>
        </Card>
    );
}
