import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FilterLoop, requestOptions } from "../../app/AppUtils";
import authService from "../api-authorization/AuthorizeService";

export const fetchDefaultThemeSettings = createAsyncThunk(
    '/api/settings/defaultThemeSettings',
    async (args, { rejectWithValue }) => {
        const response = await fetch(window.Config.apiUrl + `api/settings/defaultThemeSettings`, requestOptions)

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDepartmentThemeSettings = createAsyncThunk(
    '/api/settings/departmentThemeSettings',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/settings/departmentThemeSettings`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const updateLastUsedDepartment = createAsyncThunk(
    '/api/department/departmentId',
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/department/${departmentId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const themeSettingsSlice = createSlice({
    name: 'themeSettings',
    initialState: {
        defaultTheme: null,
        defaultThemeStatus: null,
        departmentThemes: null,
        departmentThemesStatus: null,
        activeDepartment: null,
        activeThemeSettings: null
    },
    reducers: {
        setActiveDepartment: (state, action) => {
            state.activeDepartment = action.payload;
        }
    },
    extraReducers: (builder) => {
        // Default Theme
        builder
            .addCase(fetchDefaultThemeSettings.pending, (state) => {
                state.defaultThemeStatus = 'loading'
            })
            .addCase(fetchDefaultThemeSettings.fulfilled, (state, action) => {
                state.defaultThemeStatus = 'success';
                state.defaultTheme = action.payload;
                state.activeThemeSettings = action.payload;
            })
            .addCase(fetchDefaultThemeSettings.rejected, (state) => {
                state.defaultThemeStatus = 'failed';
            });

        // Department Themes
        builder
            .addCase(fetchDepartmentThemeSettings.pending, (state) => {
                state.departmentThemesStatus = 'loading'
            })
            .addCase(fetchDepartmentThemeSettings.fulfilled, (state, action) => {
                state.departmentThemesStatus = 'success';
                state.departmentThemes = action.payload;

                var lastUsedDepartment = FilterLoop((item) => item.lastUsedDepartment, action.payload);

                if (lastUsedDepartment.length > 0) {
                    state.activeDepartment = lastUsedDepartment;
                    state.activeThemeSettings = lastUsedDepartment[0].themeSettings;
                };
            })
            .addCase(fetchDepartmentThemeSettings.rejected, (state) => {
                state.departmentThemesStatus = 'failed';
            });

        // Update Last Used
        builder
            .addCase(updateLastUsedDepartment.pending, (state) => {
                state.defaultThemeStatus = 'loading'
            })
            .addCase(updateLastUsedDepartment.fulfilled, (state) => {
                state.defaultThemeStatus = 'success';
            })
            .addCase(updateLastUsedDepartment.rejected, (state) => {
                state.defaultThemeStatus = 'failed';
            });
    },
});

export const {
    setActiveDepartment,
    setActiveTheme
} = themeSettingsSlice.actions;

export default themeSettingsSlice.reducer;