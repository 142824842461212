import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestOptions } from "../../app/AppUtils";

export const fetchSiomSettings = createAsyncThunk(
    '/api/settings/siomsettings',
    async (args, { rejectWithValue }) => {
        const response = await fetch(window.Config.apiUrl + 'api/settings/siomsettings', requestOptions)

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const siomSettingsSlice = createSlice({
    name: 'siomSettings',
    initialState: {
        siomSettings: null,
        siomSettingsStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Siom Settings
        builder
            .addCase(fetchSiomSettings.pending, (state) => {
                state.siomSettingsStatus = 'loading';
            })
            .addCase(fetchSiomSettings.fulfilled, (state, action) => {
                state.siomSettingsStatus = 'success';
                state.siomSettings = action.payload;
            })
            .addCase(fetchSiomSettings.rejected, (state) => {
                state.siomSettingsStatus = 'failed';
            });
    }
});

//export const { } = siomSettingsSlice.actions;

export default siomSettingsSlice.reducer;