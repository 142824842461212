import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestOptions } from "../../app/AppUtils";

export const fetchInformation = createAsyncThunk(
    "/api/information",
    async (_, { rejectWithValue }) => {
        const response = await fetch(
            window.Config.apiUrl + "api/information",
            requestOptions
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchInformationArticle = createAsyncThunk(
    "/api/information/id",
    async (id, { rejectWithValue }) => {
        const response = await fetch(
            window.Config.apiUrl + `api/information/${id}`,
            requestOptions
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const informationSlice = createSlice({
    name: "information",
    initialState: {
        information: null,
        informationStatus: null,
        informationArticle: null,
        informationArticleStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Information
        builder
            .addCase(fetchInformation.pending, (state) => {
                state.informationStatus = "loading";
            })
            .addCase(fetchInformation.fulfilled, (state, action) => {
                state.information = action.payload;
                state.informationStatus = "success";
            })
            .addCase(fetchInformation.rejected, (state) => {
                state.informationStatus = "failed";
            });

        // Fetch Information Article
        builder
            .addCase(fetchInformationArticle.pending, (state) => {
                state.informationArticleStatus = "loading";
            })
            .addCase(fetchInformationArticle.fulfilled, (state, action) => {
                state.informationArticle = action.payload;
                state.informationArticleStatus = "success";
            })
            .addCase(fetchInformationArticle.rejected, (state) => {
                state.informationArticleStatus = "failed";
            });
    },
});

export default informationSlice.reducer;
