import { Box, Button, ButtonGroup, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { DisplayName, HandleImageUpload } from '../../../app/AppUtils';
import LocationEditCard from '../location/LocationEditCard';
import ActivityTypeEditCard from '../types/ActivityTypeEditCard';
import HeadTypeEditCard from '../types/HeadTypeEditCard';
import { getActivities, postActivity, putActivity, resetActivityStatus, setFailedActivity } from './activityManagementSlice';

import AddIcon from '@mui/icons-material/Add';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ActivityEditCard({ handleClose, addNew }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activityTypes, headTypes } = useSelector((state) => state.management.type);
    const { locations } = useSelector((state) => state.management.location);
    const { allContacts } = useSelector((state) => state.contacts);
    const { showFailed, selectedActivity, putActivityStatus, postActivityStatus } = useSelector((state) => state.management.activity);

    const [changed, setChanged] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openType, setOpenType] = useState(false);
    const [modalType, setModalType] = useState("Head");

    const callbackModalLocation = () => setOpenLocation(false);
    const callbackModalType = () => setOpenType(false);

    // Put status callback
    useEffect(() => {
        if (putActivityStatus === "success") {
            dispatch(setFailedActivity(false));
            dispatch(resetActivityStatus());
            dispatch(getActivities());
            handleClose();
        } else if (putActivityStatus === "failed") {
            dispatch(resetActivityStatus());
            dispatch(setFailedActivity(true));
        };
    }, [dispatch, handleClose, putActivityStatus]);

    // Post status callback
    useEffect(() => {
        if (postActivityStatus === "success") {
            setFailedActivity(false);
            dispatch(resetActivityStatus());
            dispatch(getActivities());
            handleClose();
        } else if (postActivityStatus === "failed") {
            setFailedActivity(true);
        };
    }, [dispatch, handleClose, postActivityStatus]);

    // Character limits
    const nameLength = 256;

    // Property states
    const [newName, setNewName] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [newDescription, setNewDescription] = useState(null);
    const [newHeadType, setNewHeadType] = useState(null);
    const [prevHeadType, setPrevHeadType] = useState(null);
    const [errorHeadType, setErrorHeadType] = useState(null);
    const [newActivityTypes, setNewActivityTypes] = useState(null);
    const [errorActivityTypes, setErrorActivityTypes] = useState(null);
    const [newPoints, setNewPoints] = useState(null);
    const [newCost, setNewCost] = useState(null);
    const [newCapacity, setNewCapacity] = useState(null);
    const [newLocation, setNewLocation] = useState(null);
    const [newOrganiser, setNewOrganiser] = useState(null);
    const [newContact, setNewContact] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [errorImage, setErrorImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [newIconName, setNewIconName] = useState(null);
    const [newEmbeddedVideo, setNewEmbeddedVideo] = useState(null);
    const [newLinkName, setNewLinkName] = useState(null);
    const [newLinkUrl, setNewLinkUrl] = useState(null);
    const [newNonSubscribable, setNewNonSubscribable] = useState(null);
    const [newArchive, setNewArchive] = useState(null);

    // Property change functions
    const handleNameChange = (event) => setNewName(event.target.value);
    const handleDescriptionChange = (event) => setNewDescription(event.target.value);
    const handleHeadTypeChange = (event) => {
        setPrevHeadType(newHeadType);
        setNewHeadType(event.target.value);
    };
    const handleActivityTypeChange = (event) => {
        const { target: { value }, } = event;
        setNewActivityTypes(typeof value === 'string' ? value.split(',') : value,);
    };
    const handlePointsChange = (event) => setNewPoints(event.target.value);
    const handleCostChange = (event) => setNewCost(event.target.value);
    const handleCapacityChange = (event) => setNewCapacity(event.target.value);
    const handleLocationChange = (event) => setNewLocation(event.target.value);
    const handleOrganiserChange = (event) => setNewOrganiser(event.target.value);
    const handleContactChange = (event) => setNewContact(event.target.value);

    function handleImageChange(event) {
        setErrorImage(null);
        var imagePromise = HandleImageUpload(event);

        imagePromise.then(
            (value) => {
                setImageName(value?.name);
                setNewImage(value?.image);
            },
            (error) => {
                (error.message === "SIZE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_TOO_LARGE));
                (error.message === "TYPE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_NOT_IMAGE));
            });
    };

    const handleIconNameChange = (event) => setNewIconName(event.target.value);
    const handleEmbeddedVideoChange = (event) => setNewEmbeddedVideo(event.target.value);
    const handleLinkNameChange = (event) => setNewLinkName(event.target.value);
    const handleLinkUrlChange = (event) => setNewLinkUrl(event.target.value);
    const handleNonSubscribableChange = () => setNewNonSubscribable(!newNonSubscribable);
    const handleArchiveChange = () => setNewArchive(!newArchive);

    // Selected location state update 
    useEffect(() => {
        if (!addNew && selectedActivity) {
            setNewName(selectedActivity?.name);
            setNewDescription(selectedActivity?.description);
            setNewHeadType(selectedActivity?.headType?.id);
            setNewActivityTypes(selectedActivity?.activityTypeIds);
            setNewPoints(selectedActivity?.points);
            setNewCost(selectedActivity?.cost);
            setNewCapacity(selectedActivity?.capacity);
            setNewLocation(selectedActivity?.location?.id);
            setNewOrganiser(selectedActivity?.organiser);
            setNewContact(selectedActivity?.contact?.id);
            setNewImage(selectedActivity?.imageUrl);
            setNewIconName(selectedActivity?.iconName);
            setNewEmbeddedVideo(selectedActivity?.embeddedVideo);
            setNewLinkName(selectedActivity?.linkName);
            setNewLinkUrl(selectedActivity?.linkUrl);
            setNewNonSubscribable(selectedActivity?.nonSubscribable);
            setNewArchive(selectedActivity?.archive);
        };
    }, [selectedActivity]);

    // Keep track of changed state 
    useEffect(() => {
        if (
            newName !== selectedActivity?.name ||
            newDescription !== selectedActivity?.description ||
            newHeadType !== selectedActivity?.headType?.id ||
            newActivityTypes !== selectedActivity?.activityTypeIds ||
            newPoints !== selectedActivity?.points ||
            newCost !== selectedActivity?.cost ||
            newCapacity !== selectedActivity?.capacity ||
            newLocation !== selectedActivity?.location?.id ||
            newOrganiser !== selectedActivity?.organiser ||
            newContact !== selectedActivity?.contact?.id ||
            newImage !== selectedActivity?.imageUrl ||
            newIconName !== selectedActivity?.iconName ||
            newEmbeddedVideo !== selectedActivity?.embeddedVideo ||
            newLinkName !== selectedActivity?.linkName ||
            newLinkUrl !== selectedActivity?.linkUrl ||
            newNonSubscribable !== selectedActivity?.nonSubscribable ||
            newArchive !== selectedActivity?.archive
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [
        selectedActivity, newName, newDescription, newHeadType, newActivityTypes,
        newPoints, newCost, newCapacity, newLocation, newOrganiser, newContact,
        newImage, newIconName, newEmbeddedVideo, newLinkName, newLinkUrl, newNonSubscribable, newArchive
    ]);

    // Reset selected activity types if head type has changed
    useEffect(() => {
        if (newHeadType && prevHeadType && newHeadType !== prevHeadType) {
            setNewActivityTypes([]);
        };
    }, [newHeadType, prevHeadType])

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (!newHeadType) return setErrorHeadType(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorHeadType(null);

        if (newHeadType && (!newActivityTypes || newActivityTypes.length <= 0)) return setErrorActivityTypes(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorActivityTypes(null);

        if (!newImage) return setErrorImage(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorImage(null);

        dispatch(putActivity({
            id: selectedActivity.id,
            name: newName,
            description: newDescription,
            activityTypeIds: newActivityTypes,
            points: siomSettings?.flagProgress ? 0 : (newPoints ? newPoints : 0),
            cost: newCost ? newCost : null,
            capacity: newCapacity ? newCapacity : null,
            location: locations.find(x => x.id === newLocation),
            organiser: newOrganiser,
            contact: allContacts.find(x => x.id === newContact),
            imageUrl: newImage,
            iconName: newIconName,
            embeddedVideo: newEmbeddedVideo,
            linkName: newLinkName,
            linkUrl: newLinkUrl,
            nonSubscribable: newNonSubscribable ? newNonSubscribable : false,
            archive: newArchive,
            createdBy: selectedActivity.createdBy,
            dateCreated: selectedActivity.dateCreated,
            modifiedBy: `AP/${user.number}`,
            timestamp: selectedActivity.timestamp,
        }));
    };

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (!newHeadType) return setErrorHeadType(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorHeadType(null);

        if (newHeadType && (!newActivityTypes || newActivityTypes.length <= 0)) return setErrorActivityTypes(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorActivityTypes(null);

        if (!newImage) return setErrorImage(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorImage(null);

        dispatch(postActivity({
            name: newName,
            description: newDescription,
            activityTypeIds: newActivityTypes,
            points: siomSettings?.flagProgress ? 0 : (newPoints ? newPoints : 0),
            cost: newCost ? newCost : null,
            capacity: newCapacity ? newCapacity : null,
            location: locations.find(x => x.id === newLocation),
            organiser: newOrganiser,
            contact: allContacts.find(x => x.id === newContact),
            imageUrl: newImage,
            iconName: newIconName,
            embeddedVideo: newEmbeddedVideo,
            linkName: newLinkName,
            linkUrl: newLinkUrl,
            nonSubscribable: newNonSubscribable ? newNonSubscribable : false,
            archive: newArchive,
            createdBy: `AP/${user.number}`,
        }));
    };

    return (
        <>
            <Box sx={{ ...modalStyle }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                    <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                        <Grid container spacing={1}>
                            <Grid size={{ xs: 12 }}>
                                <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.ACTIVITY} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth required
                                    defaultValue={selectedActivity?.name}
                                    label={resourceFinal[appLanguage].words.NAME}
                                    onChange={handleNameChange}
                                    error={errorName !== null}
                                    helperText={errorName}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth multiline
                                    defaultValue={selectedActivity?.description}
                                    label={resourceFinal[appLanguage].words.DESCRIPTION}
                                    onChange={handleDescriptionChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <Stack direction="row" spacing={0.5} sx={{ width: 1 }}>
                                    <FormControl fullWidth error={errorHeadType !== null} required>
                                        <InputLabel>{resourceFinal[appLanguage].words.TYPE_HEAD}</InputLabel>
                                        <Select label={resourceFinal[appLanguage].words.TYPE_HEAD}
                                            defaultValue={selectedActivity?.headType ? selectedActivity?.headType?.id : ""} onChange={handleHeadTypeChange}>
                                            {headTypes?.map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{item.naam}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText>{errorHeadType}</FormHelperText>
                                    </FormControl>
                                    <IconButton sx={{ height: 56, border: 1, borderRadius: 1, borderColor: 'white.dark' }} onClick={() => {
                                        setModalType("Head");
                                        setOpenType(true);
                                    }}><AddIcon color="primary" /></IconButton>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <Stack direction="row" spacing={0.5} sx={{ width: 1 }}>
                                    <FormControl fullWidth error={errorActivityTypes !== null} required>
                                        <InputLabel>{resourceFinal[appLanguage].words.TYPES}</InputLabel>
                                        <Select multiple disabled={!newHeadType} label={resourceFinal[appLanguage].words.TYPES} value={newActivityTypes ? newActivityTypes : []} onChange={handleActivityTypeChange}>
                                            {newHeadType && activityTypes?.filter(item => item.headTypeId === newHeadType).map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{item.type}</MenuItem>
                                            )}
                                        </Select>
                                        <FormHelperText>{errorActivityTypes}</FormHelperText>
                                    </FormControl>
                                    <IconButton sx={{ height: 56, border: 1, borderRadius: 1, borderColor: 'white.dark' }} onClick={() => {
                                        setModalType("Activity");
                                        setOpenType(true);
                                    }}><AddIcon color="primary" /></IconButton>
                                </Stack>
                            </Grid>
                            {!siomSettings?.flagProgress && <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth type="number"
                                    inputProps={{ min: 0 }}
                                    defaultValue={selectedActivity?.points}
                                    label={appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}
                                    onChange={handlePointsChange}
                                    variant="outlined"
                                />
                            </Grid>}
                            {appSettings?.appVersion === 1 && <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth type="number"
                                    inputProps={{ step: 0.5, min: 0 }}
                                    defaultValue={selectedActivity?.cost}
                                    label={resourceFinal[appLanguage].words.COST}
                                    onChange={handleCostChange}
                                    variant="outlined"
                                />
                            </Grid>}
                            <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth type="number"
                                    inputProps={{ min: 0 }}
                                    defaultValue={selectedActivity?.capacity}
                                    label={resourceFinal[appLanguage].words.CAPACITY}
                                    onChange={handleCapacityChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <FormControl fullWidth>
                                    <InputLabel>{resourceFinal[appLanguage].words.CONTACT}</InputLabel>
                                    <Select label={resourceFinal[appLanguage].words.CONTACT} defaultValue={selectedActivity?.contact ? selectedActivity?.contact?.id : ""} onChange={handleContactChange}>
                                        <MenuItem value={''}>-</MenuItem>
                                        {allContacts?.map((item) =>
                                            <MenuItem key={item.id} value={item.id}>{DisplayName(item.firstname, item.insertion, item.lastname)}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={selectedActivity?.organiser}
                                    label={resourceFinal[appLanguage].words.ORGANISER}
                                    onChange={handleOrganiserChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: appSettings?.appVersion === 1 ? 6 : 12 }}>
                                <Stack direction="row" spacing={0.5} sx={{ width: 1, height: 56 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{resourceFinal[appLanguage].words.LOCATION}</InputLabel>
                                        <Select label={resourceFinal[appLanguage].words.LOCATION}
                                            defaultValue={selectedActivity?.location ? selectedActivity?.location?.id : ""} onChange={handleLocationChange}>
                                            <MenuItem value={''}>-</MenuItem>
                                            {locations?.map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{item.naam}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    <IconButton sx={{ border: 1, borderRadius: 1, borderColor: 'white.dark' }} onClick={() => setOpenLocation(true)}><AddIcon color="primary" /></IconButton>
                                </Stack>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <Button
                                    sx={{ width: 1, height: 1 }}
                                    variant="outlined"
                                    component="label"
                                    color={errorImage ? "error" : "secondary"}
                                >
                                    {imageName ? imageName : resourceFinal[appLanguage].words.IMAGE}
                                    <input
                                        type="file"
                                        name="image"
                                        onChange={handleImageChange}
                                        hidden
                                    />
                                </Button>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={selectedActivity?.iconName}
                                    label={resourceFinal[appLanguage].words.ICON}
                                    onChange={handleIconNameChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={selectedActivity?.embeddedVideo}
                                    label={resourceFinal[appLanguage].words.EMBEDDED_VIDEO}
                                    onChange={handleEmbeddedVideoChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={selectedActivity?.linkName}
                                    label={`${resourceFinal[appLanguage].words.LINK} ${resourceFinal[appLanguage].words.NAME.toLowerCase()}`}
                                    onChange={handleLinkNameChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <TextField
                                    fullWidth
                                    defaultValue={selectedActivity?.linkUrl}
                                    label={`${resourceFinal[appLanguage].words.LINK} ${resourceFinal[appLanguage].words.URL.toLowerCase()}`}
                                    onChange={handleLinkUrlChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid size={{ xs: 12, md: addNew ? 12 : 6 }}>
                                <FormControlLabel control={<Checkbox defaultChecked={selectedActivity?.nonSubscribable} onChange={handleNonSubscribableChange} />} label={resourceFinal[appLanguage].messages.NON_SUBSCRIBABLE} />
                            </Grid>
                            {!addNew && <Grid size={{ xs: 12, md: 6 }}>
                                <FormControlLabel control={<Checkbox defaultChecked={selectedActivity?.archive} onChange={handleArchiveChange} />} label={resourceFinal[appLanguage].words.ARCHIVE} />
                            </Grid>}
                            <Grid size={{ xs: 12 }}>
                                {showFailed && <>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                                </>}
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{errorImage}</Typography>
                            </Grid>
                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonGroup sx={{ justifyContent: 'center' }}>
                                    <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                    {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                    {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Scrollbars>
                </Card>
            </Box>
            <Modal open={openLocation} onClose={callbackModalLocation}>
                <>
                    <LocationEditCard handleClose={callbackModalLocation} addNew />
                </>
            </Modal>
            <Modal open={openType} onClose={callbackModalType}>
                <>
                    {modalType === "Head" && <HeadTypeEditCard handleClose={callbackModalType} addNew />}
                    {modalType === "Activity" && <ActivityTypeEditCard handleClose={callbackModalType} addNew />}
                </>
            </Modal>
        </>
    );
}
