import { Badge } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FlagIcon from '@mui/icons-material/Flag';
import GradeIcon from '@mui/icons-material/Grade';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Custom Apple Icon
function AppleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M20,10C22,13 17,22 15,22C13,22 13,21 12,21C11,21 11,22 9,22C7,22 2,13 4,10C6,7 9,7 11,8V5C5.38,8.07 4.11,3.78 4.11,3.78C4.11,3.78 6.77,0.19 11,5V3H13V8C15,7 18,7 20,10Z" />
        </SvgIcon>
    );
};

export default function Flag({ count, style, type, badgePosition, icon }) {
    const renderIcon = () => {
        if (icon.toLowerCase() === 'flag') return <FlagIcon sx={{ ...style }} />;
        if (icon.toLowerCase() === 'face') {
            if (type.toLowerCase() === 'red') return <SentimentVeryDissatisfiedIcon sx={{ ...style }} />;
            if (type.toLowerCase() === 'yellow') return <SentimentNeutralIcon sx={{ ...style }} />;
            if (type.toLowerCase() === 'green') return <SentimentSatisfiedAltIcon sx={{ ...style }} />;
        }
        if (icon.toLowerCase() === 'cup') return <EmojiEventsIcon sx={{ ...style }} />;
        if (icon.toLowerCase() === 'apple') return <AppleIcon sx={{ ...style }} />;
        if (icon.toLowerCase() === 'heart') return <FavoriteIcon sx={{ ...style }} />;
        if (icon.toLowerCase() === 'star') return <GradeIcon sx={{ ...style }} />;
        if (icon.toLowerCase() === 'thumb') return <ThumbUpIcon sx={{ ...style }} />;
        return null;
    };

    return !badgePosition ? (
        renderIcon()
    ) : (
        <Badge badgeContent={count} color="secondary" overlap="circular" anchorOrigin={badgePosition}>
            {renderIcon()}
        </Badge>
    );
}
