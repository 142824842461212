import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

export default function ProgressBar({ progressPoints, legendDisplay, barHeight }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { achievedPoints, subscribedPoints, neededPoints, maxPoints } = progressPoints;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        indexAxis: "y",
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                stacked: true,
                display: true,
                min: 0,
                max: maxPoints,
            },
            y: {
                stacked: true,
                display: false
            }
        },
        elements: {
            bar: {
                borderWidth: 2
            }
        },
        plugins: {
            legend: {
                display: legendDisplay,
                position: "bottom",
                onClick: null
            }
        }
    };

    const labels = [resourceFinal[appLanguage].words.POINTS];

    const data = {
        labels,
        datasets: [
            {
                label: resourceFinal[appLanguage].words.POINTS_ACHIEVED,
                data: labels.map(() => achievedPoints),
                borderColor: "rgb(76, 175, 80)",
                backgroundColor: "rgb(76, 175, 80, 0.7)"
            },
            {
                label: resourceFinal[appLanguage].words.POINTS_SUBSCRIBED,
                data: labels.map(() => subscribedPoints),
                borderColor: "rgb(253, 216, 53)",
                backgroundColor: "rgba(253, 216, 53, 0.7)"
            },
            {
                label: resourceFinal[appLanguage].words.POINTS_NEEDED,
                data: labels.map(() => neededPoints),
                borderColor: "rgb(239, 108, 0)",
                backgroundColor: "rgba(239, 108, 0, 0.7)"
            }
        ]
    };

    return (
        <Bar height={barHeight} options={options} data={data} />
    );
}
