import { Box } from '@mui/material';
import { useSelector } from "react-redux";
import { CardMedium, HomeTileSmall } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import ProgressBar from './ProgressBar';

export default function ProgressTile() {
    const { userProgress, userProgressStatus } = useSelector((state) => state.progress);

    return (
        <Box sx={{ ...HomeTileSmall }}>
            <Box sx={{ ...CardMedium, display: 'flex', width: 1, height: 1, alignItems: 'center', boxSizing: 'border-box' }}>
                <ContentLoader successCondition={userProgressStatus === "success"} errorCondition={userProgressStatus === "failed"} textDisabled={true} errorCompact={true} content={
                    <ProgressBar progressPoints={userProgress?.totalPoints} legendDisplay={false} barHeight={80} />
                } />
            </Box>
        </Box>
    );
}
