import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ArticleList from '../subcomponents/ArticleList';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import { fetchNewNewsArticles, fetchNews } from './newsSlice';

export default function NewsPage() {
    const dispatch = useDispatch();
    const { news, newsStatus } = useSelector((state) => state.news);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        dispatch(fetchNews());
        dispatch(fetchNewNewsArticles());
    }, [dispatch]);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.NEWS} content={
            <ContentLoader successCondition={newsStatus === "success"} errorCondition={newsStatus === "failed"} content={
                <ArticleList articles={news} status={newsStatus} rowHeight={85} pagination news />
            } />
        } bottomBar />
    );
}
