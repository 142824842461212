import Grid from '@mui/material/Grid2';
import { useSelector } from "react-redux";
import ListItemCard from '../subcomponents/ListItemCard';
import PageLayout from '../subcomponents/PageLayout';

import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import SchoolIcon from '@mui/icons-material/School';

export default function AboutPage() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.ABOUT} content={
            <Grid container spacing={1} sx={{ mt: { xs: 0, md: 1 } }}>
                <Grid size={{ xs: 12 }}>
                    <ListItemCard
                        avatarIcon={<HomeIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={resourceFinal[appLanguage].words.ACTIVITYPLANNER}
                        secondaryText={resourceFinal[appLanguage].words.APPLICATION}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <ListItemCard
                        avatarIcon={<ListIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={window.Config.version}
                        secondaryText={resourceFinal[appLanguage].words.VERSION}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <ListItemCard
                        avatarIcon={<LogoDevIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={resourceFinal[appLanguage].words.INNOVAAPPS}
                        secondaryText={resourceFinal[appLanguage].words.DEVELOPER}
                    />
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <ListItemCard
                        avatarIcon={<SchoolIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={window.Config.school}
                        secondaryText={resourceFinal[appLanguage].words.SCHOOL}
                    />
                </Grid>
                {siomSettings.useMultipleDepartments ? <Grid size={{ xs: 12 }}>
                    <ListItemCard
                        avatarIcon={<ApartmentIcon />}
                        avatarColor={'secondary.main'}
                        primaryText={activeDepartment ? activeDepartment[0].name : ""}
                        secondaryText={resourceFinal[appLanguage].words.DEPARTMENT}
                    />
                </Grid> : ""}
            </Grid>
        } bottomBar />
    );
}
