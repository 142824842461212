import { Backdrop, Button, Card, CardContent, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

export default function MobileBlockWarning() {
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase()

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [accepted, setAccepted] = useState(false);

    return (
        <>
            {accepted ? "" :
                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Card sx={{ m: 1, px: 2, py: 1, textAlign: 'center' }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ fontSize: '1.4em' }}>{resourceFinal[appLanguage].messages.EMPLOYEE_MOBILE_BLOCK}</Typography>
                            <Button variant="outlined" sx={{ mt: 1 }} onClick={() => setAccepted(true)}>{resourceFinal[appLanguage].words.ACCEPT}</Button>
                        </CardContent>
                    </Card>
                </Backdrop>}
        </>
    );
}
