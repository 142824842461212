import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchSearchSchoolclasses = createAsyncThunk(
    '/api/search/classes/licensed',
    async (licensed, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/search/classes/${licensed}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSearchSchoolclassStudents = createAsyncThunk(
    '/api/search/class/classId',
    async ({ classId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/search/class/${classId}/${departmentId}` : `api/search/class/${classId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const searchSchoolclassSlice = createSlice({
    name: 'searchSchoolclass',
    initialState: {
        searchSchoolclasses: null,
        searchSchoolclassesStatus: null,
        searchClassStudents: null,
        searchClassStudentsStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch Search Classes
        builder
            .addCase(fetchSearchSchoolclasses.pending, (state) => {
                state.searchSchoolclassesStatus = 'loading';
            })
            .addCase(fetchSearchSchoolclasses.fulfilled, (state, action) => {
                state.searchSchoolclasses = action.payload;
                state.searchSchoolclassesStatus = 'success';
            })
            .addCase(fetchSearchSchoolclasses.rejected, (state) => {
                state.searchSchoolclassesStatus = 'failed';
            });

        // Fetch Search Class Students
        builder
            .addCase(fetchSearchSchoolclassStudents.pending, (state) => {
                state.searchClassStudentsStatus = 'loading';
            })
            .addCase(fetchSearchSchoolclassStudents.fulfilled, (state, action) => {
                state.searchClassStudents = action.payload;
                state.searchClassStudentsStatus = 'success';
            })
            .addCase(fetchSearchSchoolclassStudents.rejected, (state) => {
                state.searchClassStudentsStatus = 'failed';
            });
    }
});

//export const { } = searchSchoolclassSlice.actions;

export default searchSchoolclassSlice.reducer;