import { Box, Pagination } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import ArticleListCard from './ArticleListCard';

export default function ArticleList({ articles, status, pagination, news, rowHeight, fullWidth }) {
    const [amount, setAmount] = useState(0);
    const [itemsLeft, setItemsLeft] = useState(0);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [containerHeight, setContainerHeight] = useState();

    useEffect(() => {
        setContainerHeight(document.querySelector('#container').offsetHeight);
    }, []);

    useEffect(() => {
        setAmount(Math.floor(containerHeight / rowHeight - 1));
    }, [containerHeight, rowHeight]);

    useEffect(() => {
        if (status === "success") {
            const size = articles?.length;
            setItemsLeft(size - amount);
        }
    }, [status, amount, articles]);

    useEffect(() => {
        setPages(Math.ceil(itemsLeft / amount) + 1);
    }, [itemsLeft, amount]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box id="container" sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', alignItems: 'center' }}>
            <Grid container spacing={1} sx={{ display: 'flex', flex: '1 1 auto', width: 1 }} alignContent="flex-start">
                {(articles?.slice((page - 1) * amount, page * amount)?.map((item) =>
                    <Grid key={item.id} size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <ArticleListCard item={item} articleId={item.id} news={news} fullWidth={fullWidth} />
                    </Grid>
                ))}
            </Grid>
            {(itemsLeft > 0 && !isNaN(pages) && pagination) &&
                <Box sx={{ mt: 'auto', p: 1 }}>
                    <Pagination count={pages} page={page} onChange={handleChange} defaultPage={1} color="secondary" />
                </Box>}
        </Box>
    );
}