import { Box, Button, Checkbox, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../../app/AppStyles';
import { DisplayName, GetDate, GetTime } from '../../../app/AppUtils';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import SubscriptionAddCard from './SubscriptionAddCard';
import { deleteSubscription, putSubscription, setFailedSubscription } from './subscriptionSlice';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

export default function SubscriptionsDisplay({ performanceId }) {
    const dispatch = useDispatch();
    const { subscriptions, subscriptionsStatus } = useSelector((state) => state.subscription);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { user } = useSelector((state) => state.user);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);
    const [openNew, setOpenNew] = useState(false);

    const newClick = () => setOpenNew(!openNew);
    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedSubscription(false));
    };

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CLASS}</TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIPTION} {resourceFinal[appLanguage].words.CONFIRMED.toLowerCase()}</TableCell>
                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DATE_CREATED}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    function EnhancedTableToolbar({ numSelected }) {
        const deleteClick = () => {
            var unsubscriptions = [];
            selected.forEach(item => unsubscriptions.push({
                studentId: item.student.studentId,
                subscriptionId: item.id,
                performanceId: item.performance.id,
                createdBy: `AP/${user.number}`
            }));

            setSelected([]);
            dispatch(deleteSubscription(unsubscriptions));
        };

        const confirmClick = () => {
            var subscriptions = [];
            selected.forEach(item => subscriptions.push({
                id: item.id,
                isReservation: false,
                modifiedBy: `AP/${user.number}`,
            }));

            setSelected([]);
            dispatch(putSubscription(subscriptions));
        };

        return (
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', ...(numSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}
                    </Typography>
                ) : (
                    <>
                        <Typography
                            sx={{ ...PageHeader }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            {resourceFinal[appLanguage].words.SUBSCRIPTIONS}
                        </Typography>
                        <Button variant="outlined" onClick={() => newClick("Head")} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </>
                )}

                {numSelected > 0 &&
                    <>
                        <ConfirmationModal iconButton={true} icon={<CheckIcon color="secondary" />}
                            message={`${selected.length > 1 ? resourceFinal[appLanguage].messages.CONFIRM_RESERVATIONS : resourceFinal[appLanguage].messages.CONFIRM_RESERVATION}`}
                            confirmAction={() => confirmClick(selected)}
                        />
                        <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />}
                            message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${numSelected} ${numSelected > 1 ? resourceFinal[appLanguage].words.STUDENTS.toLowerCase() : resourceFinal[appLanguage].words.STUDENT.toLowerCase()} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_3}`}
                            confirmAction={() => deleteClick(selected)}
                        />
                    </>}
            </Toolbar>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = subscriptions.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        };

        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subscriptions.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.findIndex(x => x.id === id) !== -1;

    return (
        <ContentLoader successCondition={subscriptionsStatus === "success"} errorCondition={subscriptionsStatus === "failed"} content={
            <>
                <Box sx={{ p: 0.5, ...ListItemBackground }}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table>
                            <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={subscriptions?.length} />
                            <TableBody>
                                {(rowsPerPage > 0 ? subscriptions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subscriptions)?.map((item, index) => {
                                    const isItemSelected = isSelected(item.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow hover onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={item.id} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                            </TableCell>
                                            <TableCell align="center" id={labelId}>{item.student.number}</TableCell>
                                            <TableCell align="left" id={labelId}>{DisplayName(item.student.firstName, item.student.middleName, item.student.lastName)}</TableCell>
                                            <TableCell align="center" id={labelId}>{item.student.schoolclass}</TableCell>
                                            <TableCell align="center">{item.isReservation ? resourceFinal[appLanguage].words.NO : resourceFinal[appLanguage].words.YES}</TableCell>
                                            <TableCell align="center">{GetDate(item.dateCreated)} {GetTime(item.dateCreated)}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {(rowsPerPage > 0 ? subscriptions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subscriptions)?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                                {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        sx={{ border: 0 }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={subscriptions ? subscriptions.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>

                <Modal open={openNew} onClose={callbackModalNew}>
                    <>
                        <SubscriptionAddCard handleClose={callbackModalNew} performanceId={performanceId} />
                    </>
                </Modal>
            </>
        } />
    );
}
