import { useSelector } from 'react-redux';
import EmployeeChecks from './checks/EmployeeChecks';
import StudentChecks from './checks/StudentChecks';

export default function AppChecks() {
    const { aspNetUser } = useSelector((state) => state.settings.app);

    return (
        <>
            {aspNetUser?.role?.includes("Student") && <StudentChecks />}
            {aspNetUser?.role?.includes("Employee") && <EmployeeChecks />}
        </>
    );
}
