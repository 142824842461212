import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { HomeTile } from '../../app/AppStyles';
import ArticleList from '../subcomponents/ArticleList';
import ContentLoader from '../subcomponents/ContentLoader';
import { fetchNewNewsArticles, fetchNews } from './newsSlice';

export default function NewsTile() {
    const dispatch = useDispatch();

    const { news, newsStatus } = useSelector((state) => state.news);
    const { aspNetUser } = useSelector((state) => state.settings.app);

    useEffect(() => {
        dispatch(fetchNews());

        if (aspNetUser !== null)
            dispatch(fetchNewNewsArticles());
    }, [dispatch, aspNetUser]);

    return (
        <Box sx={{ ...HomeTile, display: 'flex', flex: '1 1 auto', width: 1 }}>
            <ContentLoader successCondition={newsStatus === "success"} errorCondition={newsStatus === "failed"} content={
                <ArticleList articles={news} status={newsStatus} rowHeight={85} news fullWidth />
            } />
        </Box>
    );
}
