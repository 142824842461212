import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

export default function ExportExcelMoments({ disabled, jsonData, fileName }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (jsonData) {
            const updatedData = jsonData.map(({ id, ...rest }) => rest);

            setData(updatedData);
        }
    }, [jsonData]);

    const handleExportToExcel = () => {
        let sheetData = [];

        const headerRow = [
            `${resourceFinal[appLanguage].words.ACTIVITY}${resourceFinal[appLanguage].words.NAME.toLowerCase()}`,
            `${resourceFinal[appLanguage].words.PERFORMANCE}${resourceFinal[appLanguage].words.NAME.toLowerCase()}`,
            `${resourceFinal[appLanguage].words.PERFORMANCE}${resourceFinal[appLanguage].words.NUMBER.toLowerCase()}`,
            `${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`,
            `${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`,
            resourceFinal[appLanguage].words.LOCATION,
            resourceFinal[appLanguage].words.SUBSCRIPTIONS,
            resourceFinal[appLanguage].words.CAPACITY,
        ];

        sheetData.push(headerRow);

        data.forEach(item => {
            sheetData.push(Object.values(item));
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => handleExportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
