import { Box, Button, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const style = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "center",
    textAlign: "center",
    m: 2
};

export default function DisabledDisplay() {
    const navigate = useNavigate();
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleClick = () => {
        navigate("/");
    };

    return (
        <Box sx={{ ...style }}>
            <Typography variant="h5">{resourceFinal[appLanguage].messages.PAGE_UNAVAILABLE}</Typography>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="h6"><Button variant="text" onClick={handleClick} sx={{ p: 0 }}>{resourceFinal[appLanguage].messages.ERROR_RETURN_LINK}</Button> {resourceFinal[appLanguage].messages.ERROR_RETURN_MESSAGE}</Typography>
            </Box>
        </Box>
    )
}