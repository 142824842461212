import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

// PROGRAMS
export const getPrograms = createAsyncThunk(
    '/api/programManagement/programs',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/programs`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putProgram = createAsyncThunk(
    "/api/programManagement/programs/put",
    async (updatedProgram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/programs",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedProgram),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postProgram = createAsyncThunk(
    "/api/programManagement/programs/post",
    async (newProgram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/programs",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newProgram),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteProgram = createAsyncThunk(
    "/api/programManagement/programs/delete",
    async (programId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/programManagement/programs/${programId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

// PROGRAM SUBPROGRAMS
export const getProgramSubprograms = createAsyncThunk(
    '/api/programManagement/programSubprograms',
    async (programId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/programSubprograms/${programId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getAvailableSubprograms = createAsyncThunk(
    '/api/programManagement/programSubprograms/subprograms/programId',
    async (programId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/programSubprograms/subprograms/${programId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putProgramSubprograms = createAsyncThunk(
    "/api/programManagement/programSubprograms/put",
    async (updatedProgramSubprogram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/programSubprograms",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedProgramSubprogram),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postProgramSubprograms = createAsyncThunk(
    "/api/programManagement/programSubprograms/post",
    async (newProgramSubprograms, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/programSubprograms",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newProgramSubprograms),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteProgramSubprograms = createAsyncThunk(
    "/api/programManagement/programSubprograms/delete",
    async (programSubprogramIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/programManagement/programSubprograms`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
                body: JSON.stringify(programSubprogramIds),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const programManagementSlice = createSlice({
    name: 'programManagement',
    initialState: {
        // PROGRAMS
        programs: null,
        programsStatus: null,
        putProgramStatus: null,
        postProgramStatus: null,
        deleteProgramStatus: null,
        selectedProgram: null,
        showProgramFailed: false,

        // PROGRAM SUBPROGRAMS
        programSubprograms: null,
        programSubprogramsStatus: null,
        availableSubprograms: null,
        availableSubprogramsStatus: null,
        putProgramSubprogramsStatus: null,
        postProgramSubprogramsStatus: null,
        deleteProgramSubprogramsStatus: null,
        selectedProgramSubprogram: null,
        showProgramSubprogramsFailed: false,
    },
    reducers: {
        selectProgram: (state, action) => {
            state.selectedProgram = action.payload;
        },
        setFailedProgram: (state, action) => {
            state.showProgramFailed = action.payload;
        },
        resetProgramStatus: (state) => {
            state.putProgramStatus = null;
            state.postProgramStatus = null;
            state.deleteProgramStatus = null;
        },
        selectProgramSubprogram: (state, action) => {
            state.selectedProgramSubprogram = action.payload;
        },
        setFailedProgramSubprograms: (state, action) => {
            state.showProgramSubprogramsFailed = action.payload;
        },
        resetProgramSubprogramsStatus: (state) => {
            state.putProgramSubprogramsStatus = null;
            state.postProgramSubprogramsStatus = null;
            state.deleteProgramSubprogramsStatus = null;
        },
    },
    extraReducers: (builder) => {
        // PROGRAMS
        builder // GET
            .addCase(getPrograms.pending, (state) => {
                state.programsStatus = 'loading';
            })
            .addCase(getPrograms.fulfilled, (state, action) => {
                state.programsStatus = 'success';
                state.programs = action.payload;
            })
            .addCase(getPrograms.rejected, (state) => {
                state.programsStatus = 'failed';
            });

        builder // PUT
            .addCase(putProgram.pending, (state) => {
                state.putProgramStatus = 'loading';
            })
            .addCase(putProgram.fulfilled, (state) => {
                state.putProgramStatus = 'success';
            })
            .addCase(putProgram.rejected, (state) => {
                state.putProgramStatus = 'failed';
            });

        builder // POST
            .addCase(postProgram.pending, (state) => {
                state.postProgramStatus = 'loading';
            })
            .addCase(postProgram.fulfilled, (state) => {
                state.postProgramStatus = 'success';
            })
            .addCase(postProgram.rejected, (state) => {
                state.postProgramStatus = 'failed';
            });

        builder // DELETE
            .addCase(deleteProgram.pending, (state) => {
                state.deleteProgramStatus = 'loading';
            })
            .addCase(deleteProgram.fulfilled, (state) => {
                state.deleteProgramStatus = 'success';
            })
            .addCase(deleteProgram.rejected, (state) => {
                state.deleteProgramStatus = 'failed';
            });

        // PROGRAM SUBPROGRAMS
        builder // GET
            .addCase(getProgramSubprograms.pending, (state) => {
                state.programSubprogramsStatus = 'loading';
            })
            .addCase(getProgramSubprograms.fulfilled, (state, action) => {
                state.programSubprogramsStatus = 'success';
                state.programSubprograms = action.payload;
            })
            .addCase(getProgramSubprograms.rejected, (state) => {
                state.programSubprogramsStatus = 'failed';
            });

        builder // GET AVAILABLE SUBPROGRAMS
            .addCase(getAvailableSubprograms.pending, (state) => {
                state.availableSubprogramsStatus = 'loading';
            })
            .addCase(getAvailableSubprograms.fulfilled, (state, action) => {
                state.availableSubprogramsStatus = 'success';
                state.availableSubprograms = action.payload;
            })
            .addCase(getAvailableSubprograms.rejected, (state) => {
                state.availableSubprogramsStatus = 'failed';
            });

        builder // PUT
            .addCase(putProgramSubprograms.pending, (state) => {
                state.putProgramSubprogramsStatus = 'loading';
            })
            .addCase(putProgramSubprograms.fulfilled, (state) => {
                state.putProgramSubprogramsStatus = 'success';
            })
            .addCase(putProgramSubprograms.rejected, (state) => {
                state.putProgramSubprogramsStatus = 'failed';
            });

        builder // POST
            .addCase(postProgramSubprograms.pending, (state) => {
                state.postProgramSubprogramsStatus = 'loading';
            })
            .addCase(postProgramSubprograms.fulfilled, (state) => {
                state.postProgramSubprogramsStatus = 'success';
            })
            .addCase(postProgramSubprograms.rejected, (state) => {
                state.postProgramSubprogramsStatus = 'failed';
            });

        builder // DELETE
            .addCase(deleteProgramSubprograms.pending, (state) => {
                state.deleteProgramSubprogramsStatus = 'loading';
            })
            .addCase(deleteProgramSubprograms.fulfilled, (state) => {
                state.deleteProgramSubprogramsStatus = 'success';
            })
            .addCase(deleteProgramSubprograms.rejected, (state) => {
                state.deleteProgramSubprogramsStatus = 'failed';
            });
    },
});

export const {
    selectProgram,
    setFailedProgram,
    resetProgramStatus,
    selectProgramSubprogram,
    setFailedProgramSubprograms,
    resetProgramSubprogramsStatus
} = programManagementSlice.actions;

export default programManagementSlice.reducer;
