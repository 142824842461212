import { Card, List } from '@mui/material';
import { CardMedium } from '../../app/AppStyles';
import ListItemCard from '../subcomponents/ListItemCard';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';

export default function ContactCard({ contact }) {
    const { firstname, lastname, insertion, phone, email } = contact;

    return (
        <Card sx={{ ...CardMedium, p: 0 }}>
            <List dense={true} >
                <ListItemCard altBgColor={{ bgcolor: '' }} avatarIcon={<PersonIcon />} avatarColor={'secondary.main'} primaryText={`${firstname} ${insertion} ${lastname}`} />
                <ListItemCard altBgColor={{ bgcolor: '' }} avatarIcon={<AlternateEmailIcon />} avatarColor={'secondary.main'} primaryText={email ? email : "-"} />
                <ListItemCard altBgColor={{ bgcolor: '' }} avatarIcon={<PhoneIcon />} avatarColor={'secondary.main'} primaryText={phone ? phone : "-"} />
            </List>
        </Card>
    );
}
