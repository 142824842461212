import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchSearchStudents = createAsyncThunk(
    '/api/search/students/isActive',
    async (isActive, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/search/students/${isActive}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const fetchSearchStudent = createAsyncThunk(
    '/api/search/student/studentId',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/search/student/${studentId}/${departmentId}` : `api/search/student/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSearchStudentTimetable = createAsyncThunk(
    '/api/timetable/employee',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/timetable/employee/${studentId}/${departmentId}` : `api/timetable/employee/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchSearchStudentAttendances = createAsyncThunk(
    '/api/attendance/student/studentId/departmentId',
    async ({ studentId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null ? `api/attendance/student/${studentId}/${departmentId}` : `api/attendance/student/${studentId}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putStudentAttendance = createAsyncThunk(
    "/api/attendance/student/put",
    async (studentAttendance, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/attendance/student`, {
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "PUT",
            redirect: "follow",
            body: JSON.stringify(studentAttendance),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const searchStudentSlice = createSlice({
    name: 'searchStudent',
    initialState: {
        // Student progress and info
        searchStudents: null,
        searchStudentsStatus: null,
        searchStudent: null,
        searchStudentStatus: null,

        // Register
        studentRegisterFilter: '',
        studentRegisterPage: 'REGISTER',
        studentRegisterParentCheck: true,
        studentRegisterReasonMode: false,

        // Timetable
        searchStudentTimetable: null,
        searchStudentTimetableStatus: null,

        // Attendance
        searchStudentAttendance: null,
        searchStudentAttendanceStatus: null,
        putStudentAttendanceStatus: null,
        selectedAttendance: null,
        failedAttendance: false,
    },
    reducers: {
        // Attendance
        setSelectedAttendance: (state, action) => {
            state.selectedAttendance = action.payload;
        },
        setFailedAttendance: (state, action) => {
            state.failedAttendance = action.payload;
        },
        resetAttendanceStatus: (state, action) => {
            state.putStudentAttendanceStatus = null;
        },

        // Register
        setStudentRegisterFilter: (state, action) => {
            state.studentRegisterFilter = action.payload;
        },
        setStudentRegisterPage: (state, action) => {
            state.studentRegisterPage = action.payload;
        },
        setStudentRegisterParentCheck: (state) => {
            state.studentRegisterParentCheck = !state.studentRegisterParentCheck;
        },
        setStudentRegisterReasonMode: (state) => {
            state.studentRegisterReasonMode = !state.studentRegisterReasonMode;
        },
    },
    extraReducers: (builder) => {
        // Fetch Search Students
        builder
            .addCase(fetchSearchStudents.pending, (state) => {
                state.searchStudentsStatus = 'loading';
            })
            .addCase(fetchSearchStudents.fulfilled, (state, action) => {
                state.searchStudentsStatus = 'success';
                state.searchStudents = action.payload;
            })
            .addCase(fetchSearchStudents.rejected, (state) => {
                state.searchStudentsStatus = 'failed';
            });

        // Fetch Search Student
        builder
            .addCase(fetchSearchStudent.pending, (state) => {
                state.searchStudentStatus = 'loading';
            })
            .addCase(fetchSearchStudent.fulfilled, (state, action) => {
                state.searchStudent = action.payload;
                state.searchStudentStatus = 'success';
            })
            .addCase(fetchSearchStudent.rejected, (state) => {
                state.searchStudentStatus = 'failed';
            });

        // Fetch Search Student Timetable
        builder
            .addCase(fetchSearchStudentTimetable.pending, (state) => {
                state.searchStudentTimetableStatus = 'loading';
            })
            .addCase(fetchSearchStudentTimetable.fulfilled, (state, action) => {
                state.searchStudentTimetableStatus = 'success';
                state.searchStudentTimetable = action.payload;
            })
            .addCase(fetchSearchStudentTimetable.rejected, (state) => {
                state.searchStudentTimetableStatus = 'failed';
            });

        // Fetch Search Student Attendances
        builder
            .addCase(fetchSearchStudentAttendances.pending, (state) => {
                state.searchStudentAttendanceStatus = 'loading';
            })
            .addCase(fetchSearchStudentAttendances.fulfilled, (state, action) => {
                state.searchStudentAttendanceStatus = 'success';
                state.searchStudentAttendance = action.payload;
            })
            .addCase(fetchSearchStudentAttendances.rejected, (state) => {
                state.searchStudentAttendanceStatus = 'failed';
            });

        // Put Student Attendance
        builder
            .addCase(putStudentAttendance.pending, (state) => {
                state.putStudentAttendanceStatus = 'loading';
            })
            .addCase(putStudentAttendance.fulfilled, (state, action) => {
                state.putStudentAttendanceStatus = 'success';
            })
            .addCase(putStudentAttendance.rejected, (state) => {
                state.putStudentAttendanceStatus = 'failed';
            });
    }
});

export const {
    setSelectedAttendance,
    setFailedAttendance,
    resetAttendanceStatus,
    setStudentRegisterFilter,
    setStudentRegisterPage,
    setStudentRegisterParentCheck,
    setStudentRegisterReasonMode
} = searchStudentSlice.actions;

export default searchStudentSlice.reducer;