import { Backdrop, Box, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import PageLayout from '../subcomponents/PageLayout';
import DepartmentPicker from './DepartmentPicker';

export default function DepartmentPickerPage() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <PageLayout content={
            <>
                <Typography sx={{ textAlign: 'center', typography: { xs: 'h5', md: 'h3' }, zIndex: (theme) => theme.zIndex.drawer + 2, color: 'primary.light' }}>{resourceFinal[appLanguage].messages.DEPARTMENT_PICKER}</Typography>
                <Typography variant="subtitle1" sx={{ textAlign: 'center', zIndex: (theme) => theme.zIndex.drawer + 2, color: 'primary.light' }} gutterBottom>{resourceFinal[appLanguage].messages.DEPARTMENT_PICKER_INTRO}</Typography>
                <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Box sx={{ width: { xs: '90%', md: '50%' } }}>
                        <DepartmentPicker compact={false} />
                    </Box>
                </Backdrop>
            </>
        } />
    );
}
