import { Backdrop, Button, Card, CardContent, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchFavoriteSettings } from './favoritesSlice';

import FavoriteIcon from '@mui/icons-material/Favorite';

export default function FavoritesWarning() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase()

    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { favoriteSettings, favoriteSettingsStatus } = useSelector((state) => state.favorites);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const favoriteWord = (favoriteSettings?.favoriteMinimum - favoriteSettings?.userFavorites) > 1 ? `${resourceFinal[appLanguage].words.FAVORITES}` : `${resourceFinal[appLanguage].words.FAVORITE}`

    const ButtonClick = () => {
        navigate('/Favorieten-toevoegen');
    };

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchFavoriteSettings(activeDepartment[0].id));
                };
            } else {
                dispatch(fetchFavoriteSettings(null));
            };
        };
    }, [dispatch, siomSettings, student, activeDepartment, departmentThemesStatus]);

    return (
        <>
            {path === "/favorieten" || path === "/favorieten-toevoegen" || path.startsWith("/activiteit/") ? "" :
                <>
                    {favoriteSettingsStatus === 'success' ?
                        <>
                            {favoriteSettings.forceFavorite && favoriteSettings.userFavorites < favoriteSettings.favoriteMinimum ?
                                <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                                    <Card sx={{ height: 'auto', mx: 2, textAlign: 'center' }}>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography variant="h6">{resourceFinal[appLanguage].messages.FAVORITES_WARNING_1} {favoriteSettings.favoriteMinimum - favoriteSettings.userFavorites} {favoriteWord?.toLowerCase()} {resourceFinal[appLanguage].messages.FAVORITES_WARNING_2}</Typography>
                                            <Button sx={{ mt: 2 }} onClick={() => ButtonClick()} startIcon={<FavoriteIcon />} variant="outlined" color="secondary">{resourceFinal[appLanguage].words.FAVORITES} {resourceFinal[appLanguage].words.ADD}</Button>
                                        </CardContent>
                                    </Card>
                                </Backdrop>
                                : ""
                            }
                        </>
                        : ""
                    }
                </>
            }
        </>
    )
}