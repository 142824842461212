import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchFavorites = createAsyncThunk(
    "/api/favorite/user",
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null
            ? `api/favorite/user/${departmentId}`
            : `api/favorite/user`;
        const response = await fetch(
            window.Config.apiUrl + urlParams,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchFavoriteSettings = createAsyncThunk(
    "/api/favorite/userSettings",
    async (departmentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const urlParams = departmentId !== null
            ? `api/favorite/userSettings/${departmentId}`
            : `api/favorite/userSettings`;
        const response = await fetch(
            window.Config.apiUrl + urlParams,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const addFavorite = createAsyncThunk(
    "/api/favorite",
    async ({ activityId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/favorite",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify({
                    activityId: activityId,
                    departmentId: departmentId,
                }),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const removeFavorite = createAsyncThunk(
    "/api/favorite/favoriteId/studentId",
    async ({ activityId, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/favorite",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
                body: JSON.stringify({
                    activityId: activityId,
                    departmentId: departmentId,
                }),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const favoritesSlice = createSlice({
    name: "favorites",
    initialState: {
        favorites: null,
        favoritesStatus: null,
        favoriteSettings: null,
        favoriteSettingsStatus: null,
        addStatus: null,
        removeStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get Favorites
        builder
            .addCase(fetchFavorites.pending, (state) => {
                state.favoritesStatus = "loading";
            })
            .addCase(fetchFavorites.fulfilled, (state, action) => {
                state.favoritesStatus = "success";
                state.favorites = action.payload;
            })
            .addCase(fetchFavorites.rejected, (state, action) => {
                state.favoritesStatus = "failed";
                state.favorites = action.payload;
            });

        // Get Settings
        builder
            .addCase(fetchFavoriteSettings.pending, (state) => {
                state.favoriteSettingsStatus = "loading";
            })
            .addCase(fetchFavoriteSettings.fulfilled, (state, action) => {
                state.favoriteSettingsStatus = "success";
                state.favoriteSettings = action.payload;
            })
            .addCase(fetchFavoriteSettings.rejected, (state, action) => {
                state.favoriteSettingsStatus = "failed";
                state.favoriteSettings = action.payload;
            });

        // Add
        builder
            .addCase(addFavorite.pending, (state) => {
                state.addStatus = "loading";
            })
            .addCase(addFavorite.fulfilled, (state) => {
                state.addStatus = "success";
            })
            .addCase(addFavorite.rejected, (state) => {
                state.addStatus = "failed";
            });

        // Remove
        builder
            .addCase(removeFavorite.pending, (state) => {
                state.removeStatus = "loading";
            })
            .addCase(removeFavorite.fulfilled, (state) => {
                state.removeStatus = "success";
            })
            .addCase(removeFavorite.rejected, (state) => {
                state.removeStatus = "failed";
            });
    },
});

export default favoritesSlice.reducer;
