import { useSelector } from "react-redux";

// Student button icons
import ApartmentIcon from '@mui/icons-material/Apartment';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CreateIcon from '@mui/icons-material/Create';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EventIcon from '@mui/icons-material/Event';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FeedIcon from '@mui/icons-material/Feed';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import NewspaperIcon from '@mui/icons-material/Newspaper';

// Employee button icons
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BadgeIcon from '@mui/icons-material/Badge';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MessageIcon from '@mui/icons-material/Message';
import RuleIcon from '@mui/icons-material/Rule';
import SchoolIcon from '@mui/icons-material/School';

// SOM icons
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import HikingIcon from '@mui/icons-material/Hiking';
import KayakingIcon from '@mui/icons-material/Kayaking';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import NordicWalkingIcon from '@mui/icons-material/NordicWalking';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import PoolIcon from '@mui/icons-material/Pool';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';
import RowingIcon from '@mui/icons-material/Rowing';
import SailingIcon from '@mui/icons-material/Sailing';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import SleddingIcon from '@mui/icons-material/Sledding';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import SportsIcon from '@mui/icons-material/Sports';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SurfingIcon from '@mui/icons-material/Surfing';

// BBS icons
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import HandymanIcon from '@mui/icons-material/Handyman';
import MenuBookIcon from '@mui/icons-material/MenuBook';

// Department/Type icons
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import FestivalIcon from '@mui/icons-material/Festival';

// Eventlab icons
import ConstructionIcon from '@mui/icons-material/Construction';
import GroupsIcon from '@mui/icons-material/Groups';
import PublicIcon from '@mui/icons-material/Public';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import StorefrontIcon from '@mui/icons-material/Storefront';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

// Empty icon
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

export default function IconDisplay({ component, size, style }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <>
            {/*STUDENT BUTTONS*/}
            {component === resourceFinal[appLanguage].words.SCHEDULE && <EventIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.TIMETABLE && <CalendarMonthIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.REGISTER && <CreateIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.PROGRESS && <BarChartIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.CONTACT && <ContactPageIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.NEWS && <NewspaperIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.LEADERBOARD && <EmojiEventsIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.FAVORITES && <FavoriteIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.INFORMATION && <InfoIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.ABOUT && <FeedIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.HOME && <HomeIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.DEPARTMENT && <ApartmentIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.TRAVEL_COSTS && <DirectionsBusFilledIcon fontSize={size} sx={{ ...style }} />}

            {/*EMPLOYEE BUTTONS*/}
            {component === resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT && <ManageSearchIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.PROGRAM_MANAGEMENT && <ManageSearchIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.PLANNING && <EventIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.REPORTS_PAGE && <AssessmentIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.ATTENDANCE && <RuleIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.SEARCH_PAGE && <SchoolIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.MESSAGES_PAGE && <MessageIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.EMPLOYEE_PAGE && <BadgeIcon fontSize={size} sx={{ ...style }} />}
            {component === resourceFinal[appLanguage].words.ADMIN_PAGE && <AdminPanelSettingsIcon fontSize={size} sx={{ ...style }} />}

            {/*SPORTS*/}
            {component === "SportsAuto" && <LocalCarWashIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsBaseball" && <SportsBaseballIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsBasketball" && <SportsBasketballIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsBike" && <DirectionsBikeIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsCricket" && <SportsCricketIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsFitness" && <FitnessCenterIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsFootball" && <SportsFootballIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsGolf" && <SportsGolfIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsGymnastics" && <SportsGymnasticsIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsHandball" && <SportsHandballIcon fontSize={size} />}
            {component === "SportsHiking" && <HikingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsHockey" && <SportsHockeyIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsHorse" && <BedroomBabyIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsJudo" && <SportsKabaddiIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsKarate" && <SportsMartialArtsIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsKayak" && <KayakingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsKitesurf" && <KitesurfingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsMeditation" && <SelfImprovementIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsMinigolf" && <GolfCourseIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsMma" && <SportsMmaIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsMotorsports" && <SportsMotorsportsIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsNordic" && <NordicWalkingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsParagliding" && <ParaglidingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsRowing" && <RowingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsRugby" && <SportsRugbyIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsRun" && <DirectionsRunIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSailing" && <SailingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsScore" && <SportsScoreIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsScuba" && <ScubaDivingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSkate" && <RollerSkatingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSkateboard" && <SkateboardingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSled" && <SleddingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSnowboard" && <SnowboardingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSoccer" && <SportsSoccerIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSurf" && <SurfingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSwimming" && <PoolIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsTennis" && <SportsTennisIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsVolleyball" && <SportsVolleyballIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsWalk" && <DirectionsWalkIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsWhistle" && <SportsIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsSki" && <DownhillSkiingIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsAirplanemode" && <AirplanemodeActiveIcon fontSize={size} sx={{ ...style }} />}
            {component === "SportsPostAdd" && <PostAddIcon fontSize={size} sx={{ ...style }} />}

            {/*TYPES*/}
            {component === resourceFinal[appLanguage].words.TYPES && <Diversity2Icon fontSize={size} sx={{ ...style }} />}
            {component === "SOM" && <SportsIcon fontSize={size} sx={{ ...style }} />}
            {component === "BBS" && <BusinessCenterIcon fontSize={size} sx={{ ...style }} />}
            {component === "PBS" && <Diversity3Icon fontSize={size} sx={{ ...style }} />}
            {component === "Evenement" && <FestivalIcon fontSize={size} sx={{ ...style }} />}

            {/*BSS*/}
            {component === "BbsWorkshop" && <HandymanIcon fontSize={size} sx={{ ...style }} />}
            {component === "BbsIndividual" && <MenuBookIcon fontSize={size} sx={{ ...style }} />}
            {component === "BbsLesssons" && <CastForEducationIcon fontSize={size} sx={{ ...style }} />}

            {/*EVENTLAB*/}
            {component === "EventlabFestival" && <FestivalIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabBeurs" && <StorefrontIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabCultureel" && <PublicIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabBouw" && <ConstructionIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabSport" && <SportsSoccerIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabGaming" && <SportsEsportsIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabZakelijk" && <GroupsIcon fontSize={size} sx={{ ...style }} />}
            {component === "EventlabMaatschappelijk" && <VolunteerActivismIcon fontSize={size} sx={{ ...style }} />}

            {/*EMPTY*/}
            {!component && <AccessibilityNewIcon fontSize={size} sx={{ ...style }} />}
        </>
    )
}
