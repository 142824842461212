import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CardHeader, MainBorderRadius } from '../../app/AppStyles';
import { updateLastUsedDepartment } from '../settings/themeSettingsSlice';

export default function DepartmentCard({ department }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, name, description, lastUsedDepartment, themeSettings } = department;
    const { primaryColor } = themeSettings;

    const departmentClick = () => {
        dispatch(updateLastUsedDepartment(id));
        navigate("/");
        navigate(0);
    };

    return (
        <Card sx={{
            bgcolor: primaryColor,
            height: 1,
            borderRadius: MainBorderRadius,
        }}>
            <CardActionArea sx={{ height: 1 }} onClick={() => departmentClick()}>
                <CardContent>
                    <Typography variant="h6" sx={{ ...CardHeader, color: lastUsedDepartment ? 'primary.dark' : 'white.main', textAlign: 'center' }}>{name}</Typography>
                    <Typography variant="subtitle1" sx={{ ...CardHeader, color: lastUsedDepartment ? 'primary.dark' : 'white.main', textAlign: 'center' }}>{description}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
