import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PlanningAccordionDisplay from './accordion/PlanningAccordionDisplay';
import { fetchNewPlanningActivities, fetchPlanningPeriods } from './planningSlice';

export default function PlanningPage() {
    const dispatch = useDispatch();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme)

    useEffect(() => {
        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

        dispatch(fetchNewPlanningActivities({ inProgram: true, departmentId: departmentId }));
        dispatch(fetchPlanningPeriods());
    }, [dispatch, activeDepartment]);

    return (
        <PlanningAccordionDisplay />
    );
}
