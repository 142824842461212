import { AppBar, Box, Button, IconButton, Menu, Toolbar, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import DepartmentPicker from '../department/DepartmentPicker';
import IconDisplay from '../subcomponents/IconDisplay';
import NavMenuItem from '../subcomponents/NavMenuItem';
import LoginMenu from './LoginMenu';

import MenuIcon from '@mui/icons-material/Menu';

export default function NavMenu() {
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState(null);

    const { activeThemeSettings } = useSelector((state) => state.settings.theme);
    const { navigation } = useSelector((state) => state.settings.resource.navigation);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { student } = useSelector((state) => state.student);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handlePathDirection = (path) => {
        const pathMap = {
            [resourceFinal[appLanguage].words.SCHEDULE]: '/agenda',
            [resourceFinal[appLanguage].words.REGISTER]: '/inschrijven',
            [resourceFinal[appLanguage].words.PROGRESS]: '/voortgang',
            [resourceFinal[appLanguage].words.CONTACT]: '/contact',
            [resourceFinal[appLanguage].words.NEWS]: '/nieuws',
            [resourceFinal[appLanguage].words.FAVORITES]: '/favorieten',
            [resourceFinal[appLanguage].words.INFORMATION]: '/informatie',
            [resourceFinal[appLanguage].words.TIMETABLE]: '/rooster',
            [resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT]: '/activiteitenbeheer',
            [resourceFinal[appLanguage].words.PROGRAM_MANAGEMENT]: '/programmabeheer',
            [resourceFinal[appLanguage].words.PLANNING]: '/plannen',
            [resourceFinal[appLanguage].words.WEB_MANAGEMENT]: '/webbeheer',
            [resourceFinal[appLanguage].words.REPORTS_PAGE]: '/rapporten',
            [resourceFinal[appLanguage].words.ADMIN_PAGE]: '/admin',
            [resourceFinal[appLanguage].words.HOME]: '/',
            [resourceFinal[appLanguage].words.ABOUT]: '/over',
            [resourceFinal[appLanguage].words.ATTENDANCE]: '/presentie',
            [resourceFinal[appLanguage].words.SEARCH_PAGE]: '/zoeken/student',
            [resourceFinal[appLanguage].words.MESSAGES_PAGE]: '/berichten',
            [resourceFinal[appLanguage].words.EMPLOYEE_PAGE]: '/medewerkers',
            [resourceFinal[appLanguage].words.TRAVEL_COSTS]: '/reiskosten'
        };
        
        return pathMap[path] || '/';
    };

    const mobileBgImage = { backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 50%, ${theme.palette.primary?.main} 50%)` }
    const bgImage = useMediaQuery('(min-width:900px)') ? '' : mobileBgImage;

    const NavLink = ({ path }) => {
        var toPath = handlePathDirection(path);

        return (
            <Link to={`${toPath}`} style={{ textDecoration: 'none' }}>
                <Button sx={{ display: 'block', fontWeight: 'bold', pr: 0.5 }} color="white" variant="text">{path}</Button>
            </Link>
        );
    };

    return (
        <AppBar position="static" sx={{ ...bgImage }}>
            <Toolbar disableGutters>
                {/* MD LOGO */}
                <Box onClick={() => navigate("/")} sx={{
                    display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer', px: 4, minWidth: 200,
                    backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 10%, ${theme.palette.white?.light} 10.5%, ${theme.palette.white?.light} 89.5%, ${theme.palette.primary?.main} 90%)`
                }}>
                    <img src={activeThemeSettings?.logo} alt="logo" style={{ maxWidth: 240, maxHeight: 60, objectFit: 'scale-down' }} />
                </Box>

                {/* MD MENU */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {navigation.map((item, index) => (
                        <NavLink path={item} key={index} />
                    ))}
                </Box>

                {siomSettings.useMultipleDepartments && student !== null ? <Box sx={{ display: { xs: 'none', md: 'flex' } }}><DepartmentPicker type={"compact"} /></Box> : ""}

                {/* XS MENU */}
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                    >
                        <MenuIcon fontSize="large" sx={{ color: 'white.main' }} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {navigation.map((item) => (
                            <NavMenuItem key={item} icon={<IconDisplay component={item} style={{ color: 'secondary.main' }} />} clickFn={() => {
                                handleCloseNavMenu();
                                navigate(handlePathDirection(item));
                            }} text={item} textStyle={{ color: 'secondary.light' }} />
                        ))}
                    </Menu>
                </Box>

                {/* XS LOGO */}
                <Box onClick={() => navigate("/")} sx={{
                    display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer', minWidth: 160, px: 4, mx: 'auto',
                    backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 10%, ${theme.palette.white?.light} 10.5%, ${theme.palette.white?.light} 89.5%, ${theme.palette.primary?.main} 90%)`
                }}>
                    <img src={activeThemeSettings?.logo} alt="logo" style={{ maxWidth: 168, maxHeight: 56, objectFit: 'scale-down' }} />
                </Box>

                {/* LOGIN */}
                <Box sx={{ display: 'flex', mr: 2 }}>
                    <LoginMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
};
