import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { getClasses, getSpecificClass, postClass, putClass, resetClassStatus, setFailedClass } from './classManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ClassEditCard({ handleClose, addNew, classId }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { putClassStatus, postClassStatus, selectedClass, showClassFailed } = useSelector((state) => state.management.class);

    const [changed, setChanged] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putClassStatus === "success") {
            dispatch(setFailedClass(false));
            dispatch(resetClassStatus());
            dispatch(getSpecificClass(classId));
            handleClose();
        } else if (putClassStatus === "failed") {
            dispatch(resetClassStatus());
            dispatch(setFailedClass(true));
        };
    }, [dispatch, handleClose, putClassStatus]);

    // Post status callback
    useEffect(() => {
        if (postClassStatus === "success") {
            dispatch(setFailedClass(false));
            dispatch(resetClassStatus());
            dispatch(getClasses(true));
            handleClose();
        } else if (postClassStatus === "failed") {
            dispatch(resetClassStatus());
            dispatch(setFailedClass(true));
        };
    }, [dispatch, handleClose, postClassStatus]);

    // Character limits
    const nameLength = 256;

    // Property states
    const [newClass, setNewClass] = useState({});
    const [errorName, setErrorName] = useState(null);

    // Selected period state update 
    useEffect(() => {
        if (!addNew && selectedClass) {
            setNewClass(selectedClass);
        }
    }, [selectedClass, addNew]);

    // Keep track of changed state 
    useEffect(() => {
        if (newClass !== selectedClass) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedClass, newClass]);

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        let tempObject = { ...newClass }; // Needed to circumvent weird read only property error

        if (!newClass.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newClass.name && newClass.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);

        setErrorName(null);

        tempObject.modifiedBy = `AP/${user.number}`;

        dispatch(putClass(tempObject));
    };

    // Add click function (check property character limits etc.)
    const addClick = () => {
        let tempObject = { ...newClass }; // Needed to circumvent weird read only property error

        if (!newClass.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        if (newClass.name && newClass.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_TOO_LONG} ${nameLength}`);

        setErrorName(null);

        tempObject.createdBy = `AP/${user.number}`;

        dispatch(postClass(tempObject));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                    <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.CLASS} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                    <TextField
                        fullWidth required
                        defaultValue={selectedClass?.name}
                        label={resourceFinal[appLanguage].words.NAME}
                        onChange={(event) => setNewClass({
                            ...newClass,
                            name: event.target.value
                        })}
                        error={errorName !== null}
                        helperText={errorName}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth multiline
                        defaultValue={selectedClass?.description}
                        label={resourceFinal[appLanguage].words.DESCRIPTION}
                        onChange={(event) => setNewClass({
                            ...newClass,
                            description: event.target.value
                        })}
                        variant="outlined"
                    />
                    <FormControlLabel
                        label={resourceFinal[appLanguage].words.LICENSED}
                        control={
                            <Checkbox
                                defaultChecked={selectedClass?.isLicensed}
                                onChange={(event) => setNewClass({
                                    ...newClass,
                                    isLicensed: event.target.checked
                                })}
                            />
                        }
                    />
                    {showClassFailed && <>
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                    </>}

                    <ButtonGroup sx={{ justifyContent: 'center' }}>
                        <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                        {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                        {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                    </ButtonGroup>
                </Stack>
            </Card>
        </Box>
    );
}
