import { Alert, IconButton, Snackbar } from '@mui/material';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlertMessage, setAlertSeverity, setAlertStatus } from './alertSlice';

import CloseIcon from '@mui/icons-material/Close';

export default function AlertDisplay() {
    const dispatch = useDispatch();

    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { alertStatus, alertMessage, alertSeverity } = useSelector((state) => state.alert);
    const { subscriptionStatus, performance } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        if (subscriptionStatus === 'success') {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(aspNetUser?.role?.includes("Student") ?
                `${resourceFinal[appLanguage].messages.ALERT_SUCCESS} ${performance?.activity.name}` : `${resourceFinal[appLanguage].messages.ALERT_SUCCESS_EMPLOYEE}`
            ));
            dispatch(setAlertSeverity("success"));
        } else if (subscriptionStatus === 'failed') {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(aspNetUser?.role?.includes("Student") ?
                `${resourceFinal[appLanguage].messages.ALERT_ERROR} ${performance?.activity.name}` : `${resourceFinal[appLanguage].messages.ALERT_ERROR_EMPLOYEE}`
            ));
            dispatch(setAlertSeverity("error"));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, subscriptionStatus]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        };

        dispatch(setAlertStatus(false));
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <Snackbar
            open={alertStatus}
            autoHideDuration={5000}
            onClose={handleClose}
            action={action}
        >
            <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
    );
}