import { alpha, Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../app/AppStyles';
import SearchField from '../subcomponents/SearchField';
import TablePaginationActions from '../subcomponents/TablePaginationActions';
import { setMsgReceivers, setMsgStudentFilter } from './messageSlice';

export default function MessagesStudents({ selectedStudents, setSelectedStudents }) {
    const dispatch = useDispatch();

    const { msgStudents, msgStudentsFilter } = useSelector((state) => state.messages);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        setPage(0);
    }, [msgStudents]);

    function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && (msgStudentsFilter ? numSelected < msgStudents?.filter(customFilterFunction).length : numSelected < rowCount)}
                            checked={rowCount > 0 && (msgStudentsFilter ? numSelected === msgStudents?.filter(customFilterFunction).length : numSelected === rowCount)}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all' }}
                        />
                    </TableCell>
                    <TableCell align="center" sx={{ ...TableHeader, width: 100 }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked && msgStudents) {
            const newSelected = msgStudents?.filter(customFilterFunction).map((n) => n);
            setSelectedStudents(newSelected);
            return;
        };
        setSelectedStudents([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedStudents.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedStudents, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedStudents.slice(1));
        } else if (selectedIndex === selectedStudents.length - 1) {
            newSelected = newSelected.concat(selectedStudents.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedStudents.slice(0, selectedIndex),
                selectedStudents.slice(selectedIndex + 1),
            );
        };

        setSelectedStudents(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - msgStudents?.length) : 0;

    var customFilterFunction = (item) => {
        return item.fullname?.toLowerCase().includes(msgStudentsFilter.toLowerCase()) || item.studentNumber?.toLowerCase().includes(msgStudentsFilter.toLowerCase());
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (studentId) => selectedStudents.findIndex(x => x.studentId === studentId) !== -1;

    const handleFillClick = () => {
        dispatch(setMsgReceivers(selectedStudents));
    };

    return (
        <Box sx={{ p: 0.5, ...ListItemBackground }}>
            <EnhancedTableToolbar numSelected={selectedStudents.length} fillFunc={handleFillClick} />
            <TableContainer>
                <Table>
                    <EnhancedTableHead numSelected={selectedStudents.length} onSelectAllClick={handleSelectAllClick} rowCount={msgStudents?.length} />
                    <TableBody>
                        {(rowsPerPage > 0 ? msgStudents?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : msgStudents?.filter(customFilterFunction))?.map((item, index) => {
                            const isItemSelected = isSelected(item.studentId);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <TableRow hover key={item.studentId} onClick={(event) => handleClick(event, item)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} selected={isItemSelected} sx={{ cursor: 'pointer' }}>
                                    <TableCell padding="checkbox">
                                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                    </TableCell>
                                    <TableCell align="center">{item.studentNumber}</TableCell>
                                    <TableCell align="left">{item.fullname}</TableCell>
                                </TableRow>
                            );
                        })}
                        {(rowsPerPage > 0 ? msgStudents?.filter(customFilterFunction)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : msgStudents?.filter(customFilterFunction))?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                        {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={4} /></TableRow>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{ border: 0 }}
                                rowsPerPageOptions={[10, 25, 50]}
                                count={msgStudents ? msgStudents.length : 0}
                                rowsPerPage={rowsPerPage} page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                                labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
}

function EnhancedTableToolbar({ numSelected, fillFunc }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { postMessageStatus } = useSelector((state) => state.messages);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dispatch(setMsgStudentFilter(value));
        }, 600);
    };

    useEffect(() => {
        if (postMessageStatus === "success")
            textRef.current.value = "";
    }, [dispatch, postMessageStatus]);

    return (
        <Toolbar disableGutters sx={{ p: 1, display: 'flex', justifyContent: 'space-between', ...(numSelected > 0 && { bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) }) }}>
            <Box sx={{ width: 130 }}>
                {numSelected > 0 ? (
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} {resourceFinal[appLanguage].words.SELECTED.toLowerCase()}
                    </Typography>
                ) : (
                    <Typography
                        sx={{ ...PageHeader, textAlign: 'left' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {resourceFinal[appLanguage].words.STUDENTS}
                    </Typography>
                )}
            </Box>
            <SearchField func={handleSearchChange} textRef={textRef} />
            {numSelected > 0 ? <Button variant="outlined" color="secondary" onClick={() => fillFunc()} sx={{ width: 130 }}>{resourceFinal[appLanguage].words.ADD}</Button> : <Box sx={{ width: 130 }}></Box>}
        </Toolbar>
    );
};