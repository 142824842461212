import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../../app/AppStyles';
import { DisplayName, GetDate, GetTime } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';

export default function UnsubscriptionsDisplay() {
    const { unsubscriptions, unsubscriptionsStatus } = useSelector((state) => state.subscription);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const SubscriptionsFragment = () => {
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(5);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - unsubscriptions.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };
        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? unsubscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : unsubscriptions).map(item =>
                        <TableRow key={item.id}>
                            <TableCell align="center">{item.student.number}</TableCell>
                            <TableCell align="left">{DisplayName(item.student.firstName, item.student.middleName, item.student.lastName)}</TableCell>
                            <TableCell align="center">{item.student.schoolclass}</TableCell>
                            <TableCell align="center">{item.createdBy}</TableCell>
                            <TableCell align="center">{GetDate(item.dateCreated)} {GetTime(item.dateCreated)}</TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? unsubscriptions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : unsubscriptions)?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 25]}
                            count={unsubscriptions ? unsubscriptions.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <ContentLoader successCondition={unsubscriptionsStatus === "success"} errorCondition={unsubscriptionsStatus === "failed"} content={
            <Box sx={{ p: 0.5, ...ListItemBackground }}>
                <Toolbar>
                    <Typography
                        sx={{ ...PageHeader }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {resourceFinal[appLanguage].words.UNSUBSCRIPTIONS}
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CLASS}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CREATED_BY}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DATE_CREATED}</TableCell>
                            </TableRow>
                        </TableHead>
                        <SubscriptionsFragment />
                    </Table>
                </TableContainer>
            </Box>
        } />
    );
}
