import { Box, Button, ButtonGroup, Card, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { HandleImageUpload } from '../../../app/AppUtils';
import { getLocations, postLocation, putLocation, resetLocationStatus, setFailedLocation } from './locationManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '20%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function LocationEditCard(props) {
    const { handleClose, addNew } = props;
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedLocation, putLocationStatus, postLocationStatus, showFailed } = useSelector((state) => state.management.location);
    const [changed, setChanged] = useState(false);

    // Put status callback
    useEffect(() => {
        if (putLocationStatus === "success") {
            dispatch(setFailedLocation(false));
            dispatch(resetLocationStatus());
            dispatch(getLocations());
            handleClose();
        } else if (putLocationStatus === "failed") {
            dispatch(resetLocationStatus());
            dispatch(setFailedLocation(true));
        };
    }, [dispatch, handleClose, putLocationStatus]);

    // Post status callback
    useEffect(() => {
        if (postLocationStatus === "success") {
            dispatch(setFailedLocation(false));
            dispatch(resetLocationStatus());
            dispatch(getLocations());
            handleClose();
        } else if (postLocationStatus === "failed") {
            dispatch(resetLocationStatus());
            dispatch(setFailedLocation(true));
        };
    }, [dispatch, handleClose, postLocationStatus]);

    // Character limits
    const nameLength = 128;
    const streetLength = 118;
    const houseLength = 10;
    const zipcodeLength = 16
    const placeLength = 128;
    const countryLength = 128;

    // Property states
    const [newName, setNewName] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [newStreet, setNewStreet] = useState(null);
    const [errorStreet, setErrorStreet] = useState(null);
    const [newHouse, setNewHouse] = useState(null);
    const [errorHouse, setErrorHouse] = useState(null);
    const [newZipcode, setNewZipcode] = useState(null);
    const [errorZipcode, setErrorZipcode] = useState(null);
    const [newPlace, setNewPlace] = useState(null);
    const [errorPlace, setErrorPlace] = useState(null);
    const [newCountry, setNewCountry] = useState(null);
    const [errorCountry, setErrorCountry] = useState(null);
    const [newPhone, setNewPhone] = useState(null);
    const [errorPhone, setErrorPhone] = useState(null);
    const [newEmail, setNewEmail] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [newDescription, setNewDescription] = useState(null);
    const [newImage, setNewImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [errorImage, setErrorImage] = useState(null);

    // Property change functions
    const handleNameChange = (event) => setNewName(event.target.value);
    const handleStreetChange = (event) => setNewStreet(event.target.value);
    const handleHouseChange = (event) => setNewHouse(event.target.value);
    const handleZipcodeChange = (event) => setNewZipcode(event.target.value);
    const handlePlaceChange = (event) => setNewPlace(event.target.value);
    const handleCountryChange = (event) => setNewCountry(event.target.value);
    const handlePhoneChange = (event) => setNewPhone(event.target.value);
    const handleEmailChange = (event) => setNewEmail(event.target.value);
    const handleDescriptionChange = (event) => setNewDescription(event.target.value);
    function handleImageChange(event) {
        setErrorImage(null);
        var imagePromise = HandleImageUpload(event);

        imagePromise.then(
            (value) => {
                setImageName(value?.name);
                setNewImage(value?.image);
            },
            (error) => {
                (error.message === "SIZE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_TOO_LARGE));
                (error.message === "TYPE" && setErrorImage(resourceFinal[appLanguage].messages.ERROR_FILE_NOT_IMAGE));
            });
    };

    // Selected location state update 
    useEffect(() => {
        if (!addNew) {
            setNewName(selectedLocation?.naam);
            setNewStreet(selectedLocation?.straatnaam);
            setNewHouse(selectedLocation?.huisnummer);
            setNewZipcode(selectedLocation?.postcode);
            setNewPlace(selectedLocation?.plaatsnaam);
            setNewCountry(selectedLocation?.land);
            setNewPhone(selectedLocation?.telefoonnummer);
            setNewEmail(selectedLocation?.email);
            setNewDescription(selectedLocation?.beschrijving);
            setNewImage(selectedLocation?.imageUrl);
        };
    }, [selectedLocation]);

    // Keep track of changed state 
    useEffect(() => {
        if (
            newName !== selectedLocation?.naam ||
            newStreet !== selectedLocation?.straatnaam ||
            newHouse !== selectedLocation?.huisnummer ||
            newZipcode !== selectedLocation?.postcode ||
            newPlace !== selectedLocation?.plaatsnaam ||
            newCountry !== selectedLocation?.land ||
            newPhone !== selectedLocation?.telefoonnummer ||
            newEmail !== selectedLocation?.email ||
            newDescription !== selectedLocation?.beschrijving ||
            newImage !== selectedLocation?.imageUrl
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedLocation, newName, newDescription, newStreet, newHouse, newZipcode, newPlace, newCountry, newPhone, newEmail, newImage]);

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (!newPlace) return setErrorPlace(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorPlace(null);

        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (newStreet && newStreet?.length > streetLength) return setErrorStreet(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${streetLength}`);
        setErrorStreet(null);

        if (newHouse && newHouse?.length > houseLength) return setErrorHouse(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${houseLength}`);
        setErrorHouse(null);

        if (newZipcode && newZipcode?.length > zipcodeLength) return setErrorZipcode(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${zipcodeLength}`);
        setErrorZipcode(null);

        if (newPlace && newPlace?.length > placeLength) return setErrorPlace(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${placeLength}`);
        setErrorPlace(null);

        if (newCountry && newCountry?.length > countryLength) return setErrorCountry(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${countryLength}`);
        setErrorCountry(null);

        if (newPhone && !/^(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/.test(newPhone)) return setErrorPhone(`${resourceFinal[appLanguage].messages.INVALID_PHONE}`);
        setErrorPhone(null);

        if (newEmail && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newEmail)) return setErrorEmail(`${resourceFinal[appLanguage].messages.INVALID_EMAIL}`);
        setErrorEmail(null);

        dispatch(putLocation({
            id: selectedLocation.id,
            naam: newName,
            beschrijving: newDescription,
            imageUrl: newImage,
            straatnaam: newStreet,
            huisnummer: newHouse,
            postcode: newZipcode,
            plaatsnaam: newPlace,
            land: newCountry,
            telefoonnummer: newPhone,
            email: newEmail,
            createdBy: selectedLocation.createdBy,
            dateCreated: selectedLocation.dateCreated,
            modifiedBy: `AP/${user.number}`,
            timestamp: selectedLocation.timestamp,
        }));
    };

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newName) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (!newPlace) return setErrorPlace(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorPlace(null);

        if (newName && newName?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (newStreet && newStreet?.length > streetLength) return setErrorStreet(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${streetLength}`);
        setErrorStreet(null);

        if (newHouse && newHouse?.length > houseLength) return setErrorHouse(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${houseLength}`);
        setErrorHouse(null);

        if (newZipcode && newZipcode?.length > zipcodeLength) return setErrorZipcode(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${zipcodeLength}`);
        setErrorZipcode(null);

        if (newPlace && newPlace?.length > placeLength) return setErrorPlace(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${placeLength}`);
        setErrorPlace(null);

        if (newCountry && newCountry?.length > countryLength) return setErrorCountry(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${countryLength}`);
        setErrorCountry(null);

        if (newPhone && !/^(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/.test(newPhone)) return setErrorPhone(`${resourceFinal[appLanguage].messages.INVALID_PHONE}`);
        setErrorPhone(null);

        if (newEmail && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newEmail)) return setErrorEmail(`${resourceFinal[appLanguage].messages.INVALID_EMAIL}`);
        setErrorEmail(null);

        dispatch(postLocation({
            naam: newName,
            beschrijving: newDescription,
            imageUrl: newImage,
            straatnaam: newStreet,
            huisnummer: newHouse,
            postcode: newZipcode,
            plaatsnaam: newPlace,
            land: newCountry,
            telefoonnummer: newPhone,
            email: newEmail,
            createdBy: `AP/${user.number}`,
        }));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Stack spacing={1} sx={{ flex: '1 1 auto' }}>
                        <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.LOCATION} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        <TextField
                            fullWidth required
                            defaultValue={selectedLocation?.naam}
                            label={resourceFinal[appLanguage].words.NAME}
                            onChange={handleNameChange}
                            error={errorName !== null}
                            helperText={errorName}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth multiline
                            defaultValue={selectedLocation?.beschrijving}
                            label={resourceFinal[appLanguage].words.DESCRIPTION}
                            onChange={handleDescriptionChange}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.straatnaam}
                            label={resourceFinal[appLanguage].words.LOCATION_STREET}
                            onChange={handleStreetChange}
                            error={errorStreet !== null}
                            helperText={errorStreet}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.huisnummer}
                            label={resourceFinal[appLanguage].words.LOCATION_HOUSENUMBER}
                            onChange={handleHouseChange}
                            error={errorHouse !== null}
                            helperText={errorHouse}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.postcode}
                            label={resourceFinal[appLanguage].words.LOCATION_ZIPCODE}
                            onChange={handleZipcodeChange}
                            error={errorZipcode !== null}
                            helperText={errorZipcode}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth required
                            defaultValue={selectedLocation?.plaatsnaam}
                            label={resourceFinal[appLanguage].words.LOCATION_PLACE}
                            onChange={handlePlaceChange}
                            error={errorPlace !== null}
                            helperText={errorPlace}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.land}
                            label={resourceFinal[appLanguage].words.LOCATION_COUNTRY}
                            onChange={handleCountryChange}
                            error={errorCountry !== null}
                            helperText={errorCountry}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.telefoonnummer}
                            label={resourceFinal[appLanguage].words.PHONENUMBER}
                            onChange={handlePhoneChange}
                            error={errorPhone !== null}
                            helperText={errorPhone}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            defaultValue={selectedLocation?.email}
                            label={resourceFinal[appLanguage].words.EMAIL}
                            onChange={handleEmailChange}
                            error={errorEmail !== null}
                            helperText={errorEmail}
                            variant="outlined"
                        />
                        <Button
                            sx={{ width: 1, height: 1 }}
                            variant="outlined"
                            component="label"
                        >
                            {imageName ? imageName : resourceFinal[appLanguage].words.IMAGE}
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                                hidden
                            />
                        </Button>
                        {showFailed && <>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                        </>}
                        <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{errorImage}</Typography>
                        <ButtonGroup sx={{ justifyContent: 'center' }}>
                            <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                            {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                            {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                        </ButtonGroup>
                    </Stack>
                </Scrollbars>
            </Card>
        </Box>
    );
}
