import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postNotification } from "./notificationSlice";

export default function NotificationsTestButton({ disabled, recipient, message }) {
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    function handleSendClick() {
        dispatch(postNotification({ studentId: recipient, message: message }));
    };

    return (
        <Button disabled={disabled} variant="outlined" onClick={() => handleSendClick()}>
            {resourceFinal[appLanguage].words.TEST} {resourceFinal[appLanguage].words.SEND2}
        </Button>
    );
}