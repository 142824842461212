import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchTravelCostDeclerationOptions = createAsyncThunk(
    '/api/travelcost/options',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost/options`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDeclaredTravelCosts = createAsyncThunk(
    '/api/travelcost/declared',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost/declared`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDeclarationsForEmployee = createAsyncThunk(
    '/api/travelcost/employee',
    async (approved, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const apiUrl = approved !== null ? `api/travelcost/employee/${approved}` : `api/travelcost/employee`;
        const response = await fetch(window.Config.apiUrl + apiUrl, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDeclaration = createAsyncThunk(
    '/api/travelcost/declarationId',
    async (declarationId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost/${declarationId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postDeclaration = createAsyncThunk(
    '/api/travelcost/post',
    async (newDeclaration, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newDeclaration)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putDeclaration = createAsyncThunk(
    '/api/travelcost/edit',
    async (updatedDeclaration, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost/edit`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedDeclaration)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const declarationJudgment = createAsyncThunk(
    '/api/travelcost/judge',
    async (declarationJudgement, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/travelcost/judge`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(declarationJudgement)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const travelCostSlice = createSlice({
    name: 'travel cost',
    initialState: {
        travelCostOptions: null,
        travelCostOptionsStatus: null,
        declaredTravelCosts: null,
        declaredTravelCostsStatus: null,
        studentDeclarations: null,
        studentDeclarationsStatus: null,
        getDeclarationStatus: null,
        postDeclarationStatus: null,
        putDeclarationStatus: null,
        declarationJudgementStatus: null,
        selectedDeclaration: null,
        showFailed: false,
    },
    reducers: {
        selectDeclaration: (state, action) => {
            state.selectedDeclaration = action.payload
            state.getDeclarationStatus = null
        },
        setFailedDeclaration: (state, action) => {
            state.showFailed = action.payload;
        },
        resetPostDeclarationStatus: (state) => {
            state.postDeclarationStatus = null;
            state.selectedDeclaration = null;
        },
        resetPutDeclarationStatus: (state) => {
            state.putDeclarationStatus = null;
            state.selectedDeclaration = null;
        },
        resetJudgementStatus: (state) => {
            state.declarationJudgementStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Fetch Travel Cost Declaration Options
        builder
            .addCase(fetchTravelCostDeclerationOptions.pending, (state) => {
                state.travelCostOptionsStatus = 'loading';
            })
            .addCase(fetchTravelCostDeclerationOptions.fulfilled, (state, action) => {
                state.travelCostOptionsStatus = 'success';
                state.travelCostOptions = action.payload;
            })
            .addCase(fetchTravelCostDeclerationOptions.rejected, (state) => {
                state.travelCostOptionsStatus = 'failed';
            });

        // Fetch Declared Travel Costs
        builder
            .addCase(fetchDeclaredTravelCosts.pending, (state) => {
                state.declaredTravelCostsStatus = 'loading';
            })
            .addCase(fetchDeclaredTravelCosts.fulfilled, (state, action) => {
                state.declaredTravelCostsStatus = 'success';
                state.declaredTravelCosts = action.payload;
            })
            .addCase(fetchDeclaredTravelCosts.rejected, (state) => {
                state.declaredTravelCostsStatus = 'failed';
            });

        // Fetch Declarations for Employee
        builder
            .addCase(fetchDeclarationsForEmployee.pending, (state) => {
                state.studentDeclarationsStatus = 'loading';
            })
            .addCase(fetchDeclarationsForEmployee.fulfilled, (state, action) => {
                state.studentDeclarationsStatus = 'success';
                state.studentDeclarations = action.payload;
            })
            .addCase(fetchDeclarationsForEmployee.rejected, (state) => {
                state.studentDeclarationsStatus = 'failed';
            });

        // Get Declaration
        builder
            .addCase(fetchDeclaration.pending, (state) => {
                state.getDeclarationStatus = 'loading';
            })
            .addCase(fetchDeclaration.fulfilled, (state, action) => {
                state.getDeclarationStatus = 'success';
                state.selectedDeclaration = action.payload;
            })
            .addCase(fetchDeclaration.rejected, (state) => {
                state.getDeclarationStatus = 'failed';
            });

        // Post Declaration
        builder
            .addCase(postDeclaration.pending, (state) => {
                state.putDeclarationStatus = 'loading';
            })
            .addCase(postDeclaration.fulfilled, (state) => {
                state.putDeclarationStatus = 'success';
            })
            .addCase(postDeclaration.rejected, (state) => {
                state.putDeclarationStatus = 'failed';
            });

        // Put Declaration
        builder
            .addCase(putDeclaration.pending, (state) => {
                state.postDeclarationStatus = 'loading';
            })
            .addCase(putDeclaration.fulfilled, (state) => {
                state.postDeclarationStatus = 'success';
            })
            .addCase(putDeclaration.rejected, (state) => {
                state.postDeclarationStatus = 'failed';
            });

        // Put Declare Object
        builder
            .addCase(declarationJudgment.pending, (state) => {
                state.declarationJudgementStatus = 'loading';
            })
            .addCase(declarationJudgment.fulfilled, (state) => {
                state.declarationJudgementStatus = 'success';
            })
            .addCase(declarationJudgment.rejected, (state) => {
                state.declarationJudgementStatus = 'failed';
            });
    }
});

export const {
    resetJudgementStatus,
    resetPostDeclarationStatus,
    resetPutDeclarationStatus,
    selectDeclaration,
    setFailedDeclaration
} = travelCostSlice.actions;

export default travelCostSlice.reducer;