import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

// SUBPROGRAMS
export const getSubprograms = createAsyncThunk(
    '/api/programManagement/subprograms',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/subprograms`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putSubprogram = createAsyncThunk(
    "/api/programManagement/subprograms/put",
    async (updatedSubprogram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/subprograms",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedSubprogram),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const postSubprogram = createAsyncThunk(
    "/api/programManagement/subprograms/post",
    async (newSubprogram, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/subprograms",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newSubprogram),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const deleteSubprogram = createAsyncThunk(
    "/api/programManagement/subprograms/delete",
    async (subprogramId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/programManagement/subprograms/${subprogramId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

// SUBPROGRAM ACTIVITIES
export const getAvailableActivities = createAsyncThunk(
    '/api/programManagement/activities',
    async (subprogramId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/activities/${subprogramId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const getSubprogramActivities = createAsyncThunk(
    '/api/programManagement/SubprogramActivities/subprogramId',
    async (subprogramId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/programManagement/subprogramActivities/${subprogramId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putSubprogramActivity = createAsyncThunk(
    "/api/programManagement/subprogramActivity/put",
    async (updatedSubprogramActivities, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/subprogramActivities",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedSubprogramActivities),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const postSubprogramActivity = createAsyncThunk(
    "/api/programManagement/subprogramActivity/post",
    async (newSubprogramActivities, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/programManagement/subprogramActivities",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newSubprogramActivities),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const deleteSubprogramActivity = createAsyncThunk(
    "/api/programManagement/subprogramActivity/delete",
    async (subprogramActivityIds, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/programManagement/subprogramActivities`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
                body: JSON.stringify(subprogramActivityIds),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const subprogramManagementSlice = createSlice({
    name: 'subprogramManagement',
    initialState: {
        // SUBPROGRAMS
        subprograms: null,
        subprogramsStatus: null,
        putSubprogramStatus: null,
        postSubprogramStatus: null,
        deleteSubprogramStatus: null,
        selectedSubprogram: null,
        showSubprogramFailed: false,

        // ACTIVITIES
        availableActivities: null,
        availableActivitiesStatus: null,

        // SUBPROGRAMS
        subprogramActivities: null,
        subprogramActivitiesStatus: null,
        putSubprogramActivitiesStatus: null,
        postSubprogramActivitiesStatus: null,
        deleteSubprogramActivitiesStatus: null,
        selectedSubprogramActivity: null,
        showSubprogramActivitiesFailed: false,
    },
    reducers: {
        selectSubprogram: (state, action) => {
            state.selectedSubprogram = action.payload;
        },
        setFailedSubprogram: (state, action) => {
            state.showSubprogramFailed = action.payload;
        },
        resetSubprogramStatus: (state) => {
            state.putSubprogramStatus = null;
            state.postSubprogramStatus = null;
            state.deleteSubprogramStatus = null;
        },
        selectSubprogramActivity: (state, action) => {
            state.selectedSubprogramActivity = action.payload;
        },
        setFailedSubprogramActivity: (state, action) => {
            state.showSubprogramActivitiesFailed = action.payload;
        },
        resetSubprogramActivityStatus: (state) => {
            state.putSubprogramActivitiesStatus = null;
            state.postSubprogramActivitiesStatus = null;
            state.deleteSubprogramActivitiesStatus = null;
        },
    },
    extraReducers: (builder) => {
        // SUBPROGRAMS
        builder // GET
            .addCase(getSubprograms.pending, (state) => {
                state.subprogramsStatus = 'loading';
            })
            .addCase(getSubprograms.fulfilled, (state, action) => {
                state.subprogramsStatus = 'success';
                state.subprograms = action.payload;
            })
            .addCase(getSubprograms.rejected, (state) => {
                state.subprogramsStatus = 'failed';
            });

        builder // PUT
            .addCase(putSubprogram.pending, (state) => {
                state.putSubprogramStatus = 'loading';
            })
            .addCase(putSubprogram.fulfilled, (state) => {
                state.putSubprogramStatus = 'success';
            })
            .addCase(putSubprogram.rejected, (state) => {
                state.putSubprogramStatus = 'failed';
            });

        builder // POST
            .addCase(postSubprogram.pending, (state) => {
                state.postSubprogramStatus = 'loading';
            })
            .addCase(postSubprogram.fulfilled, (state) => {
                state.postSubprogramStatus = 'success';
            })
            .addCase(postSubprogram.rejected, (state) => {
                state.postSubprogramStatus = 'failed';
            });

        builder // DELETE
            .addCase(deleteSubprogram.pending, (state) => {
                state.deleteSubprogramStatus = 'loading';
            })
            .addCase(deleteSubprogram.fulfilled, (state) => {
                state.deleteSubprogramStatus = 'success';
            })
            .addCase(deleteSubprogram.rejected, (state) => {
                state.deleteSubprogramStatus = 'failed';
            });

        // ACTIVITIES
        builder // GET
            .addCase(getAvailableActivities.pending, (state) => {
                state.availableActivitiesStatus = 'loading';
            })
            .addCase(getAvailableActivities.fulfilled, (state, action) => {
                state.availableActivitiesStatus = 'success';
                state.availableActivities = action.payload;
            })
            .addCase(getAvailableActivities.rejected, (state) => {
                state.availableActivitiesStatus = 'failed';
            });

        // SUBPROGRAM ACTIVITIES
        builder // GET
            .addCase(getSubprogramActivities.pending, (state) => {
                state.subprogramActivitiesStatus = 'loading';
            })
            .addCase(getSubprogramActivities.fulfilled, (state, action) => {
                state.subprogramActivitiesStatus = 'success';
                state.subprogramActivities = action.payload;
            })
            .addCase(getSubprogramActivities.rejected, (state) => {
                state.subprogramActivitiesStatus = 'failed';
            });

        builder // PUT
            .addCase(putSubprogramActivity.pending, (state) => {
                state.putSubprogramActivitiesStatus = 'loading';
            })
            .addCase(putSubprogramActivity.fulfilled, (state) => {
                state.putSubprogramActivitiesStatus = 'success';
            })
            .addCase(putSubprogramActivity.rejected, (state) => {
                state.putSubprogramActivitiesStatus = 'failed';
            });

        builder // POST
            .addCase(postSubprogramActivity.pending, (state) => {
                state.postSubprogramActivitiesStatus = 'loading';
            })
            .addCase(postSubprogramActivity.fulfilled, (state) => {
                state.postSubprogramActivitiesStatus = 'success';
            })
            .addCase(postSubprogramActivity.rejected, (state) => {
                state.postSubprogramActivitiesStatus = 'failed';
            });

        builder // DELETE
            .addCase(deleteSubprogramActivity.pending, (state) => {
                state.deleteSubprogramActivitiesStatus = 'loading';
            })
            .addCase(deleteSubprogramActivity.fulfilled, (state) => {
                state.deleteSubprogramActivitiesStatus = 'success';
            })
            .addCase(deleteSubprogramActivity.rejected, (state) => {
                state.deleteSubprogramActivitiesStatus = 'failed';
            });
    },
});

export const {
    selectSubprogram,
    setFailedSubprogram,
    resetSubprogramStatus,
    selectSubprogramActivity,
    setFailedSubprogramActivity,
    resetSubprogramActivityStatus
} = subprogramManagementSlice.actions;

export default subprogramManagementSlice.reducer;
