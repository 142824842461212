export const requestOptions = {
    headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
    }),
    method: 'GET',
    redirect: 'follow',
};

export const AddZero = (input) => {
    return (input < 10 ? "0" : "") + input;
};

export const GetDate = (input) => {
    var date = new Date(input);
    var result = date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return result;
};

export const GetDateLong = (input) => {
    var date = new Date(input);
    var result = date.toLocaleDateString("nl-NL", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "short",
    });

    return result;
};

export const GetTime = (input) => {
    const date = new Date(input);

    return `${AddZero(date.getHours())}:${AddZero(date.getMinutes())}`;
};

export const GetDayName = (input) => {
    const date = new Date(input);
    var result = date.toLocaleDateString("nl-NL", { weekday: 'long' });

    return result;
};

export const GetMonthName = (input) => {
    const date = new Date(input);
    var result = date.toLocaleDateString("nl-NL", { month: 'long' });

    return result;
};

export const GetYear = (input) => {
    const date = new Date(input);
    var result = date.toLocaleDateString("nl-NL", { year: 'numeric' });

    return result;
};

export const FilterLoop = (fn, a) => {
    const f = [];

    for (let i = 0; i < a.length; i++) {
        if (fn(a[i])) {
            f.push(a[i]);
        }
    }

    return f;
};

export const GroupBy = (data, key) => {
    return data.reduce(function (storage, item) {
        var group = item[key];
        storage[group] = storage[group] || [];
        storage[group].push(item);

        return storage;
    }, {});
};

export const DynamicSort = (property) => {
    var sortOrder = 1;

    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    };

    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    };
};

export const DisplayName = (first, ins, last) => {
    return `${first} ${ins ? ins + " " + last : last}`;
};

export const HandleImageUpload = (event) => {
    var name;
    var file = event.target.files[0];
    name = file.name;

    return new Promise((resolve, reject) => {
        // Check file type
        if (!file.type.match('image.*')) {
            console.log("promise here")
            reject(new Error("TYPE"));
        };

        // Check file size
        console.log("File size:", `${(file.size / (1024 * 1024)).toFixed(2)}MB`)
        if ((file.size / (1024 * 1024)).toFixed(2) > 8) { // 8MB limit
            reject(new Error("SIZE"));
        };

        // Upload file
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            var img = new Image();
            img.src = reader.result;

            img.onload = function () {
                resolve({ image: img.src, name: name });
            };

            img.onerror = function (err) {
                reject(new Error(err));
            };
        };
    });
};

export const ConvertoToCSharpDateTime = (input) => {
    return `${AddZero(input.$y)}-${AddZero(Number(input.$M) + 1)}-${AddZero(input.$D)}T${AddZero(input.$H)}:${AddZero(input.$m)}:${AddZero(input.$s)}`
};