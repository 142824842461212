import { useSelector } from 'react-redux';
import ContentLoader from '../subcomponents/ContentLoader';

export default function VersionSwitcher({ content1, content2 }) {
    const { appSettings, appSettingsStatus } = useSelector((state) => state.settings.app);

    return (
        <ContentLoader successCondition={appSettingsStatus === "success"} errorCondition={appSettingsStatus === "failed"} content={
            <>
                {appSettings?.appVersion === 1 ?
                    content1
                    : appSettings?.appVersion === 2 ?
                        content2
                        : ""}
            </>
        } />
    );
}
