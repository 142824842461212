import { Card } from '@mui/material';
import { useSelector } from "react-redux";
import { CardMedium } from '../../../../app/AppStyles';
import ContentLoader from '../../../subcomponents/ContentLoader';
import TimetableDisplay from '../../../timetable/TimetableDisplay';

export default function SearchStudentTimetableTab() {
    const { searchStudentTimetable, searchStudentTimetableStatus } = useSelector((state) => state.search.student);

    return (
        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
            <ContentLoader successCondition={searchStudentTimetableStatus === 'success'} errorCondition={searchStudentTimetableStatus === 'failed'} content={
                <TimetableDisplay timetable={searchStudentTimetable} employeeMode />
            } />
        </Card>
    );
}
