import { Button, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardHeader } from '../../app/AppStyles';
import NotificationsTestButton from '../notifications/NotificationsTestButton';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { moveImagesToAzure } from './adminSlice';

export default function AdminFunctionsTab() {
    const dispatch = useDispatch();
    const [recipient, setRecipient] = useState("");
    const [message, setMessage] = useState("Test message");
    const [imageType, setImageType] = useState("");
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { moveImagesToAzureStatus } = useSelector((state) => state.admin);

    // Put Callback
    useEffect(() => {
        if (moveImagesToAzureStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.AZURE_IMAGES_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
        } else if (moveImagesToAzureStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.AZURE_IMAGES_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
        };
    }, [moveImagesToAzureStatus]);
    const handleMoveImagestoAzure = (imageType) => {
        dispatch(moveImagesToAzure(imageType));

    };
    return (
        <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ p: 1, border: 1, borderRadius: 1, borderColor: 'white.dark' }}>
                    <Typography variant="body1" sx={{ ...CardHeader, color: 'black.light' }}>
                        {resourceFinal[appLanguage].words.TEST} {resourceFinal[appLanguage].words.NOTIFICATION} {resourceFinal[appLanguage].words.SEND2}
                    </Typography>
                    <TextField
                        fullWidth required
                        value={recipient}
                        label={resourceFinal[appLanguage].words.MESSAGE_RECEIVER}
                        onChange={(e) => setRecipient(e.target.value)}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth required
                        value={message}
                        label={resourceFinal[appLanguage].words.MESSAGE}
                        onChange={(e) => setMessage(e.target.value)}
                        variant="outlined"
                    />
                    <NotificationsTestButton disabled={!recipient || !message} recipient={recipient} message={message} />
                </Stack>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
                <Stack spacing={1} sx={{ p: 1, border: 1, borderRadius: 1, borderColor: 'white.dark' }}>
                    <Typography variant="body1" sx={{ ...CardHeader, color: 'black.light' }}>
                        {resourceFinal[appLanguage].words.AZURE_MIGRATION}
                    </Typography>
                    <TextField
                        fullWidth required
                        value={imageType}
                        label={resourceFinal[appLanguage].words.AZURE_IMAGETYPE}
                        onChange={(e) => setImageType(e.target.value)}
                        variant="outlined"
                    />
                    <Button
                        variant="outlined"
                        disabled={!imageType}
                        onClick={() => handleMoveImagestoAzure(imageType)}
                    >
                        {resourceFinal[appLanguage].words.SEND}
                    </Button>
                </Stack>
            </Grid>
        </Grid>

    );
}
