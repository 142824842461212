import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CardLarge } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import ScheduleDetails from './ScheduleDetails';
import { fetchSchedule, setMomentDetails } from './scheduleSlice';

export default function ScheduleDetailsPage() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { schedule, scheduleStatus, momentDetails } = useSelector((state) => state.schedule);

    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchSchedule());
        };
    }, [dispatch, aspNetUser]);

    useEffect(() => {
        if (scheduleStatus === "success") {
            dispatch(setMomentDetails(schedule.find((element) => element.momentId === id)));
        };
    }, [dispatch, scheduleStatus, id, schedule]);

    return (
        <PageLayout style={{ backgroundColor: 'primary.light' }} content={
            <ContentLoader successCondition={momentDetails !== null} errorCondition={''} content={
                <Box sx={{ ...CardLarge, alignItems: 'center' }}>
                    <ScheduleDetails />
                </Box>
            } />
        } bottomBar />
    );
}