import { Box, useMediaQuery } from '@mui/material';
import { HomeTile } from '../../app/AppStyles';
import ScheduleDisplayAlt from './ScheduleDisplayAlt';

export default function ScheduleTile() {
    const desktop = useMediaQuery('(min-width:900px)');

    return (
        <>
            {desktop ?
                <Box sx={{ ...HomeTile, display: 'flex', flex: '1 1 auto', width: 1 }}>
                    <ScheduleDisplayAlt />
                </Box>
                :
                <Box sx={{ ...HomeTile, display: 'flex', flex: '1 1 auto', width: 1 }}>
                    <ScheduleDisplayAlt />
                </Box>
            }
        </>
    );
}
