import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchUserProgress = createAsyncThunk(
    '/api/progress/userProgress',
    async ({ departmentId, fullProgress }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/progress/userProgress/${fullProgress}/${departmentId}` : `api/progress/userProgress/${fullProgress}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchProgressOverview = createAsyncThunk(
    '/api/progress/overview',
    async ({ departmentIds, fullProgress }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = `api/Progress/UserProgress`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({
                departmentIds: departmentIds, fullProgress: fullProgress
            }),
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        };
    }
);

export const progressSlice = createSlice({
    name: 'progress',
    initialState: {
        userProgress: null,
        userProgressStatus: null,
        progressOverview: null,
        progressOverviewStatus: null,
        activitySubscriptionDetails: null
    },
    reducers: {
        setActivitySubscriptionDetails: (state, action) => {
            state.activitySubscriptionDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        // UserProgress
        builder
            .addCase(fetchUserProgress.pending, (state) => {
                state.userProgressStatus = 'loading';
            })
            .addCase(fetchUserProgress.fulfilled, (state, action) => {
                state.userProgress = action.payload;
                state.userProgressStatus = 'success';
            })
            .addCase(fetchUserProgress.rejected, (state, action) => {
                state.userProgressStatus = 'failed';
                state.userProgress = action.payload;
            });

        // Progress Overview
        builder
            .addCase(fetchProgressOverview.pending, (state) => {
                state.progressOverviewStatus = 'loading';
            })
            .addCase(fetchProgressOverview.fulfilled, (state, action) => {
                state.progressOverview = action.payload;
                state.progressOverviewStatus = 'success';
            })
            .addCase(fetchProgressOverview.rejected, (state) => {
                state.progressOverviewStatus = 'failed';
            });
    }
});

export const { setActivitySubscriptionDetails } = progressSlice.actions;

export default progressSlice.reducer;