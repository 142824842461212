import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchNews = createAsyncThunk(
    '/api/news',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/news`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchArticle = createAsyncThunk(
    '/api/news/article',
    async (newsId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/news/article/${newsId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchCount = createAsyncThunk(
    '/api/news/count',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/news/count`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchNewNewsArticles = createAsyncThunk(
    '/api/news/new',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/news/new`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const addNewsUser = createAsyncThunk(
    '/api/news/post',
    async (newsId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/news/${newsId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
        })

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const newsSlice = createSlice({
    name: 'news',
    initialState: {
        news: null,
        newsStatus: null,
        article: null,
        articleStatus: null,
        newsCount: 0,
        countStatus: null,
        newArticles: null,
        newArticlesStatus: null,
        newsUserStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Fetch News
        builder
            .addCase(fetchNews.pending, (state) => {
                state.newsStatus = 'loading';
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.news = action.payload;
                state.newsStatus = 'success';
            })
            .addCase(fetchNews.rejected, (state) => {
                state.newsStatus = 'failed';
            });

        // Fetch Article
        builder
            .addCase(fetchArticle.pending, (state) => {
                state.articleStatus = 'loading';
            })
            .addCase(fetchArticle.fulfilled, (state, action) => {
                state.article = action.payload;
                state.articleStatus = 'success';
            })
            .addCase(fetchArticle.rejected, (state) => {
                state.articleStatus = 'failed';
            });

        // Fetch Count
        builder
            .addCase(fetchCount.pending, (state) => {
                state.countStatus = 'loading';
            })
            .addCase(fetchCount.fulfilled, (state, action) => {
                state.newsCount = action.payload;
                state.countStatus = 'success';
            })
            .addCase(fetchCount.rejected, (state) => {
                state.countStatus = 'failed';
            });

        // Fetch NewArticles
        builder
            .addCase(fetchNewNewsArticles.pending, (state) => {
                state.newArticlesStatus = 'loading';
            })
            .addCase(fetchNewNewsArticles.fulfilled, (state, action) => {
                state.newArticles = action.payload;
                state.newArticlesStatus = 'success';
            })
            .addCase(fetchNewNewsArticles.rejected, (state) => {
                state.newArticlesStatus = 'failed';
            });

        // Add NewsUser
        builder
            .addCase(addNewsUser.pending, (state) => {
                state.newsUserStatus = 'loading';
            })
            .addCase(addNewsUser.fulfilled, (state) => {
                state.newsUserStatus = 'success';
            })
            .addCase(addNewsUser.rejected, (state) => {
                state.newsUserStatus = 'failed';
            });
    }

});

//export const { } = newsSlice.actions;

export default newsSlice.reducer;