import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchFavorites } from '../favorites/favoritesSlice';
import { fetchUserProgress } from '../progress/progressSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import FilterButton from '../subcomponents/FilterButton';
import FilterSidebar from '../subcomponents/FilterSidebar';
import NoContentDisplay from '../subcomponents/NoContentDisplay';
import PageLayout from '../subcomponents/PageLayout';
import WarningDisplay from '../subcomponents/WarningDisplay';
import RegisterCard from './RegisterCard';
import { fetchRegister, setAllPeriodChecks, setCheckedPeriod, setParentCheck, setPeriodsList } from './registerSlice';

export default function RegisterPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { register, registerStatus, enrollStatus, deleteStatus, periods, parentCheck } = useSelector((state) => state.register);
    const { userProgress, userProgressStatus } = useSelector((state) => state.progress);
    const { favorites, favoritesStatus } = useSelector((state) => state.favorites);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { student } = useSelector((state) => state.student);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);

    const [filter, setFilter] = useState("");
    const [registerFavorite, setRegisterFavorite] = useState();
    const [registerNoFavorite, setRegisterNoFavorite] = useState();

    useEffect(() => {
        if (student !== null && userProgressStatus !== "success" && registerStatus !== "success") {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchUserProgress({ departmentId: activeDepartment[0].id, fullProgress: false }))
                };
            } else {
                dispatch(fetchUserProgress({ departmentId: null, fullProgress: false }))
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, student, activeDepartment, deleteStatus, departmentThemesStatus, siomSettings]);

    useEffect(() => {
        if (student !== null && userProgressStatus === "success" && registerStatus !== "success") {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchRegister(activeDepartment[0].id));
                    dispatch(fetchFavorites(activeDepartment[0].id));
                };
            } else {
                dispatch(fetchRegister(null));
                dispatch(fetchFavorites(null));
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, student, enrollStatus, deleteStatus, userProgressStatus]);

    useEffect(() => {
        if (registerStatus === "success") {
            setRegisterFavorite(register?.filter((item) => item.isFavorite === true));
            setRegisterNoFavorite(register?.filter((item) => item.isFavorite === false));
            dispatch(setPeriodsList(filterUniquePeriods(register)));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerStatus]);

    const filterUniquePeriods = (registerActivities) => {
        const allPeriods = registerActivities.flatMap(item => item.periods);
        const uniquePeriods = new Map();

        allPeriods.forEach((item) => {
            const key = JSON.stringify(item.name);
            if (!uniquePeriods.has(key)) {
                uniquePeriods.set(key, item);
            }
        });

        const uniquePeriodsArray = Array.from(uniquePeriods.values());
        uniquePeriodsArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

        return uniquePeriodsArray;
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleHomeClick = () => {
        navigate("/");
    };

    var customFilterFunction = (item) => {
        return item.activityName?.toLowerCase().includes(filter.toLowerCase()) &&
            periods?.filter(e1 => item.activityPerformances.some(e2 => e1.periodId === e2.periode.periodId)).some((e) => e.checked);
    };

    const FilterFragment = () => {
        return (
            <>
                {registerStatus === "success" && favoritesStatus === "success" ?
                    <Box>
                        <FormControlLabel
                            label={resourceFinal[appLanguage].words.PERIODS}
                            control={
                                <Checkbox
                                    checked={parentCheck}
                                    onChange={
                                        () => {
                                            dispatch(setParentCheck())
                                            periods.forEach((item) => {
                                                dispatch(setAllPeriodChecks({ id: item.periodId, bool: !parentCheck }));
                                            });
                                        }
                                    }
                                />
                            } />
                        <FormGroup sx={{ width: 1, alignSelf: 'center' }}>
                            {periods?.map((item) => <PeriodCheckbox key={item.periodId} item={item} />)}
                        </FormGroup>
                    </Box> : ""}
            </>
        );
    };

    const PeriodCheckbox = ({ item }) => {
        return (
            <FormControlLabel sx={{ alignSelf: 'left', ml: 2 }} control={<Checkbox checked={item.checked} onChange={() => {
                dispatch(setCheckedPeriod(item.periodId))
            }} />} label={item.name} />
        );
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.REGISTER} content={
            <>
                <ContentLoader successCondition={userProgressStatus === "success"} errorCondition={userProgressStatus === "failed"} customError={
                    <>
                        {userProgressStatus === "failed" &&
                            <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                                <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_GET_USER_FAILED}</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography variant="h6"><Button variant="text" onClick={handleHomeClick} sx={{ p: 0 }}>{resourceFinal[appLanguage].messages.ERROR_RETURN_LINK}</Button> {resourceFinal[appLanguage].messages.ERROR_RETURN_MESSAGE}</Typography>
                                </Box>
                            </Box>}
                    </>
                } content={
                    <>
                        {userProgress?.allPointLimitsReached ?
                            <Typography sx={{ textAlign: 'center' }} variant="h6">{resourceFinal[appLanguage].messages.REGISTER_LIMIT}</Typography>
                            :
                            <ContentLoader successCondition={registerStatus === "success" && favoritesStatus === "success"} errorCondition={registerStatus === "failed" || favoritesStatus === "failed"} customError={
                                <>
                                    {registerStatus === "failed" &&
                                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                                            <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_GET_ACTIVITIES_FAILED}</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography variant="h6"><Button variant="text" onClick={handleHomeClick} sx={{ p: 0 }}>{resourceFinal[appLanguage].messages.ERROR_RETURN_LINK}</Button> {resourceFinal[appLanguage].messages.ERROR_RETURN_MESSAGE}</Typography>
                                            </Box>
                                        </Box>}

                                    {favoritesStatus === "failed" &&
                                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                                            <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_GET_FAVORITES_FAILED}</Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography variant="h6"><Button variant="text" onClick={handleHomeClick} sx={{ p: 0 }}>{resourceFinal[appLanguage].messages.ERROR_RETURN_LINK}</Button> {resourceFinal[appLanguage].messages.ERROR_RETURN_MESSAGE}</Typography>
                                            </Box>
                                        </Box>}
                                </>
                            } content={
                                <>
                                    <Grid container spacing={{ xs: 1, md: 2 }} justifyContent="center">
                                        {favorites?.length > 0 && registerFavorite?.filter(customFilterFunction).length <= 0 && registerNoFavorite?.filter(customFilterFunction).length > 0 ?
                                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <WarningDisplay message={resourceFinal[appLanguage].messages.NO_REGISTER_FAVORITES} />
                                            </Grid> : ""}
                                        {registerFavorite?.filter(customFilterFunction).map((item) =>
                                            <Grid key={item.performanceId}>
                                                <RegisterCard performance={item} futureWarning={siomSettings?.enableSubscriptionOutsideProgram} />
                                            </Grid>
                                        )}
                                        {registerNoFavorite?.filter(customFilterFunction).map((item) =>
                                            <Grid key={item.performanceId}>
                                                <RegisterCard performance={item} futureWarning={siomSettings?.enableSubscriptionOutsideProgram} />
                                            </Grid>
                                        )}
                                        {(registerFavorite?.filter(customFilterFunction).length <= 0 && registerNoFavorite?.filter(customFilterFunction).length <= 0) &&
                                            (registerFavorite?.length > 0 || registerNoFavorite?.length > 0) ?
                                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <NoContentDisplay message={resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY} />
                                            </Grid> : ""}
                                        {registerFavorite?.length <= 0 && registerNoFavorite?.length <= 0 ?
                                            <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <NoContentDisplay message={resourceFinal[appLanguage].messages.NO_REGISTER_CLINICS} />
                                            </Grid> : ""}
                                    </Grid>
                                </>
                            } />
                        }
                    </>
                } />
            </>
        } contentLeft={
            <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            <>
                {registerStatus === "success" && favoritesStatus === "success" ?
                    <FilterButton filters={<FilterFragment />} /> : ""}
            </>
        } />
    );
}
