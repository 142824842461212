import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getHeadTypes = createAsyncThunk(
    '/api/typeManagement/head',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/head`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const getActivityTypes = createAsyncThunk(
    '/api/typeManagement/activity',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/activity`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putHeadType = createAsyncThunk(
    '/api/typeManagement/head/put',
    async (updatedHeadType, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/head`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedHeadType)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putActivityType = createAsyncThunk(
    '/api/typeManagement/activity/put',
    async (updatedActivityType, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/activity`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedActivityType)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postHeadType = createAsyncThunk(
    '/api/typeManagement/head/post',
    async (newHeadType, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/head`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newHeadType)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postActivityType = createAsyncThunk(
    '/api/typeManagement/activity/post',
    async (newActivityType, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/activity`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newActivityType)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteHeadType = createAsyncThunk(
    '/api/typeManagement/head/delete',
    async (headTypeId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/head/${headTypeId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deleteActivityType = createAsyncThunk(
    '/api/typeManagement/activity/delete',
    async (activityTypeId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/typeManagement/activity/${activityTypeId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const typeManagementSlice = createSlice({
    name: 'typeManagement',
    initialState: {
        headTypes: null,
        headTypesStatus: null,
        activityTypes: null,
        activityTypesStatus: null,
        selectedType: null,
        putHeadTypeStatus: null,
        putActivityTypeStatus: null,
        postHeadTypeStatus: null,
        postActivityTypeStatus: null,
        deleteHeadTypeStatus: null,
        deleteActivityTypeStatus: null,
        showFailed: false,
    },
    reducers: {
        selectType: (state, action) => {
            state.selectedType = action.payload;
        },
        resetTypeStatus: (state) => {
            state.headTypesStatus = null;
            state.putHeadTypeStatus = null;
            state.putActivityTypeStatus = null;
            state.postHeadTypeStatus = null;
            state.postActivityTypeStatus = null;
            state.deleteHeadTypeStatus = null;
            state.deleteActivityTypeStatus = null;
        },
        setFailedType: (state, action) => {
            state.showFailed = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Get head types
        builder
            .addCase(getHeadTypes.pending, (state, action) => {
                state.headTypesStatus = 'loading';
            })
            .addCase(getHeadTypes.fulfilled, (state, action) => {
                state.headTypesStatus = 'success';
                state.headTypes = action.payload;
            })
            .addCase(getHeadTypes.rejected, (state, action) => {
                state.headTypesStatus = 'failed';
            });

        // Get activity types
        builder
            .addCase(getActivityTypes.pending, (state, action) => {
                state.activityTypesStatus = 'loading';
            })
            .addCase(getActivityTypes.fulfilled, (state, action) => {
                state.activityTypesStatus = 'success';
                state.activityTypes = action.payload;
            })
            .addCase(getActivityTypes.rejected, (state, action) => {
                state.activityTypesStatus = 'failed';
            });

        // Put head type
        builder
            .addCase(putHeadType.pending, (state, action) => {
                state.putHeadTypeStatus = 'loading';
            })
            .addCase(putHeadType.fulfilled, (state, action) => {
                state.putHeadTypeStatus = 'success';
            })
            .addCase(putHeadType.rejected, (state, action) => {
                state.putHeadTypeStatus = 'failed';
            });

        // Put activity type
        builder
            .addCase(putActivityType.pending, (state, action) => {
                state.putActivityTypeStatus = 'loading';
            })
            .addCase(putActivityType.fulfilled, (state, action) => {
                state.putActivityTypeStatus = 'success';
            })
            .addCase(putActivityType.rejected, (state, action) => {
                state.putActivityTypeStatus = 'failed';
            });

        // Post head type
        builder
            .addCase(postHeadType.pending, (state, action) => {
                state.postHeadTypeStatus = 'loading';
            })
            .addCase(postHeadType.fulfilled, (state, action) => {
                state.postHeadTypeStatus = 'success';
            })
            .addCase(postHeadType.rejected, (state, action) => {
                state.postHeadTypeStatus = 'failed';
            });

        // Post activity type
        builder
            .addCase(postActivityType.pending, (state, action) => {
                state.postActivityTypeStatus = 'loading';
            })
            .addCase(postActivityType.fulfilled, (state, action) => {
                state.postActivityTypeStatus = 'success';
            })
            .addCase(postActivityType.rejected, (state, action) => {
                state.postActivityTypeStatus = 'failed';
            });

        // Delete head type
        builder
            .addCase(deleteHeadType.pending, (state, action) => {
                state.deleteHeadTypeStatus = 'loading';
            })
            .addCase(deleteHeadType.fulfilled, (state, action) => {
                state.deleteHeadTypeStatus = 'success';
            })
            .addCase(deleteHeadType.rejected, (state, action) => {
                state.deleteHeadTypeStatus = 'failed';
            });

        // Delete activity type
        builder
            .addCase(deleteActivityType.pending, (state, action) => {
                state.deleteActivityTypeStatus = 'loading';
            })
            .addCase(deleteActivityType.fulfilled, (state, action) => {
                state.deleteActivityTypeStatus = 'success';
            })
            .addCase(deleteActivityType.rejected, (state, action) => {
                state.deleteActivityTypeStatus = 'failed';
            });
    },
});

export const {
    resetTypeStatus,
    selectType,
    setFailedType
} = typeManagementSlice.actions;

export default typeManagementSlice.reducer;