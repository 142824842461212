import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchWintersportReport = createAsyncThunk(
    '/api/employeeReports/Wintersport/performanceId',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchWintersportReportActivities = createAsyncThunk(
    '/api/employeeReports/wintersport/activities',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/activities`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchWintersportReportPerformances = createAsyncThunk(
    '/api/employeeReports/wintersport/performances',
    async (activityId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/wintersport/performances/${activityId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportWintersportSlice = createSlice({
    name: 'reportWintersport',
    initialState: {
        wintersportReport: null,
        wintersportReportStatus: null,
        wintersportReportActivities: null,
        wintersportReportActivitiesStatus: null,
        wintersportReportPerformances: null,
        wintersportReportPerformancesStatus: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get wintersport report
        builder
            .addCase(fetchWintersportReport.pending, (state) => {
                state.wintersportReportStatus = 'loading';
            })
            .addCase(fetchWintersportReport.fulfilled, (state, action) => {
                state.wintersportReport = action.payload;
                state.wintersportReportStatus = 'success';
            })
            .addCase(fetchWintersportReport.rejected, (state) => {
                state.wintersportReportStatus = 'failed';
            });

        // Get wintersport report activities
        builder
            .addCase(fetchWintersportReportActivities.pending, (state) => {
                state.wintersportReportActivitiesStatus = 'loading';
            })
            .addCase(fetchWintersportReportActivities.fulfilled, (state, action) => {
                state.wintersportReportActivities = action.payload;
                state.wintersportReportActivitiesStatus = 'success';
            })
            .addCase(fetchWintersportReportActivities.rejected, (state) => {
                state.wintersportReportActivitiesStatus = 'failed';
            });

        // Get wintersport report performances
        builder
            .addCase(fetchWintersportReportPerformances.pending, (state) => {
                state.wintersportReportPerformancesStatus = 'loading';
            })
            .addCase(fetchWintersportReportPerformances.fulfilled, (state, action) => {
                state.wintersportReportPerformances = action.payload;
                state.wintersportReportPerformancesStatus = 'success';
            })
            .addCase(fetchWintersportReportPerformances.rejected, (state) => {
                state.wintersportReportPerformancesStatus = 'failed';
            });
    }
});

//export const { } = reportWintersportSlice.actions;

export default reportWintersportSlice.reducer;