import { Box, Button, ButtonGroup, Card, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { ConvertoToCSharpDateTime, DisplayName } from '../../../app/AppUtils';
import LocationEditCard from '../../management-activity/location/LocationEditCard';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import { fetchDayPlanning, fetchMonthPlanning, fetchPlanningActivities, fetchWeekPlanning, fetchYearPlanning } from '../planningSlice';
import { fetchDefaultActivity, fetchPerformance, postPerformance, putPerformance, resetPlanningPerformanceStatus, setFailedPerformance } from './planningPerformanceSlice';

import AddIcon from '@mui/icons-material/Add';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function PlanningPerformanceEditCard({ handleClose, addNew, addActivity, id, waitForInput }) {
    const dispatch = useDispatch();
    const textRef = useRef(null);
    const now = new Date();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { locations } = useSelector((state) => state.management.location);
    const { allContacts } = useSelector((state) => state.contacts);
    const { newPlanningActivities, newPlanningActivitiesStatus, planningPeriods, accordionDisplay, accordionSelectedDate, accordionShowPast } = useSelector((state) => state.planning.planning);
    const { showFailed, performance, putPerformanceStatus, postPerformanceStatus, defaultActivity, defaultActivityStatus } = useSelector((state) => state.planning.performance);

    // States
    const [changed, setChanged] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [filter, setFilter] = useState("");
    const [newActivity, setNewActivity] = useState(null);
    const [newPerformance, setNewPerformance] = useState({});
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [minMoment, setMinMoment] = useState(null);
    const [maxMoment, setMaxMoment] = useState(null);

    const [selectedMomentStart, setSelectedMomentStart] = useState(null);
    const [selectedMomentEnd, setSelectedMomentEnd] = useState(null);
    const [selectedSubscribableFrom, setSelectedSubscribableFrom] = useState(null);
    const [selectedSubscribableUntil, setSelectedSubscribableUntil] = useState(null);

    // Errors
    const [errorActivity, setErrorActivity] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorCapacity, setErrorCapacity] = useState(null);
    const [errorLocation, setErrorLocation] = useState(null);
    const [errorMomentStart, setErrorMomentStart] = useState(null);
    const [errorMomentEnd, setErrorMomentEnd] = useState(null);
    const [errorMomentRange, setErrorMomentRange] = useState(null);
    const [errorSubscribableUntil, setErrorSubscribableUntil] = useState(null);
    const [errorSubscribableFrom, setErrorSubscribableFrom] = useState(null);
    const [errorPeriod, setErrorPeriod] = useState(null);
    const [errorMomentPeriodRange, setErrorMomentPeriodRange] = useState(null);
    const [errorSubscribableRange, setErrorSubscribableRange] = useState(null);
    const [errorSubscribableMoment, setErrorSubscribableMoment] = useState(null);

    // Get default activity values
    useEffect(() => {
        if (addNew && performance?.activity?.id)
            dispatch(fetchDefaultActivity(performance?.activity?.id));
    }, [dispatch, addNew, performance]);

    // Set 'subscribable from' if new performance
    useEffect(() => {
        if (addNew && selectedSubscribableFrom === null) {
            var value = dayjs(now);
            setSelectedSubscribableFrom(value);
        }
    }, []);

    // Set default activity values
    useEffect(() => {
        if (defaultActivityStatus === "success") {
            setNewPerformance({
                ...newPerformance,
                capacityLimit: defaultActivity?.capacity,
                points: siomSettings?.flagProgress ? 0 : defaultActivity?.points,
                cost: defaultActivity?.cost,
                contact: defaultActivity?.contact,
                location: defaultActivity?.location,
            });
        }
    }, [dispatch, addNew, performance, defaultActivityStatus, defaultActivity]);

    // Put status callback
    useEffect(() => {
        if (putPerformanceStatus === "success") {
            dispatch(setFailedPerformance(false));
            dispatch(resetPlanningPerformanceStatus());
            dispatch(fetchPerformance(id));
            handleClose();
        } else if (putPerformanceStatus === "failed") {
            dispatch(setFailedPerformance(true));
        };
    }, [dispatch, handleClose, putPerformanceStatus, id]);

    // Post status callback
    useEffect(() => {
        if (postPerformanceStatus === "success") {
            dispatch(setFailedPerformance(false));
            dispatch(resetPlanningPerformanceStatus());

            const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

            if (accordionDisplay === "AccordionActivity")
                dispatch(fetchPlanningActivities({ showPast: accordionShowPast, departmentId: departmentId }));

            if (accordionDisplay === "AccordionDay")
                dispatch(fetchDayPlanning({ date: accordionSelectedDate, departmentId: departmentId }));

            if (accordionDisplay === "AccordionWeek")
                dispatch(fetchWeekPlanning({ date: accordionSelectedDate, departmentId: departmentId }));

            if (accordionDisplay === "AccordionMonth")
                dispatch(fetchMonthPlanning({ date: new Date(new Date(accordionSelectedDate).setMonth(new Date(accordionSelectedDate).getMonth() + 1)).toISOString(), departmentId: departmentId }));

            if (accordionDisplay === "AccordionYear")
                dispatch(fetchYearPlanning({ date: null, departmentId: departmentId }));

            handleClose();
        } else if (postPerformanceStatus === "failed") {
            dispatch(setFailedPerformance(true));
        };
    }, [dispatch, handleClose, postPerformanceStatus]);

    // Character limits
    const nameLength = 255;

    // Set selected performance state 
    useEffect(() => {
        if (!addNew && performance) {
            setSelectedPeriod(performance.period);
            setNewPerformance(performance);

            setSelectedMomentStart(dayjs(performance?.momentStart));
            setSelectedMomentEnd(dayjs(performance?.momentEnd));
            setSelectedSubscribableFrom(dayjs(performance?.subscribableFrom));
            setSelectedSubscribableUntil(dayjs(performance?.subscribableUntil));
        }
    }, [addNew, performance]);

    // Keep track of changed state 
    useEffect(() => {
        if ((!addNew && newPerformance !== performance) || (addNew && Object.keys(newPerformance) !== 0)) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [performance, newPerformance, addNew]);

    // Check moment range
    useEffect(() => {
        if (Date.parse(selectedMomentStart) >= Date.parse(selectedMomentEnd)) {
            setErrorMomentRange(true);
        } else {
            setErrorMomentRange(false);
        };
    }, [selectedMomentStart, selectedMomentEnd]);

    // Check subscribable date range
    useEffect(() => {
        if (Date.parse(selectedSubscribableFrom) >= Date.parse(selectedSubscribableUntil)) {
            setErrorSubscribableRange(true);
        } else {
            setErrorSubscribableRange(false);
        };
    }, [selectedSubscribableFrom, selectedSubscribableUntil]);

    // Check if subscribable before moment
    useEffect(() => {
        if (Date.parse(selectedSubscribableUntil) >= Date.parse(selectedMomentStart)) {
            setErrorSubscribableMoment(true);
        } else {
            setErrorSubscribableMoment(false);
        };
    }, [selectedSubscribableUntil, selectedMomentStart]);

    // Check if moments in period
    useEffect(() => {
        if (selectedMomentStart && selectedMomentEnd && minMoment && maxMoment) {
            if ((Date.parse(selectedMomentStart) >= Date.parse(minMoment) && Date.parse(selectedMomentStart) <= Date.parse(maxMoment)) &&
                (Date.parse(selectedMomentEnd) >= Date.parse(minMoment) && Date.parse(selectedMomentEnd) <= Date.parse(maxMoment))) {
                setErrorMomentPeriodRange(false);
            } else {
                setErrorMomentPeriodRange(true);
            };
        };
    }, [selectedMomentStart, selectedMomentEnd, minMoment, maxMoment]);

    // Check min/max date for moment end
    useEffect(() => {
        if (selectedPeriod) {
            setMaxMoment(dayjs(selectedPeriod?.endTime));
            setMinMoment(dayjs(selectedPeriod?.startTime));
        };
    }, [selectedPeriod]);

    const callbackModalLocation = () => setOpenLocation(false);

    const handleSearchChange = (e) => {
        if (newActivity)
            setNewActivity(null);

        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.name?.toLowerCase().includes(filter.toLowerCase());
    };

    // Update click function (check property character limits etc.)
    const updateClick = async () => {
        let tempObject = { ...newPerformance }; // Needed to circumvent weird read only property error

        if (!newPerformance.period) return setErrorPeriod(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorPeriod(null);

        if (!newPerformance.name && appSettings?.appVersion === 2) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newPerformance.name && newPerformance.name?.length > nameLength && appSettings?.appVersion === 2) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (!newPerformance.capacityLimit) return setErrorCapacity(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorCapacity(null);

        if (!newPerformance.location) return setErrorLocation(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorLocation(null);

        if (!selectedMomentStart) return setErrorMomentStart(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentStart(null);

        if (!selectedMomentEnd) return setErrorMomentEnd(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentEnd(null);

        if (!selectedSubscribableFrom) return setErrorSubscribableFrom(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorSubscribableFrom(null);

        if (!selectedSubscribableUntil) return setErrorSubscribableUntil(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorSubscribableUntil(null);

        if (errorMomentRange) return;
        if (errorSubscribableRange) return;
        if (errorSubscribableMoment) return;
        if (errorMomentPeriodRange) return;

        !newPerformance.hasOwnProperty('moments') ? tempObject.moments = 1 : tempObject.moments = newPerformance.moments;
        tempObject.momentStart = ConvertoToCSharpDateTime(selectedMomentStart);
        tempObject.momentEnd = ConvertoToCSharpDateTime(selectedMomentEnd);
        tempObject.subscribableFrom = ConvertoToCSharpDateTime(selectedSubscribableFrom);
        tempObject.subscribableUntil = ConvertoToCSharpDateTime(selectedSubscribableUntil);
        tempObject.id = performance.id;
        tempObject.modifiedBy = `AP/${user.number}`;

        dispatch(putPerformance(tempObject));
    };

    // Add click function (check property character limits etc.)
    const addClick = async () => {
        let tempObject = { ...newPerformance }; // Needed to circumvent weird read only property error

        if (addActivity && !newActivity) return setErrorActivity(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorActivity(null);

        if (!newPerformance.period) return setErrorPeriod(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorPeriod(null);

        if (!newPerformance.name && appSettings?.appVersion === 2) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newPerformance.name && newPerformance.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        if (!newPerformance.capacityLimit) return setErrorCapacity(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorCapacity(null);

        if (!newPerformance.location) return setErrorLocation(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorLocation(null);

        if (!selectedMomentStart) return setErrorMomentStart(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentStart(null);

        if (!selectedMomentEnd) return setErrorMomentEnd(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentEnd(null);

        if (!selectedSubscribableFrom) return setErrorSubscribableFrom(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorSubscribableFrom(null);

        if (!selectedSubscribableUntil) return setErrorSubscribableUntil(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorSubscribableUntil(null);

        if (errorMomentRange) return;
        if (errorMomentPeriodRange) return;
        if (errorSubscribableRange) return;
        if (errorSubscribableMoment) return;

        !newPerformance.hasOwnProperty('moments') ? tempObject.moments = 1 : tempObject.moments = newPerformance.moments;
        tempObject.momentStart = ConvertoToCSharpDateTime(selectedMomentStart);
        tempObject.momentEnd = ConvertoToCSharpDateTime(selectedMomentEnd);
        tempObject.subscribableFrom = ConvertoToCSharpDateTime(selectedSubscribableFrom);
        tempObject.subscribableUntil = ConvertoToCSharpDateTime(selectedSubscribableUntil);
        tempObject.activity = addActivity ? { id: newActivity } : performance.activity;
        tempObject.createdBy = `AP/${user.number}`;

        dispatch(postPerformance(tempObject));
    };

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        {addActivity ? (
                            <ContentLoader successCondition={newPlanningActivitiesStatus === 'success'} errorCondition={newPlanningActivitiesStatus === 'failed'} errorCompact content={
                                <Grid size={{ xs: 12 }}>
                                    <FormControl fullWidth required error={errorActivity !== null}>
                                        <InputLabel>{resourceFinal[appLanguage].words.ACTIVITIES}</InputLabel>
                                        <Select onClose={() => setFilter("")} label={resourceFinal[appLanguage].words.ACTIVITIES} defaultValue={performance?.activity ? performance?.activity : ""}
                                            onChange={(event) => {
                                                setNewActivity(event.target.value);
                                                dispatch(fetchDefaultActivity(event.target.value));
                                            }}>
                                            <MenuItem
                                                disableRipple
                                                dense
                                                divider
                                                value={""}
                                                onClickCapture={stopImmediatePropagation}
                                                onKeyDown={e => e.stopPropagation()}
                                            >
                                                <SearchField func={handleSearchChange} textRef={textRef} />
                                            </MenuItem>
                                            {newPlanningActivities?.filter(customFilterFunction).map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) ?? []}
                                        </Select>
                                        <FormHelperText>{errorActivity}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            } />
                        ) : (
                            <Grid size={{ xs: 12 }}>
                                <TextField
                                    fullWidth disabled required={appSettings?.appVersion === 2}
                                    value={performance?.activity?.name}
                                    label={resourceFinal[appLanguage].words.ACTIVITY}
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                        <ContentLoader waitForInput={waitForInput} successCondition={addNew ? defaultActivityStatus === "success" : true} errorCondition={addNew ? defaultActivityStatus === "failed" : false} content={
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <FormControl fullWidth required error={errorPeriod !== null}>
                                        <InputLabel>{resourceFinal[appLanguage].words.PERIOD}</InputLabel>
                                        <Select label={resourceFinal[appLanguage].words.PERIOD} defaultValue={performance?.period ? performance?.period?.periodId : ""}
                                            onChange={(event) => {
                                                setNewPerformance({
                                                    ...newPerformance,
                                                    period: planningPeriods.find(x => x.periodId === event.target.value)
                                                })
                                                setSelectedPeriod(planningPeriods.find(x => x.periodId === event.target.value));
                                            }}>
                                            {planningPeriods?.map((item) => <MenuItem key={item.periodId} value={item.periodId}>{item.name}</MenuItem>)}
                                        </Select>
                                        <FormHelperText>{errorPeriod}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TextField
                                        fullWidth required={appSettings?.appVersion === 2}
                                        defaultValue={performance?.name}
                                        label={resourceFinal[appLanguage].words.NAME}
                                        onChange={(event) => setNewPerformance({
                                            ...newPerformance,
                                            name: event.target.value
                                        })}
                                        error={errorName !== null}
                                        helperText={errorName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={{ xs: appSettings?.appVersion === 1 ? 6 : 4 }}>
                                    <TextField
                                        fullWidth required type="number"
                                        defaultValue={1}
                                        label={resourceFinal[appLanguage].words.MOMENTS}
                                        onChange={(event) => setNewPerformance({
                                            ...newPerformance,
                                            moments: Math.round(event.target.value)
                                        })}
                                        InputProps={{
                                            inputProps: { min: 1 }
                                        }}
                                        variant="outlined"
                                        disabled={!addNew}
                                    />
                                </Grid>
                                <Grid size={{ xs: appSettings?.appVersion === 1 ? 6 : 4 }}>
                                    <TextField
                                        fullWidth required type="number"
                                        defaultValue={addNew ? defaultActivity?.capacity : performance?.capacityLimit}
                                        label={resourceFinal[appLanguage].words.CAPACITY}
                                        onChange={(event) => setNewPerformance({
                                            ...newPerformance,
                                            capacityLimit: Math.round(event.target.value)
                                        })}
                                        error={errorCapacity !== null}
                                        helperText={errorCapacity}
                                        variant="outlined"
                                    />
                                </Grid>
                                {!siomSettings?.flagProgress &&
                                    <Grid size={{ xs: appSettings?.appVersion === 1 ? 6 : 4 }}>
                                        <TextField
                                            fullWidth type="number"
                                            defaultValue={addNew ? defaultActivity?.points : performance?.points}
                                            label={appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}
                                            onChange={(event) => setNewPerformance({
                                                ...newPerformance,
                                                points: Math.round(event.target.value)
                                            })}
                                            variant="outlined"
                                        />
                                    </Grid>}
                                {appSettings?.appVersion === 1 &&
                                    <Grid size={{ xs: siomSettings?.flagProgress ? 12 : 6 }}>
                                        <TextField
                                            fullWidth type="number"
                                            inputProps={{ step: 0.5 }}
                                            defaultValue={addNew ? defaultActivity?.cost : performance?.cost}
                                            label={resourceFinal[appLanguage].words.COST}
                                            onChange={(event) => setNewPerformance({
                                                ...newPerformance,
                                                cost: event.target.value
                                            })}
                                            variant="outlined"
                                        />
                                    </Grid>}
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                        <DateTimePicker
                                            slotProps={{ textField: { fullWidth: true } }}
                                            disabled={!newPerformance.period}
                                            label={`${resourceFinal[appLanguage].words.MOMENT} ${resourceFinal[appLanguage].words.START.toLowerCase()} *`}
                                            value={selectedMomentStart ?? null}
                                            onChange={(value) => {
                                                setChanged(true);
                                                setSelectedMomentStart(dayjs(value));
                                                if (addNew || Date.parse(value) > Date.parse(newPerformance.momentEnd)) setSelectedMomentEnd(dayjs(value).add(siomSettings?.momentDefaultDuration, 'hours'));
                                                if (addNew || Date.parse(value) < Date.parse(newPerformance.subscribableUntil)) setSelectedSubscribableUntil(dayjs(value).subtract(24, 'hours'));
                                            }}
                                            minDate={minMoment}
                                            maxDate={maxMoment}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                        <DateTimePicker
                                            slotProps={{ textField: { fullWidth: true } }}
                                            disabled={!newPerformance.period}
                                            value={selectedMomentEnd ?? null}
                                            label={`${resourceFinal[appLanguage].words.MOMENT} ${resourceFinal[appLanguage].words.END.toLowerCase()} *`}
                                            onChange={(value) => {
                                                setChanged(true);
                                                setSelectedMomentEnd(dayjs(value));
                                                if ((addNew && selectedMomentStart === null) || Date.parse(value) < Date.parse(newPerformance.momentStart)) setSelectedMomentStart(dayjs(value).subtract(siomSettings?.momentDefaultDuration, 'hours'));
                                            }}
                                            minDate={minMoment}
                                            maxDate={maxMoment}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                        <DateTimePicker
                                            slotProps={{ textField: { fullWidth: true } }}
                                            disabled={!selectedMomentStart}
                                            label={`${resourceFinal[appLanguage].words.SUBSCRIBABLE_FROM} *`}
                                            value={selectedSubscribableFrom ?? null}
                                            onChange={(value) => {
                                                setChanged(true);
                                                setSelectedSubscribableFrom(dayjs(value))
                                            }}
                                            maxDate={selectedMomentStart}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                        <DateTimePicker
                                            slotProps={{ textField: { fullWidth: true } }}
                                            disabled={!selectedMomentStart}
                                            label={`${resourceFinal[appLanguage].words.SUBSCRIBABLE_UNTIL} *`}
                                            value={selectedSubscribableUntil ?? null}
                                            onChange={(value) => {
                                                setChanged(true);
                                                setSelectedSubscribableUntil(dayjs(value))
                                            }}
                                            maxDate={selectedMomentStart}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{resourceFinal[appLanguage].words.CONTACT}</InputLabel>
                                        <Select label={resourceFinal[appLanguage].words.CONTACT}
                                            defaultValue={addNew ? (defaultActivity?.contact ? defaultActivity?.contact?.id : "") : (performance?.contact ? performance?.contact?.id : "")}
                                            onChange={(event) => setNewPerformance({
                                                ...newPerformance,
                                                contact: { id: event.target.value }
                                            })}>
                                            <MenuItem value={''}>-</MenuItem>
                                            {allContacts?.map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{DisplayName(item.firstname, item.insertion, item.lastname)}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{`${resourceFinal[appLanguage].words.PRIMARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}</InputLabel>
                                        <Select label={`${resourceFinal[appLanguage].words.PRIMARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}
                                            defaultValue={performance?.primaryAttendant ? performance?.primaryAttendant?.id : ""}
                                            onChange={(event) => setNewPerformance({
                                                ...newPerformance,
                                                primaryAttendant: { id: event.target.value }
                                            })}>
                                            <MenuItem value={''}>-</MenuItem>
                                            {allContacts?.map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{DisplayName(item.firstname, item.insertion, item.lastname)}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }}>
                                    <FormControl fullWidth>
                                        <InputLabel>{`${resourceFinal[appLanguage].words.SECONDARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}</InputLabel>
                                        <Select label={`${resourceFinal[appLanguage].words.SECONDARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}
                                            defaultValue={performance?.secondaryAttendant ? performance?.secondaryAttendant?.id : ""}
                                            onChange={(event) => setNewPerformance({
                                                ...newPerformance,
                                                secondaryAttendant: { id: event.target.value }
                                            })}>
                                            <MenuItem value={''}>-</MenuItem>
                                            {allContacts?.map((item) =>
                                                <MenuItem key={item.id} value={item.id}>{DisplayName(item.firstname, item.insertion, item.lastname)}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <Stack direction="row" spacing={0.5} sx={{ width: 1 }}>
                                        <FormControl fullWidth error={errorLocation !== null} required>
                                            <InputLabel>{resourceFinal[appLanguage].words.LOCATION}</InputLabel>
                                            <Select label={resourceFinal[appLanguage].words.LOCATION}
                                                defaultValue={addNew ? defaultActivity?.location?.id : performance?.location ? performance?.location?.id : ""}
                                                onChange={(event) => setNewPerformance({
                                                    ...newPerformance,
                                                    location: { id: event.target.value }
                                                })}>
                                                <MenuItem value={null}>-</MenuItem>
                                                {locations?.map((item) =>
                                                    <MenuItem key={item.id} value={item.id}>{item.naam}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText>{errorLocation}</FormHelperText>
                                        </FormControl>
                                        <IconButton sx={{ height: 56, border: 1, borderRadius: 1, borderColor: 'white.dark' }} onClick={() => setOpenLocation(true)}><AddIcon color="primary" /></IconButton>
                                    </Stack>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TextField
                                        fullWidth multiline
                                        defaultValue={performance?.remark}
                                        label={resourceFinal[appLanguage].words.PERFORMANCE_REMARK}
                                        onChange={(event) => setNewPerformance({
                                            ...newPerformance,
                                            remark: event.target.value
                                        })}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={{ xs: 12, md: 12 }}>
                                    <FormControlLabel control={<Checkbox defaultChecked={performance?.alwaysAvailable} onChange={(event) => {
                                        setNewPerformance({
                                            ...newPerformance,
                                            alwaysAvailable: event.target.checked
                                        })
                                    }} />} label={resourceFinal[appLanguage].words.ALWAYS_AVAILABLE} />
                                </Grid>
                                <Grid size={{ xs: 12, md: 6 }} sx={{ display: 'none' }}>
                                    <FormControlLabel control={<Checkbox defaultChecked={performance?.remarkPopup} onChange={(event) => setNewPerformance({
                                        ...newPerformance,
                                        remarkPopup: event.target.checked
                                    })} />} label={resourceFinal[appLanguage].words.REMARK_POPUP} />
                                </Grid>
                                {showFailed && <Grid size={{ xs: 12 }}>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                                </Grid>}
                                {(errorLocation || errorSubscribableFrom || errorSubscribableUntil || errorMomentStart || errorMomentEnd) && <Grid size={{ xs: 12 }}>
                                    <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROPERTIES_EMPTY}
                                        {errorSubscribableFrom && <li>{resourceFinal[appLanguage].words.SUBSCRIBABLE_FROM}</li>}
                                        {errorSubscribableUntil && <li>{resourceFinal[appLanguage].words.SUBSCRIBABLE_UNTIL}</li>}
                                        {errorMomentStart && <li>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.START.toLowerCase()}</li>}
                                        {errorMomentEnd && <li>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.END.toLowerCase()}</li>}
                                    </Typography>
                                </Grid>}
                                {(performance?.moments > 1 && !errorMomentRange && !errorSubscribableMoment && !addNew) && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_ALL_MOMENTS}</Typography></Grid>}
                                {errorMomentRange && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_RANGE_MOMENTS}</Typography></Grid>}
                                {errorSubscribableRange && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_RANGE_SUBSCRIBABLE}</Typography></Grid>}
                                {errorMomentPeriodRange && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_RANGE_PERIODMOMENT}</Typography></Grid>}
                                {(!errorMomentRange && !errorSubscribableRange && !errorMomentPeriodRange && errorSubscribableMoment) && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_SUBSCRIBABLE_MOMENT}</Typography></Grid>}
                                <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <ButtonGroup>
                                        <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                        {!addNew && <Button disabled={!changed || errorMomentRange || errorSubscribableRange || errorSubscribableMoment || errorMomentPeriodRange} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                        {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                                    </ButtonGroup>
                                </Grid>
                            </>
                        } />
                    </Grid>
                </Scrollbars>
            </Card>

            <Modal open={openLocation} onClose={callbackModalLocation}>
                <>
                    <LocationEditCard handleClose={callbackModalLocation} addNew />
                </>
            </Modal>
        </Box>
    );
}