import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchNotifications = createAsyncThunk(
    '/api/notifications/studentId',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/notifications`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const updateNotification = createAsyncThunk(
    '/api/notifications/update',
    async ({ notificationId, archive }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/notifications/update/${notificationId}/${archive}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify({
                notificationId: notificationId,
                archive: archive
            }),
        })

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const addSubscription = createAsyncThunk(
    '/api/notifications/subscription/post',
    async ({ endpoint, p256dh, auth }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/notifications/subscription/post`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({
                endpoint: endpoint, p256dh: p256dh, auth: auth
            }),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postNotification = createAsyncThunk(
    '/api/notifications/post',
    async ({ studentId, message }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/notifications/post`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({
                studentId: studentId, message: message
            }),
        })

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchNotificationsCount = createAsyncThunk(
    '/api/notifications/count',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/notifications/count`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: null,
        notificationsStatus: null,
        notificationsCount: null,
        notificationsCountStatus: null,
        updateStatus: null,
        notificationStatus: null,
        subscriptionStatus: null,
        permission: "granted",
        archiveCounter: null,
    },
    reducers: {
        setPermission: (state, action) => {
            state.permission = action.payload;
        },
        incrArchiveCounter: (state) => {
            state.archiveCounter += 1;
        }
    },
    extraReducers: (builder) => {
        // Get Notifications
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.notificationsStatus = 'loading';
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.notificationsStatus = 'success';
                state.notifications = action.payload;
            })
            .addCase(fetchNotifications.rejected, (state) => {
                state.notificationsStatus = 'failed';
            });

        // Update Notification
        builder
            .addCase(updateNotification.pending, (state) => {
                state.updateStatus = 'loading';
            })
            .addCase(updateNotification.fulfilled, (state) => {
                state.updateStatus = 'success';
            })
            .addCase(updateNotification.rejected, (state) => {
                state.updateStatus = 'failed';
            });

        // Add Subscription
        builder
            .addCase(addSubscription.pending, (state) => {
                state.subscriptionStatus = 'loading';
            })
            .addCase(addSubscription.fulfilled, (state) => {
                state.subscriptionStatus = 'success';
            })
            .addCase(addSubscription.rejected, (state) => {
                state.subscriptionStatus = 'failed';
            });

        // Post Notification
        builder
            .addCase(postNotification.pending, (state) => {
                state.notificationStatus = 'loading';
            })
            .addCase(postNotification.fulfilled, (state) => {
                state.notificationStatus = 'success';
            })
            .addCase(postNotification.rejected, (state) => {
                state.notificationStatus = 'failed';
            });

        // Get Notifications Count
        builder
            .addCase(fetchNotificationsCount.pending, (state) => {
                state.notificationsCountStatus = 'loading';
            })
            .addCase(fetchNotificationsCount.fulfilled, (state, action) => {
                state.notificationsCountStatus = 'success';
                state.notificationsCount = action.payload;
            })
            .addCase(fetchNotificationsCount.rejected, (state) => {
                state.notificationsCountStatus = 'failed';
            });
    }
});

export const {
    incrArchiveCounter,
    setPermission
} = notificationsSlice.actions;

export default notificationsSlice.reducer;