import { Backdrop, Card, CardContent, Typography } from '@mui/material';
import { useSelector } from "react-redux";

export default function UserWarning() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { userCheck, userCheckStatus } = useSelector((state) => state.user);

    return (
        <>
            {userCheckStatus === 'success' &&
                <>
                    {userCheck !== 4 &&
                        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                            <Card sx={{ height: 'auto', mx: 2, textAlign: 'center' }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {userCheck === 0 ?
                                        <>
                                            <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_NO_USER}</Typography>
                                            <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_CONTACT}</Typography>
                                        </>
                                        : userCheck === 1 ?
                                            <>
                                                <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_NO_ASSIGNED_CLASS}</Typography>
                                                <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_CONTACT}</Typography>

                                            </>
                                            : userCheck === 2 ?
                                                <>
                                                    <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_NO_ACTIVE_CLASS}</Typography>
                                                    <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_CONTACT}</Typography>
                                                </>
                                                : userCheck === 3 ?
                                                    <>
                                                        <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_NO_ASSIGNED_PROGRAM}</Typography>
                                                        <Typography variant="h5">{resourceFinal[appLanguage].messages.ERROR_MESSAGE_CONTACT}</Typography>
                                                    </> : ""}
                                </CardContent>
                            </Card>
                        </Backdrop>}
                </>}
        </>
    )
}