import { Backdrop, Box, Button, Card, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { putStudentInfo } from './studentSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function StudentInfoWarning({ isInfoComplete }) {
    const dispatch = useDispatch();
    const location = useLocation();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { studentInfoSettings, studentInfoSettingsStatus, putStudentInfoStatus } = useSelector((state) => state.student);

    // Student info values
    const {
        privateInfoRequired,
        privateEmailRequired,
        privateEmail,
        privatePhoneRequired,
        privatePhone,
        workPhoneRequired,
        workPhone,
        ibanRequired,
        iban,
        pokRequired,
        pok,
        wintersportInfoRequired,
        allergy,
        skiExperience,
        sport,
        medication,
        emergencyContactName,
        emergencyContactNumber,
        shoeSizeEU,
        height,
        weight,
        ownedMaterial
    } = studentInfoSettings;

    // State values
    const [saveFailed, setSaveFailed] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [valuesToSubmit, setValuesToSubmit] = useState([]);
    const [formState, setFormState] = useState({
        // App version 1
        email: { value: null, error: null },
        mobilePhone: { value: null, error: null }, // privatePhone
        anyPhone: { value: null, error: null }, // workPhone (emergencyPhone with appVersion = 2)

        // App version 2
        iban: { value: null, error: null },
        pok: { value: null, error: null },

        // Wintersport mode
        allergy: { value: null, error: null },
        skiExperience: { value: null, error: null },
        sport: { value: null, error: null },
        medication: { value: null, error: null },
        emergencyContactName: { value: null, error: null },
        emergencyContactNumber: { value: null, error: null },
        shoeSizeEU: { value: null, error: null },
        height: { value: null, error: null },
        weight: { value: null, error: null },
        ownedMaterial: { value: null, error: null },
    });

    const updateOrAddSubmitObject = (newObject) => {
        setValuesToSubmit(prevArray => {
            const index = prevArray.findIndex(obj => obj.field === newObject.field);

            if (index !== -1) {
                return prevArray.map(obj => obj.field === newObject.field ? newObject : obj);
            } else {
                return [...prevArray, newObject];
            }
        });
    };

    const handleValueChange = (field, newValue) => {
        setFormState(prevState => ({
            ...prevState,
            [field]: {
                ...prevState[field],
                value: newValue
            }
        }));
    };

    const handleErrorChange = (field, newError) => {
        setFormState(prevState => ({
            ...prevState,
            [field]: {
                ...prevState[field],
                error: newError
            }
        }));
    };

    // Validation functions
    function isValidEmail(input) { return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input); };
    function isValidMobilePhone(input) { return /^\(?(0)-?6(\s?|-)([0-9]\s{0,2}){8}$/.test(input); };
    function isValidAnyPhone(input) { return /^((\\+|00(\\s|\\s?\\-\\s?)?)31(\\s|\\s?\\-\\s?)?(\\(0\\)[\\-\\s]?)?|0)[1-9]((\\s|\\s?\\-\\s?)?[0-9]){8}$/.test(input); };
    function isValidIban(input) { return /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/.test(input); };
    function isValidValue(input) {
        if (input !== null && input !== undefined && input !== '') {
            return true;
        }
        return false;
    }

    // Clear errors if valid value
    useEffect(() => {
        const clearErrorIfValid = (field, validator) => {
            var error = formState[field].error;
            var value = formState[field].value;

            if (error !== null && validator(value)) {
                handleErrorChange(field, null);
            }
        };

        // App version 1
        clearErrorIfValid('email', isValidEmail);
        clearErrorIfValid('mobilePhone', isValidMobilePhone);
        clearErrorIfValid('anyPhone', isValidAnyPhone);

        // App version 2
        clearErrorIfValid('iban', isValidIban);
        clearErrorIfValid('pok', isValidValue);

        // Wintersport mode
        clearErrorIfValid('allergy', isValidValue);
        clearErrorIfValid('skiExperience', isValidValue);
        clearErrorIfValid('sport', isValidValue);
        clearErrorIfValid('medication', isValidValue);
        clearErrorIfValid('emergencyContactName', isValidValue);
        clearErrorIfValid('emergencyContactNumber', isValidAnyPhone);
        clearErrorIfValid('shoeSizeEU', isValidValue);
        clearErrorIfValid('height', isValidValue);
        clearErrorIfValid('weight', isValidValue);
        clearErrorIfValid('ownedMaterial', isValidValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    // Callback after updating info
    useEffect(() => {
        if (putStudentInfoStatus === "success") {
            window.location.reload();
        } else if (putStudentInfoStatus === "failed") {
            setSaveFailed(true);
            setDisabled(false);
        };
    }, [dispatch, putStudentInfoStatus]);

    // Submit values if valid
    const submitValues = () => {
        if (valuesToSubmit.length === 0) return;

        valuesToSubmit.forEach(({ field, errorCondition, validator, errorMessage }) => {
            var value = formState[field].value;
            if (errorCondition && !validator(value)) {
                handleErrorChange(field, errorMessage);
            } else if (validator(value)) {
                setDisabled(true);
                dispatch(putStudentInfo({ key: field, newValue: value }));
            }
        });
    };

    if (location.pathname.toLocaleLowerCase() === "/account" || studentInfoSettingsStatus !== 'success' || !(privateInfoRequired && !isInfoComplete)) {
        return null;
    }

    return (
        <Backdrop open={true} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Box sx={{ ...modalStyle }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                    <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                        <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6">
                                {resourceFinal[appLanguage].messages.PRIVATE_INFO_WARNING}
                            </Typography>

                            <TextFieldFragment
                                state={formState}
                                field='email'
                                required={privateEmailRequired}
                                existingValue={privateEmail}
                                onChange={(event) => {
                                    handleValueChange('email', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'email',
                                        errorCondition: privateEmailRequired && !formState.email.value,
                                        validator: isValidEmail,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_EMAIL,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_EMAIL_PRIVATE}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='mobilePhone'
                                required={privatePhoneRequired}
                                existingValue={privatePhone}
                                onChange={(event) => {
                                    handleValueChange('mobilePhone', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'mobilePhone',
                                        errorCondition: privatePhoneRequired && !formState.mobilePhone.value,
                                        validator: isValidMobilePhone,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_MOBILE_PHONE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='anyPhone'
                                required={workPhoneRequired}
                                existingValue={workPhone}
                                onChange={(event) => {
                                    handleValueChange('anyPhone', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'anyPhone',
                                        errorCondition: workPhoneRequired && !formState.anyPhone.value,
                                        validator: isValidAnyPhone,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_PHONE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_PHONENUMBER_ICE}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='iban'
                                required={ibanRequired}
                                existingValue={iban}
                                onChange={(event) => {
                                    handleValueChange('iban', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'iban',
                                        errorCondition: ibanRequired && !formState.iban.value,
                                        validator: isValidIban,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_IBAN,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_IBAN}
                            />

                            <RadioGroupFragment
                                state={formState}
                                field='pok'
                                required={pokRequired}
                                existingValue={pok}
                                onChange={(event) => {
                                    handleValueChange('pok', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'pok',
                                        errorCondition: pokRequired && (formState.pok.value === null || formState.pok.value === "" || formState.pok.value === undefined),
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_POK,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_POK}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='allergy'
                                required={wintersportInfoRequired}
                                existingValue={allergy}
                                onChange={(event) => {
                                    handleValueChange('allergy', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'allergy',
                                        errorCondition: wintersportInfoRequired && !formState.allergy.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_ALLERGY}
                            />

                            <DropdownSelectFragment
                                state={formState}
                                field='skiExperience'
                                required={wintersportInfoRequired}
                                existingValue={skiExperience}
                                onChange={(event) => {
                                    handleValueChange('skiExperience', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'skiExperience',
                                        errorCondition: wintersportInfoRequired && !formState.skiExperience.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE}
                                menuItems={[
                                    <MenuItem key="0" value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_NO}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_NO}</MenuItem>,
                                    <MenuItem key="1" value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES1}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES1}</MenuItem>,
                                    <MenuItem key="2" value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES2}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES2}</MenuItem>,
                                    <MenuItem key="3" value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES3}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES3}</MenuItem>,
                                    <MenuItem key="4" value={resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES4}>{resourceFinal[appLanguage].words.USER_DATA_SKIEXPERIENCE_YES4}</MenuItem>,
                                ]}
                            />

                            <DropdownSelectFragment
                                state={formState}
                                field='sport'
                                required={wintersportInfoRequired}
                                existingValue={sport}
                                onChange={(event) => {
                                    handleValueChange('sport', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'sport',
                                        errorCondition: wintersportInfoRequired && !formState.sport.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_SPORT}
                                menuItems={[
                                    <MenuItem key="0" value={resourceFinal[appLanguage].words.USER_DATA_SPORT_1}>{resourceFinal[appLanguage].words.USER_DATA_SPORT_1}</MenuItem>,
                                    <MenuItem key="1" value={resourceFinal[appLanguage].words.USER_DATA_SPORT_2}>{resourceFinal[appLanguage].words.USER_DATA_SPORT_2}</MenuItem>,
                                ]}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='medication'
                                required={wintersportInfoRequired}
                                existingValue={medication}
                                onChange={(event) => {
                                    handleValueChange('medication', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'medication',
                                        errorCondition: wintersportInfoRequired && !formState.medication.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_MEDICATION}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='emergencyContactName'
                                required={wintersportInfoRequired}
                                existingValue={emergencyContactName}
                                onChange={(event) => {
                                    handleValueChange('emergencyContactName', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'emergencyContactName',
                                        errorCondition: wintersportInfoRequired && !formState.emergencyContactName.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NAME}
                            />

                            <TextFieldFragment
                                state={formState}
                                field='emergencyContactNumber'
                                required={wintersportInfoRequired}
                                existingValue={emergencyContactNumber}
                                onChange={(event) => {
                                    handleValueChange('emergencyContactNumber', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'emergencyContactNumber',
                                        errorCondition: wintersportInfoRequired && !formState.emergencyContactNumber.value,
                                        validator: isValidAnyPhone,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_PHONE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_EMERGENCY_CONTACT_NUMBER}
                            />

                            <NumberTextFieldFragment
                                step={0.5} min={0} max={100}
                                state={formState}
                                field='shoeSizeEU'
                                required={wintersportInfoRequired}
                                existingValue={shoeSizeEU}
                                onChange={(event) => {
                                    handleValueChange('shoeSizeEU', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'shoeSizeEU',
                                        errorCondition: wintersportInfoRequired && !formState.shoeSizeEU.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_SHOE_SIZE}
                            />

                            <NumberTextFieldFragment
                                step={0.1} min={0} max={999}
                                state={formState}
                                field='height'
                                required={wintersportInfoRequired}
                                existingValue={height}
                                onChange={(event) => {
                                    handleValueChange('height', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'height',
                                        errorCondition: wintersportInfoRequired && !formState.height.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_HEIGHT}
                            />

                            <NumberTextFieldFragment
                                step={0.1} min={0} max={999}
                                state={formState}
                                field='weight'
                                required={wintersportInfoRequired}
                                existingValue={weight}
                                onChange={(event) => {
                                    handleValueChange('weight', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'weight',
                                        errorCondition: wintersportInfoRequired && !formState.weight.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_WEIGHT}
                            />

                            <DropdownSelectFragment
                                state={formState}
                                field='ownedMaterial'
                                required={wintersportInfoRequired}
                                existingValue={ownedMaterial}
                                onChange={(event) => {
                                    handleValueChange('ownedMaterial', event.target.value);
                                    updateOrAddSubmitObject({
                                        field: 'ownedMaterial',
                                        errorCondition: wintersportInfoRequired && !formState.ownedMaterial.value,
                                        validator: isValidValue,
                                        errorMessage: resourceFinal[appLanguage].messages.INVALID_VALUE,
                                    });
                                }}
                                label={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL}
                                menuItems={[
                                    <MenuItem key="0" value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_1}>{resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_1}</MenuItem>,
                                    <MenuItem key="1" value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_2}>{resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_2}</MenuItem>,
                                    <MenuItem key="2" value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_3}>{resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_3}</MenuItem>,
                                    <MenuItem key="3" value={resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_4}>{resourceFinal[appLanguage].words.USER_DATA_OWNED_MATERIAL_4}</MenuItem>,
                                ]}
                            />

                            {saveFailed && (
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>
                                    {resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}
                                </Typography>
                            )}

                            <Button
                                disabled={disabled || valuesToSubmit.length === 0}
                                sx={{ width: 80 }}
                                onClick={submitValues}
                                variant="outlined"
                                color="secondary"
                            >
                                {resourceFinal[appLanguage].words.SAVE}
                            </Button>
                        </Stack>
                    </Scrollbars>
                </Card>
            </Box>
        </Backdrop >
    );
}

// Reusable textfield component
const TextFieldFragment = ({ state, field, required, existingValue, onChange, label }) => {
    var error = state[field].error;

    return (
        (required && !existingValue) && (
            <TextField
                fullWidth
                error={error !== null}
                helperText={error}
                variant="outlined"
                onChange={onChange}
                label={label}
            />
        )
    );
};

// Reusable number textfield component
const NumberTextFieldFragment = ({ state, field, required, existingValue, onChange, label, step, min, max }) => {
    var error = state[field].error;

    return (
        (required && !existingValue) && (
            <TextField
                type="number"
                inputProps={{ step: step, min: min, max: max }}
                fullWidth
                error={error !== null}
                helperText={error}
                variant="outlined"
                onChange={onChange}
                label={label}
            />
        )
    );
};

// Reusable radio group component 
const DropdownSelectFragment = ({ state, field, required, existingValue, onChange, label, menuItems }) => {
    var error = state[field].error;
    var currentValue = state[field].value;

    return (
        (required && !existingValue) && (
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select
                    label={label}
                    value={currentValue ?? ""}
                    onChange={onChange}
                    sx={{ textAlign: 'left' }}
                >
                    {menuItems}
                </Select>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        )
    );
}

// Reusable radio group component
const RadioGroupFragment = ({ state, field, required, existingValue, onChange, label }) => {
    var error = state[field].error;
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        (required && (existingValue !== "True" || existingValue !== "False")) && (
            <FormControl error={error !== null}>
                <FormLabel>{label}</FormLabel>
                <RadioGroup onChange={onChange}>
                    <FormControlLabel value="True" control={<Radio />} label={resourceFinal[appLanguage].words.YES} />
                    <FormControlLabel value="False" control={<Radio />} label={resourceFinal[appLanguage].words.NO} />
                </RadioGroup>
                <FormHelperText>{error}</FormHelperText>
            </FormControl>
        )
    );
};