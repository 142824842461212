import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubscription, setPermission } from "./notificationSlice";

export default function Notifications() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'notifications' }).then(function (notificationPerm) {
                dispatch(setPermission(notificationPerm.state));
            });
        }
    }, [dispatch]);

    useEffect(() => {
        if (Notification.permission === "granted" && user != null) {
            navigator.serviceWorker.getRegistration()
                .then((reg) => { getSubscription(reg); });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Notification.permission, user]);

    function getSubscription(reg) {
        reg.pushManager.getSubscription().then(function (sub) {
            if (sub === null) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: "BNhzJmBJvTtLRXUMrGfNjlaKzq9NiNzV1lVYyffPnM4h9OfbjOpTXiNhgMd_3MoOWf1NDXHBHRN9SOKa4ZGdeCk"
                }).then(function (sub) {
                    dispatch(addSubscription({ endpoint: sub.endpoint, p256dh: arrayBufferToBase64(sub.getKey("p256dh")), auth: arrayBufferToBase64(sub.getKey("auth")) }));
                }).catch(function (e) {
                    console.error("Unable to subscribe to push", e);
                });
            };
        });
    };

    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    return (<></>);
}