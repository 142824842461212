import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const getActivities = createAsyncThunk(
    "/api/activityManagement",
    async (_, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/activityManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const putActivity = createAsyncThunk(
    "/api/activityManagement/put",
    async (updatedActivity, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/activityManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "PUT",
                redirect: "follow",
                body: JSON.stringify(updatedActivity),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const postActivity = createAsyncThunk(
    "/api/activityManagement/post",
    async (newActivity, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + "api/activityManagement",
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "POST",
                redirect: "follow",
                body: JSON.stringify(newActivity),
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const deleteActivity = createAsyncThunk(
    "/api/activityManagement/delete",
    async (activityId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/activityManagement/${activityId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "DELETE",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const activityManagementSlice = createSlice({
    name: "activityManagement",
    initialState: {
        activities: null,
        activitiesStatus: null,
        selectedActivity: null,
        putActivityStatus: null,
        postActivityStatus: null,
        deleteActivityStatus: null,
        showFailed: false,
    },
    reducers: {
        selectActivity: (state, action) => {
            state.selectedActivity = action.payload;
        },
        setFailedActivity: (state, action) => {
            state.showFailed = action.payload;
        },
        resetActivityStatus: (state) => {
            state.activitiesStatus = null;
            state.putActivityStatus = null;
            state.postActivityStatus = null;
            state.deleteActivityStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get activities
        builder
            .addCase(getActivities.pending, (state) => {
                state.activitiesStatus = "loading";
            })
            .addCase(getActivities.fulfilled, (state, action) => {
                state.activitiesStatus = "success";
                state.activities = action.payload;
            })
            .addCase(getActivities.rejected, (state) => {
                state.activitiesStatus = "failed";
            });

        // Put activity
        builder
            .addCase(putActivity.pending, (state) => {
                state.putActivityStatus = "loading";
            })
            .addCase(putActivity.fulfilled, (state) => {
                state.putActivityStatus = "success";
            })
            .addCase(putActivity.rejected, (state) => {
                state.putActivityStatus = "failed";
            });

        // Post activity
        builder
            .addCase(postActivity.pending, (state) => {
                state.postActivityStatus = "loading";
            })
            .addCase(postActivity.fulfilled, (state) => {
                state.postActivityStatus = "success";
            })
            .addCase(postActivity.rejected, (state) => {
                state.postActivityStatus = "failed";
            });

        // Delete activity
        builder
            .addCase(deleteActivity.pending, (state) => {
                state.deleteActivityStatus = "loading";
            })
            .addCase(deleteActivity.fulfilled, (state) => {
                state.deleteActivityStatus = "success";
            })
            .addCase(deleteActivity.rejected, (state) => {
                state.deleteActivityStatus = "failed";
            });
    },
});

export const {
    selectActivity,
    resetActivityStatus,
    setFailedActivity,
} = activityManagementSlice.actions;

export default activityManagementSlice.reducer;
