import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium, HomeTileSmall, TableHeader } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';
import { fetchEmployeeAttendances } from './employeeSlice';
import { fetchMomentAttendance } from '../attendance/attendanceSlice';
import { useNavigate } from 'react-router-dom';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GetDate, GetTime } from '../../app/AppUtils';

export default function EmployeeAttendancesTile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { employeeAttendances, employeeAttendancesStatus } = useSelector((state) => state.employees);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);

    const [filter, setFilter] = useState("");


    useEffect(() => {
        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

        dispatch(fetchEmployeeAttendances(departmentId));
    }, []);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    var customFilterFunction = (item) => {
        return item.activityName?.toLowerCase().includes(filter.toLowerCase()) ||
            String(item.performanceNumber)?.toLowerCase().includes(filter.toLowerCase());
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - employeeAttendances?.filter(customFilterFunction).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const attendanceClick = (item) => {
            dispatch(fetchMomentAttendance(item.momentId));
            navigate(`/presentie/moment/${item.momentId}`);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? employeeAttendances.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employeeAttendances.filter(customFilterFunction)).map(item =>
                        <TableRow key={item.momentId} hover onClick={() => attendanceClick(item)} sx={{ cursor: 'pointer', '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.activityName}</TableCell>
                            <TableCell align="center">{item.performanceNumber}</TableCell>
                            <TableCell align="center">{item.locationName}</TableCell>
                            <TableCell align="center">{GetDate(item.startTime)} ({GetTime(item.startTime)})</TableCell>
                            <TableCell align="center"><Checkbox disabled checked={item.completed} color="secondary" /></TableCell>
                            <TableCell align="center"><ArrowForwardIosIcon color="secondary" /></TableCell>
                        </TableRow>
                    )}

                    {(rowsPerPage > 0 ? employeeAttendances.filter(customFilterFunction).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : employeeAttendances.filter(customFilterFunction)).length === 0 && <TableRow><TableCell colSpan={6}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10]}
                            count={employeeAttendances?.filter(customFilterFunction).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <Box sx={{ ...HomeTileSmall }}>
            <Box sx={{ ...CardMedium, display: 'flex', width: 1, height: 1, boxSizing: 'border-box', alignItems: 'flex-start' }}>
                <ContentLoader successCondition={employeeAttendancesStatus === "success"} errorCondition={employeeAttendancesStatus === "failed"} textDisabled errorCompact content={
                    <Box sx={{ display: 'flex', flex: '1 1 auto', width: 1, height: 1 }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                        <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.COMPLETED}</TableCell>
                                        <TableCell align="center" padding="checkbox"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableFragment />
                            </Table>
                        </TableContainer>
                    </Box>
                } />
            </Box>
        </Box>
    );
}
