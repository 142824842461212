import { InputAdornment, Stack, TextField } from '@mui/material';
import { useSelector } from "react-redux";

import SearchIcon from '@mui/icons-material/Search';

export default function SearchField({ func, textRef }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Stack direction="row" sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color="primary" />
                    </InputAdornment>
                )
            }} inputRef={textRef} placeholder={resourceFinal[appLanguage].words.SEARCH} variant="standard" onChange={func} />
        </Stack>
    );
}