import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchPerformance = createAsyncThunk(
    '/api/planningPerformance/id',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningPerformance/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchDefaultActivity = createAsyncThunk(
    '/api/planningPerformance/activity/id',
    async (activityId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningPerformance/activity/${activityId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putPerformance = createAsyncThunk(
    '/api/planningPerformance/put',
    async (updatedPerformance, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningPerformance`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedPerformance)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postPerformance = createAsyncThunk(
    '/api/planningPerformance/post',
    async (newPerformance, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningPerformance`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newPerformance)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deletePerformance = createAsyncThunk(
    '/api/planningPerformance/delete',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningPerformance/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const planningPerformanceSlice = createSlice({
    name: 'planningPerformance',
    initialState: {
        performance: null,
        performanceStatus: null,
        defaultActivity: null,
        defaultActivityStatus: null,
        putPerformanceStatus: null,
        postPerformanceStatus: null,
        deletePerformanceStatus: null,
        showFailed: false
    },
    reducers: {
        setFailedPerformance: (state, action) => {
            state.showFailed = action.payload;
        },
        selectPerformance: (state, action) => {
            state.performance = action.payload;
        },
        resetPlanningPerformanceStatus: (state) => {
            state.putPerformanceStatus = null;
            state.postPerformanceStatus = null;
            state.deletePerformanceStatus = null;
        },
        resetDefaultActivityValues: (state) => {
            state.defaultActivity = null;
            state.defaultActivityStatus = null;
        }
    },
    extraReducers: (builder) => {
        // Get performance
        builder
            .addCase(fetchPerformance.pending, (state) => {
                state.performanceStatus = 'loading';
            })
            .addCase(fetchPerformance.fulfilled, (state, action) => {
                state.performance = action.payload;
                state.performanceStatus = 'success';
            })
            .addCase(fetchPerformance.rejected, (state) => {
                state.performanceStatus = 'failed';
            });

        // Get default activity
        builder
            .addCase(fetchDefaultActivity.pending, (state) => {
                state.defaultActivityStatus = 'loading';
            })
            .addCase(fetchDefaultActivity.fulfilled, (state, action) => {
                state.defaultActivity = action.payload;
                state.defaultActivityStatus = 'success';
            })
            .addCase(fetchDefaultActivity.rejected, (state) => {
                state.defaultActivityStatus = 'failed';
            });

        // Put performance
        builder
            .addCase(putPerformance.pending, (state) => {
                state.putPerformanceStatus = 'loading';
            })
            .addCase(putPerformance.fulfilled, (state) => {
                state.putPerformanceStatus = 'success';
            })
            .addCase(putPerformance.rejected, (state) => {
                state.putPerformanceStatus = 'failed';
            });

        // Post performance
        builder
            .addCase(postPerformance.pending, (state) => {
                state.postPerformanceStatus = 'loading';
            })
            .addCase(postPerformance.fulfilled, (state) => {
                state.postPerformanceStatus = 'success';
            })
            .addCase(postPerformance.rejected, (state) => {
                state.postPerformanceStatus = 'failed';
            });

        // Delete performance
        builder
            .addCase(deletePerformance.pending, (state) => {
                state.deletePerformanceStatus = 'loading';
            })
            .addCase(deletePerformance.fulfilled, (state) => {
                state.deletePerformanceStatus = 'success';
            })
            .addCase(deletePerformance.rejected, (state) => {
                state.deletePerformanceStatus = 'failed';
            });
    }
});

export const {
    resetPlanningPerformanceStatus,
    setFailedPerformance,
    selectPerformance,
    resetDefaultActivityValues
} = planningPerformanceSlice.actions;

export default planningPerformanceSlice.reducer;