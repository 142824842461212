import { Box, Card, CardContent, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CardHeader, CardMedium, MainIconStyle } from '../../app/AppStyles';
import { GetDateLong } from '../../app/AppUtils';
import RegisterMomentsDisplay from '../register/RegisterMomentsDisplay';
import { deleteSubscriptionStudent, fetchPerformance, resetRegisterStatusses } from '../register/registerSlice';
import BackButton from '../subcomponents/BackButton';
import ConfirmationModal from '../subcomponents/ConfirmationModal';
import ContentLoader from '../subcomponents/ContentLoader';
import IconDisplay from '../subcomponents/IconDisplay';
import LocationButton from '../subcomponents/LocationButton';
import PageLayout from '../subcomponents/PageLayout';
import { fetchUserProgress, setActivitySubscriptionDetails } from './progressSlice';

import BadgeIcon from '@mui/icons-material/Badge';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EuroIcon from '@mui/icons-material/Euro';
import GroupIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import PendingIcon from '@mui/icons-material/Pending';
import ReportIcon from '@mui/icons-material/Report';
import TodayIcon from '@mui/icons-material/Today';

export default function ProgressActivityPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id, name } = useParams();
    const { user } = useSelector((state) => state.user);
    const { student } = useSelector((state) => state.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { userProgress, userProgressStatus, activitySubscriptionDetails } = useSelector((state) => state.progress);
    const { performance, performanceStatus } = useSelector((state) => state.register);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment, departmentThemesStatus } = useSelector((state) => state.settings.theme);

    const deleteClick = (subscriptionId) => {
        dispatch(deleteSubscriptionStudent({
            studentId: user?.studentId,
            subscriptionId: subscriptionId,
            performanceId: performance?.performanceId,
            createdBy: `AP/${user?.number}`
        }));

        dispatch(resetRegisterStatusses());
        navigate(-1);
    };

    const activityClick = (name, id) => {
        navigate(`/activiteit/${encodeURIComponent(name)}/${id}`);
    };

    const locationClick = (location) => {
        window.open(`https://www.google.nl/maps/dir//+${location}`, '_blank');
    };

    useEffect(() => {
        if (student !== null) {
            dispatch(fetchPerformance({ performanceId: id }))

            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchUserProgress({ departmentId: activeDepartment[0].id, fullProgress: true }))
                };
            } else {
                dispatch(fetchUserProgress({ departmentId: null, fullProgress: true }))
            };
        };
    }, [dispatch, student, departmentThemesStatus, activeDepartment, siomSettings, id]);

    useEffect(() => {
        if (userProgressStatus === "success") {
            dispatch(setActivitySubscriptionDetails(userProgress?.progressActivities?.find((element) => element.performanceId === id)))
        };
    }, [dispatch, userProgressStatus, userProgress, id]);

    const ItemFragment = ({ icon, text, label, click, style }) => {
        return (
            <ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText onClick={click} sx={{ ...style }}
                    primary={text}
                    secondary={label}
                />
            </ListItem>
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.ACTIVITY} ${resourceFinal[appLanguage].words.OVERVIEW.toLowerCase()}`} subtitle={`${decodeURIComponent(name)}`} content={
            <Grid container spacing={1} alignItems="stretch">
                <Grid size={{ xs: 12, md: 4 }} sx={{ display: 'flex' }}>
                    <ContentLoader successCondition={performanceStatus === "success"} errorCondition={performanceStatus === "failed"} content={
                        <Card sx={{ ...CardMedium }}>
                            <CardContent>
                                <Typography variant="h6" sx={{ ...CardHeader, fontSize: { xs: '1.1rem', md: '1.25rem' } }}>{resourceFinal[appLanguage].words.PERFORMANCE}</Typography>
                                <List dense>
                                    <ItemFragment icon={<IconButton onClick={() => activityClick(performance?.activity.name, performance?.activity.id)}><IconDisplay component={performance?.activity.iconName} style={{ color: 'secondary.main' }} /></IconButton>}
                                        text={performance?.activity.name} label={resourceFinal[appLanguage].words.ACTIVITY}
                                        click={() => activityClick(performance?.activity.name, performance?.activity.id)} style={{ cursor: 'pointer' }} />
                                    <ItemFragment icon={<BadgeIcon sx={{ ...MainIconStyle }} />}
                                        text={performance?.performanceName} label={resourceFinal[appLanguage].words.PERFORMANCE}
                                    />
                                    {performance?.cost > 0 ? <ItemFragment icon={<EuroIcon sx={{ ...MainIconStyle }} />}
                                        text={Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR" }).format(performance?.activity.cost)} label={resourceFinal[appLanguage].words.COST} /> : ""}
                                    <ItemFragment icon={<GroupsIcon sx={{ ...MainIconStyle }} />}
                                        text={performance?.occupation} label={resourceFinal[appLanguage].words.OCCUPATION} />
                                    <ItemFragment icon={<TodayIcon sx={{ ...MainIconStyle }} />}
                                        text={GetDateLong(performance?.firstDate)} label={resourceFinal[appLanguage].words.STARTDATE} />
                                    <ItemFragment icon={<LocationButton locationString={`${performance?.location.street}+${performance?.location.streetNumber}+${performance?.location.city}`} />}
                                        text={<>{performance?.location.name} {performance?.location.street ?
                                            "(" + performance?.location.street + (performance?.location.streetNumber ? " " + performance?.location.streetNumber : "") +
                                            (performance?.location.city ? ", " + performance?.location.city + ")" : ")") :
                                            performance?.location.city ? `(${performance?.location.city})` : ""}</>}
                                        label={resourceFinal[appLanguage].words.LOCATION} style={{ cursor: 'pointer' }} click={() => locationClick(`${performance?.location.street}+${performance?.location.streetNumber}+${performance?.location.city}`)} />
                                </List>
                            </CardContent>
                        </Card>
                    } />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ display: 'flex' }}>
                    <ContentLoader successCondition={activitySubscriptionDetails !== null} errorCondition={''} content={
                        <Card sx={{ ...CardMedium }}>
                            <CardContent>
                                <Typography variant="h6" display="inline" sx={{ ...CardHeader, fontSize: { xs: '1.1rem', md: '1.25rem' } }}>{resourceFinal[appLanguage].words.SUBSCRIPTION}</Typography>
                                <Typography variant="h6" display="inline" sx={{ color: 'error.main' }}> {activitySubscriptionDetails?.isReservation && `(${resourceFinal[appLanguage].words.RESERVATION})`}</Typography>
                                <List dense>
                                    <ItemFragment icon={<CalendarMonthIcon sx={{ ...MainIconStyle }} />}
                                        text={activitySubscriptionDetails?.periodName} label={resourceFinal[appLanguage].words.PERIOD} />
                                    <ItemFragment icon={<BarChartIcon sx={{ ...MainIconStyle }} />}
                                        text={activitySubscriptionDetails?.pointsDisplay} label={resourceFinal[appLanguage].words.POINTS} />
                                    <ItemFragment icon={<PendingIcon sx={{ ...MainIconStyle }} />}
                                        text={activitySubscriptionDetails?.momentsProgressDisplay} label={resourceFinal[appLanguage].words.PROGRESS} />
                                    <ItemFragment icon={<GroupIcon sx={{ ...MainIconStyle }} />}
                                        text={activitySubscriptionDetails?.attendanceDisplay} label={resourceFinal[appLanguage].words.PRESENCE} />
                                    <ItemFragment icon={activitySubscriptionDetails?.status ? <CheckCircleIcon color="success" sx={{ p: 1 }} /> : <ReportIcon color="error" sx={{ p: 1 }} />}
                                        text={activitySubscriptionDetails?.status ? resourceFinal[appLanguage].words.STATUS_OK : resourceFinal[appLanguage].words.STATUS_NOT_OK} label={resourceFinal[appLanguage].words.STATUS} />
                                    <ItemFragment icon={<ConfirmationModal iconButton={true} icon={<DeleteIcon color={`${activitySubscriptionDetails?.unsubscribable ? "secondary" : ""}`} />} disabledCondition={!activitySubscriptionDetails?.unsubscribable} message={`${resourceFinal[appLanguage].messages.UNSUBSCRIBE_CONFIRMATION} ${decodeURIComponent(name)}?`} confirmAction={() => deleteClick(activitySubscriptionDetails?.subscriptionId, activitySubscriptionDetails?.performanceId)} />}
                                        text={activitySubscriptionDetails?.unsubscribable ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO} label={resourceFinal[appLanguage].words.UNSUBSCRIBABLE + "?"} />
                                </List>
                            </CardContent>
                        </Card>
                    } />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} sx={{ display: 'flex' }}>
                    <ContentLoader successCondition={performanceStatus === "success"} errorCondition={performanceStatus === "failed"} content={
                        <Card sx={{ ...CardMedium, display: 'flex' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                                <Typography variant="h6" sx={{ ...CardHeader, fontSize: { xs: '1.1rem', md: '1.25rem' } }}>{resourceFinal[appLanguage].words.MOMENTS} ({performance?.activityMoments.length})</Typography>
                                <RegisterMomentsDisplay moments={performance?.activityMoments} amount={5} pagination />
                            </CardContent>
                        </Card>
                    } />
                </Grid>
            </ Grid>
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}