import { Button, Card, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { CardLarge, PageHeader } from '../../app/AppStyles';
import { fetchPerformance } from '../register/registerSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import MolliePaymentButton from './MolliePaymentButton';
import { fetchTransactionByPaymentId, resetMollieFetchResults } from './mollieSlice';

import HomeIcon from '@mui/icons-material/Home';

export default function MollieReturnPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { performance, performanceStatus } = useSelector((state) => state.register);
    const { transaction, transactionStatus } = useSelector((state) => state.mollie);

    // Reset Mollie fetch results
    useEffect(() => {
        dispatch(resetMollieFetchResults());
    }, []);

    // Fetch ideal transaction from DB
    useEffect(() => {
        dispatch(fetchTransactionByPaymentId(id));
    }, [id]);

    // Fetch performance info
    useEffect(() => {
        if (transactionStatus === "success")
            dispatch(fetchPerformance({ performanceId: transaction?.onderwijsUitvoeringId }));
    }, [transactionStatus]);

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.PAYMENT} ${resourceFinal[appLanguage].words.RESULT.toLowerCase()}`} content={
            <ContentLoader successCondition={transactionStatus === "success" && performanceStatus === "success"} errorCondition={transactionStatus === "failed"} content={
                <>
                    <Card sx={{ ...CardLarge, flex: 0, p: 1 }}>
                        <Typography variant="h6" textAlign="center" sx={{ ...PageHeader }}>{performance?.activity.name}{performance?.performanceName && ` - ${performance?.performanceName}`}</Typography>
                        <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].words.AMOUNT}: {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(transaction?.bedrag)}</Typography>
                        <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].words.STATE}: {transaction?.status}</Typography>
                    </Card>
                    <Card sx={{ ...CardLarge, p: 1, mt: 1 }}>
                        {transaction?.status?.toLowerCase() === "paid" &&
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_PAID}
                            </Typography>}
                        {transaction?.status?.toLowerCase() === "expired" &&
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_EXPIRED}
                            </Typography>}
                        {transaction?.status?.toLowerCase() === "failed" &&
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_FAILED}
                            </Typography>}
                        {transaction?.status?.toLowerCase() === "canceled" &&
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_CANCELED}
                            </Typography>}
                        {(transaction?.status?.toLowerCase() !== "paid" && transaction?.status !== "open" && !siomSettings.mollieEnableDelay) &&
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_ACTION1}
                            </Typography>}
                        {(transaction?.status?.toLowerCase() === "open" || (transaction?.status?.toLowerCase() !== "paid" && transaction?.status?.toLowerCase() !== "open" && siomSettings.mollieEnableDelay))&& <>
                            <MolliePaymentButton buttonMode="mollieReturn" performanceId={performance?.performanceId} transactionId={id} subscriptionId={transaction?.inschrijvingId} />
                        </>}
                        <Button variant="outlined" color="secondary" sx={{ width: 110, alignSelf: 'center', mt: 'auto' }}
                            startIcon={<HomeIcon />} onClick={() => navigate("/")}>
                            {resourceFinal[appLanguage].words.HOME}
                        </Button>
                    </Card>
                </>
            } />
        } bottomBar />
    );
}
