import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchSchedule = createAsyncThunk(
    '/api/schedule/user/id',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/schedule/user`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: {
        schedule: null,
        scheduleStatus: null,
        momentDetails: null,
        containerHeight: null,
    },
    reducers: {
        setContainerHeight: (state, action) => {
            state.containerHeight = action.payload
        },
        setMomentDetails: (state, action) => {
            state.momentDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Fetch Schedule
        builder
            .addCase(fetchSchedule.pending, (state) => {
                state.scheduleStatus = 'loading';
            })
            .addCase(fetchSchedule.fulfilled, (state, action) => {
                state.schedule = action.payload;
                state.scheduleStatus = 'success';
            })
            .addCase(fetchSchedule.rejected, (state, action) => {
                state.schedule = action.payload;
                state.scheduleStatus = 'failed';
            });
    }
});

export const { setContainerHeight, setMomentDetails } = scheduleSlice.actions;

export default scheduleSlice.reducer;