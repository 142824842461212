import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from "react-redux";
import { CardMedium, HomeTileSmall, SubHeader } from '../../app/AppStyles';
import ContentLoader from '../subcomponents/ContentLoader';

export default function InfoTile() {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { user, userStatus } = useSelector((state) => state.user);
    const desktop = useMediaQuery('(min-width:900px)');

    return (
        <>
            {desktop ?
                <Box sx={{ ...HomeTileSmall }}>
                    <Box sx={{ ...CardMedium, width: 1, height: 1, boxSizing: 'border-box' }}>
                        <ContentLoader successCondition={userStatus === "success"} errorCondition={userStatus === "failed"} content={
                            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: 1 }}>
                                <Typography variant="h6" sx={{ ...SubHeader, textAlign: 'center' }}>{resourceFinal[appLanguage].words.WELCOME}, {user?.firstName} {user?.middleName} {user?.lastName}!</Typography>
                                {activeDepartment ? <Typography variant="h6" sx={{ ...SubHeader, textAlign: 'center' }}>{resourceFinal[appLanguage].words.AT} {activeDepartment[0].name}</Typography> : ""}
                            </Stack>
                        } />
                    </Box>
                </Box>
                :
                <Box sx={{ ...HomeTileSmall }}>
                    <Box sx={{ ...CardMedium, width: 1, height: 1, boxSizing: 'border-box' }}>
                        <Typography variant="h5" sx={{ color: 'primary.main', fontSize: 16, textAlign: 'center', fontWeight: 'bold' }}>{user?.firstName} {user?.middleName} {user?.lastName} ({user?.number}){activeDepartment ? ", " + activeDepartment[0].name : ""}</Typography>
                    </Box>
                </Box>
            }
        </>
    );
}
