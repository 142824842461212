import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from '../subcomponents/BackButton';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import ActivityCard from './ActivityCard';
import { fetchActivity } from './activitySlice';

export default function ActivityPage() {
    const dispatch = useDispatch();

    const { id } = useParams();
    const { activity, activityStatus } = useSelector((state) => state.activity);

    useEffect(() => {
        dispatch(fetchActivity(id));
    }, [dispatch, id]);

    return (
        <PageLayout favoritePage={true} content={
            <ContentLoader successCondition={activityStatus === "success"} errorCondition={activityStatus === "failed"} content={
                <ActivityCard activity={activity} />
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <BackButton desktop />
            </Box>
        } bottomBar />
    );
}
