import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { deleteReservation, fetchMolliePayment, fetchTransactionByPaymentId, putReservation, putTransaction, resetMollieFetchResults } from './mollieSlice';

export default function MollieUpdate() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const { user } = useSelector((state) => state.user);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { transaction, transactionStatus, molliePayment, molliePaymentStatus, updateTransactionStatus, confirmReservationStatus, deleteReservationStatus } = useSelector((state) => state.mollie);

    const [hasUpdated, setHasUpdated] = useState(false);

    // Reset Mollie fetch results
    useEffect(() => {
        dispatch(resetMollieFetchResults());
    }, []);

    // Fetch ideal transaction from DB
    useEffect(() => {
        dispatch(fetchTransactionByPaymentId(id));
    }, [id]);

    // Fetch Mollie payment from Mollie if status is open, authorized or pending
    useEffect(() => {
        if (transactionStatus === "success" && (
            transaction?.status?.toLowerCase() === "open" ||
            transaction?.status?.toLowerCase() === "authorized" ||
            transaction?.status?.toLowerCase() === "pending")
        ) {
            dispatch(fetchMolliePayment(transaction.transactieId));
        }
    }, [transactionStatus]);

    // Update ideal transaction in DB with data from Mollie
    useEffect(() => {
        if (molliePaymentStatus === "success" && !hasUpdated) {
            setHasUpdated(true);
            dispatch(putTransaction({
                idealKoppelingId: id,
                status: molliePayment.status,
                modifiedBy: `AP/Mollie Update`,
                amount: molliePayment.amount.value
            }));
        }
    }, [molliePaymentStatus]);

    // Fetch ideal transaction from DB if above update is successful
    useEffect(() => {
        if (updateTransactionStatus === "success") {
            dispatch(fetchTransactionByPaymentId(id));
        }
    }, [updateTransactionStatus]);


    useEffect(() => {
        if (transactionStatus === "success" && hasUpdated) {
            // Update reservation details if ideal transaction status is open or status is failed/canceled/expired and payment delay is enabled
            if (
                transaction?.status?.toLowerCase() === "open" || (
                    (transaction?.status?.toLowerCase() === "failed" ||
                        transaction?.status?.toLowerCase() === "canceled" ||
                        transaction?.status?.toLowerCase() === "expired")
                    && siomSettings.mollieEnableDelay)) {
                dispatch(putReservation({
                    SubscriptionId: transaction?.inschrijvingId,
                    PerformanceId: transaction?.onderwijsUitvoeringId,
                    StudentId: transaction?.studentId,
                    IsReservation: true, // Updates subscription details, but it stays as a reservation
                    ModifiedBy: `AP/Mollie Update`,
                }));
            }

            // Confirm reservation if ideal transaction status is paid
            if (transaction?.status?.toLowerCase() === "paid") {
                dispatch(putReservation({
                    SubscriptionId: transaction?.inschrijvingId,
                    PerformanceId: transaction?.onderwijsUitvoeringId,
                    StudentId: transaction?.studentId,
                    IsReservation: false,
                    ModifiedBy: `AP/Mollie Update`,
                }));
            }

            // Delete reservation if ideal transaction status is failed/canceled/expired and payment delay is disabled
            if ((transaction?.status?.toLowerCase() === "failed" || transaction?.status?.toLowerCase() === "canceled" || transaction?.status?.toLowerCase() === "expired") && !siomSettings.mollieEnableDelay) {
                dispatch(deleteReservation({
                    SubscriptionId: transaction?.inschrijvingId,
                    PerformanceId: transaction?.onderwijsUitvoeringId,
                    StudentId: transaction?.studentId,
                    IsReservation: true,
                    ModifiedBy: `AP/Mollie Update`,
                }));
            }
        }
    }, [transaction, transactionStatus, molliePaymentStatus]);

    useEffect(() => {
        if ((confirmReservationStatus === "success" || deleteReservationStatus === "success") && user) {
            navigate(`/inschrijven/betaling/${id}`);
        }
    }, [confirmReservationStatus, deleteReservationStatus])

    return (
        <Box sx={{
            display: 'flex', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center',
            backgroundImage: `radial-gradient(circle at center, white 20%, rgba(0, 255, 255, 0.1) 100%)`
        }}>
            <Card sx={{ width: { xs: '90%', md: '50%' } }}>
                <CardContent>
                    <Stack>
                        {transactionStatus === "success" ? (
                            <>
                                {transaction?.status?.toLowerCase() === "paid" &&
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {resourceFinal[appLanguage].messages.PAYMENT_PAID_NON_USER}
                                    </Typography>
                                }

                                {transaction?.status?.toLowerCase() === "open" &&
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {resourceFinal[appLanguage].messages.PAYMENT_OPEN_NON_USER}
                                    </Typography>
                                }

                                {(transaction?.status?.toLowerCase() !== "paid" && transaction?.status?.toLowerCase() !== "open") &&
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {resourceFinal[appLanguage].messages.PAYMENT_NOT_PAID_NON_USER}
                                    </Typography>
                                }
                            </>
                        ) : (
                            <Typography variant="h6" align="center" gutterBottom>
                                {resourceFinal[appLanguage].messages.PAYMENT_WAITING}
                            </Typography>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}
